import React, {useState, useEffect} from 'react'
import LoadingCircle from 'components/LoadingCircle'
import useAdsQuery from 'hooks/queries/useAdsQuery'
import {useLocation} from 'react-router-dom'
import {useMutation, useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import {Ad, AdImg, Wrapper} from './Advertisements.styles'

const Advertisements = ({...rest}) => {
  const api = useApi()
  const location = useLocation()
  const {data, isLoading} = useAdsQuery({is_draft: 0, is_mobile: 0})
  const [path, setPath] = useState(location.pathname)
  const [handleClick] = useMutation(id => api.post(`/ad_tools/${id}/click`))
  const filterAds = data?.filter(route => route.route_segments.some(seg => path.includes(seg)))

  useEffect(() => {
    const newPath = location.pathname
    if (newPath !== path) {
      setPath(newPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useQuery('impressions', () => api.adsImpressions(filterAds?.slice(0, 2).map(ad => ad.id)), {
    enabled: !!filterAds && filterAds.length > 0,
  })

  return (
    <Wrapper {...rest}>
      {isLoading && <LoadingCircle />}
      {filterAds?.slice(0, 2).map(({id, link, image_url}) => (
        <Ad key={id} href={link} target='_blank' rel='noopener' onClick={() => handleClick(id)}>
          <AdImg src={image_url} />
        </Ad>
      ))}
    </Wrapper>
  )
}

export default Advertisements
