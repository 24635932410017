import React, {useCallback, useContext} from 'react'
import I18nContext from 'contexts/I18nContext'
import {profileType} from 'types'
import {Link, generatePath} from 'react-router-dom'
import useProfileFollows from 'hooks/api/useProfileFollows'
import routes from 'routes'
import {translatedUrl} from 'utils/i18n'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import {scroller} from 'react-scroll'
import {useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import {Wrapper} from './BottomStatistics.styles'
import StatisticsCell from './StatisticsCell'

const BottomStatistics = ({profile, ...rest}) => {
  const {t} = useContext(I18nContext)
  const {profile: personalProfile} = usePersonalProfile()

  const api = useApi()
  const {data: postsCount} = useQuery(['postsCount', profile?.id], async () => {
    if (profile) {
      return api.getUserPosts({user_profile_id: profile.id, limit: 0}).then(res => res.meta.total)
    }
    return 0
  })

  const {followersCount, followingsCount} = useProfileFollows({profile})

  const getFollowPageLink = useCallback(
    tab => {
      const path =
        personalProfile?.id === profile?.id ? routes.teammates.path : routes.profileFollowers.path
      return {
        pathname: translatedUrl(generatePath(path, {id: profile?.id})),
        search: `?${new URLSearchParams({tab}).toString()}`,
      }
    },
    [personalProfile?.id, profile?.id]
  )

  return (
    <Wrapper {...rest}>
      <StatisticsCell
        text={t('userProfileCard.posts')}
        value={postsCount}
        onClick={() =>
          scroller.scrollTo('scroll-to-posts', {
            duration: 400,
            smooth: 'easeInOutQuad',
            offset: -100,
          })
        }
      />
      <StatisticsCell
        text={t('userProfileCard.following')}
        value={followingsCount}
        component={Link}
        to={getFollowPageLink('following')}
      />
      <StatisticsCell
        text={t('userProfileCard.subscribers')}
        value={followersCount}
        component={Link}
        to={getFollowPageLink('subscribers')}
      />
    </Wrapper>
  )
}

BottomStatistics.propTypes = {
  profile: profileType,
}

export default BottomStatistics
