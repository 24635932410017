import {createLocalStorageStateHook} from 'use-local-storage-state'
import useUser from 'hooks/api/useUser'

const storageHook = createLocalStorageStateHook('registerSelectedAccType')

export default function useRegisterAccType() {
  const [type, setType] = storageHook()
  const {userInfo} = useUser()
  if (userInfo?.invite_token) return ['team', setType]
  return [type, setType]
}
