import React, {useMemo} from 'react'
import * as PropTypes from 'prop-types'
import {ReactComponent as Insta} from 'images/common/socials/insta.svg'
import {ReactComponent as Fb} from 'images/common/socials/facebook.svg'
import transfermarkt from 'images/common/socials/transfermarkt.png'
import {ReactComponent as Fussballde} from 'images/common/socials/fussballde.svg'
import {parseUrl} from 'utils/helpers'
import {SocialIconLink} from './SocialIcon.styles'

const iconsMap = {
  instagram: {
    icon: <Insta />,
  },
  facebook: {
    icon: <Fb />,
  },
  transfermarkt: {
    icon: <img src={transfermarkt} alt='' />,
  },
  fupa: {
    icon: <Fussballde />,
  },
  fussball: {
    icon: <Fussballde />,
  },
}

const SocialIcon = ({type, link, ...rest}) => {
  const parsedUrl = useMemo(() => parseUrl(link), [link])
  return (
    <SocialIconLink target='_blank' rel='noopener' href={parsedUrl} {...rest}>
      {iconsMap[type]?.icon || type}
    </SocialIconLink>
  )
}

SocialIcon.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default SocialIcon
