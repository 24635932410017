import styled from 'styled-components'

export const ErrorText = styled.span(({theme}) => ({
  fontSize: theme.fontSizes.extraSmall,
  color: theme.palette.error.main,
  display: 'inline-block',
  marginTop: 4,
  lineHeight: 1.2,
  '&::first-letter': {
    textTransform: 'uppercase',
  },
}))
