import styled from 'styled-components'
import SocialIcon from 'components/SocialIcon'

export const Wrapper = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 10,
}))

export const Icon = styled(SocialIcon)(() => ({}))
