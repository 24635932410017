import React, {useContext} from 'react'
import I18nContext from 'contexts/I18nContext'
import {Wrapper, Link} from './Footer.styles'

const Footer = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return (
    <Wrapper {...rest}>
      <Link href='https://www.poacher-sports.de/en/imprint/'>{t('footer.imprint')}</Link>
      <Link href=' https://www.poacher-sports.de/en/data-protection/'>{t('footer.privacy')}</Link>
      <Link href=' https://www.poacher-sports.de/en/terms/'>{t('footer.terms')}</Link>
    </Wrapper>
  )
}

export default Footer
