import React from 'react'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import FooterMenuPlayer from './FooterMenuPlayer'
import FooterMenuTeam from './FooterMenuTeam'
import FooterMenuPress from './FooterMenuPress'
import FooterMenuAdmin from './FooterMenuAdmin'

const FooterMenu = ({playerItems, pressItems, adminItems}) => {
  const {profileRole} = usePersonalProfile()

  return (
    <>
      {profileRole === 'player' && <FooterMenuPlayer items={playerItems} />}
      {profileRole === 'team' && <FooterMenuTeam items={playerItems} />}
      {profileRole === 'press' && <FooterMenuPress items={pressItems} />}
      {profileRole === 'admin' && <FooterMenuAdmin items={adminItems} />}
    </>
  )
}

export default FooterMenu
