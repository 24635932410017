import {createMuiTheme} from '@material-ui/core/styles'
import {radii, spacing} from './shapings'
import typography, {fontSizes} from './typography'
import breakpoints from './breakpoints'
import palette from './palette'
import transitions from './transitions'
import {resolve, resolveColor} from './utils'
import {overrides, props} from './components'

const themeProps = {
  palette,
  fontSizes,
  typography,
  radii,
  spacing,
  shape: {
    borderRadius: radii.default,
  },
  transitions,
  breakpoints,
  overrides,
  props,
}

const darkTheme = createMuiTheme({
  ...themeProps,
  palette: {
    ...palette,
    type: 'dark',
    background: {
      paper: palette.grey[800],
      default: palette.grey[900],
    },
  },
})

darkTheme.resolve = str => resolve(darkTheme, str)
darkTheme.resolveColor = (str, getMain) => resolveColor(darkTheme, str, getMain)

const lightTheme = createMuiTheme({
  ...themeProps,
  palette: {
    ...palette,
    type: 'light',
    background: {
      paper: palette.grey[50],
      default: palette.grey[0],
    },
  },
})
lightTheme.resolve = str => resolve(lightTheme, str)
lightTheme.resolveColor = (str, getMain) => resolveColor(lightTheme, str, getMain)

export {lightTheme, darkTheme}
