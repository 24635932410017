import styled from 'styled-components'

export const Wrapper = styled.div(({$mV = 3, $mH = 5}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  marginTop: -$mV,
  marginBottom: -$mV,
  marginLeft: -$mH,
  marginRight: -$mH,
  '& > *': {
    marginTop: $mV,
    marginBottom: $mV,
    marginLeft: $mH,
    marginRight: $mH,
  },
}))
