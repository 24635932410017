import React, {useCallback, useMemo} from 'react'
import {ReactQueryDevtools} from 'react-query-devtools'
import {QueryCache, ReactQueryCacheProvider} from 'react-query'
import {useSnackbar} from 'notistack'
import config from 'config'
import {hasOwnPropertyCall} from 'utils/helpers'

const ReactQueryProvider = ({children}) => {
  const {enqueueSnackbar} = useSnackbar()

  const onError = useCallback(
    err => {
      // if CancelQuery error - do not show
      if (err && hasOwnPropertyCall(err, 'silent')) return

      // eslint-disable-next-line no-console
      if (config.env === 'development') console.error(err)

      enqueueSnackbar(`${err?.toString()}`, {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
      })
    },
    [enqueueSnackbar]
  )

  const queryCache = useMemo(
    () =>
      new QueryCache({
        defaultConfig: {
          queries: {
            retry: (failureCount, error) => {
              if (error?.status < 500) return false
              return failureCount < 2
            },
            onError,
          },
          mutations: {
            onError,
          },
        },
      }),
    [onError]
  )
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryCacheProvider>
  )
}

export default ReactQueryProvider
