import React, {useMemo} from 'react'
import {socialsType} from 'types'
import {Wrapper, Icon} from './SocialIconsGroup.styles'

const iconsOrder = ['fupa', 'transfermarkt', 'facebook', 'instagram']

const SocialIconsGroup = ({socials, ...rest}) => {
  const dataMapped = useMemo(
    () =>
      iconsOrder
        ?.map(type => ({
          type,
          link: socials?.[type],
        }))
        .filter(({link}) => !!link) || [],
    [socials]
  )
  if (dataMapped.length <= 0) return null
  return (
    <Wrapper {...rest}>
      {dataMapped.map(({link, type}) => (
        <Icon key={type} type={type} link={link} />
      ))}
    </Wrapper>
  )
}

SocialIconsGroup.propTypes = {
  socials: socialsType,
}

export default SocialIconsGroup
