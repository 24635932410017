import React, {useContext, useMemo} from 'react'
import styled from 'styled-components'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import Button from 'components/Button'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useProfileInfo from 'hooks/api/useProfileInfo'
import I18nContext from 'contexts/I18nContext'
import {ReactComponent as MessagesSvg} from 'images/icons/menu/messages.svg'
import {generatePath} from 'react-router-dom'
import StarsIcon from '@material-ui/icons/Stars'
import {Tooltip} from '@material-ui/core'
import useApi from 'hooks/api/useApi'
import {useMutation} from 'react-query'

const ButtonWrapper = styled.div({
  flexGrow: 1,
  maxWidth: 180,
})

export const PremiumIcon = styled.span(({theme}) => ({
  position: 'absolute',
  zIndex: 1,
  fontSize: '10px',
  marginTop: '-11px',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}))

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  maxWidth: '100%',
})

const maxLeagueLevel = 15

const ButtonRequest = ({
  profile: otherProfile,
  buttonText,
  requestButtonVacancyFeed,
  promotion,
  ...props
}) => {
  const {t} = useContext(I18nContext)
  const api = useApi()
  const {
    instance: personalInstance,
    profileRole: personalRole,
    profile: personalProfile,
  } = usePersonalProfile()
  const {instance: otherProfileInstance, profileRole: otherProfileRole} = useProfileInfo(
    otherProfile
  )
  const isPlayerSendToTeamOrClub = personalRole === 'player' && ['team'].includes(otherProfileRole)

  const isTeamOrClubSendToPlayer = ['team'].includes(personalRole) && otherProfileRole === 'player'

  const isTeamSendToPlayer = personalRole === 'team' && otherProfileRole === 'player'

  const rejectReason = useMemo(() => {
    if (isPlayerSendToTeamOrClub && !personalProfile.premium_account) {
      return t('userProfileCard.errors.premiumRequired')
    }
    if (isPlayerSendToTeamOrClub || isTeamOrClubSendToPlayer) {
      const personalLevel = personalInstance.league_level || maxLeagueLevel
      const requiredLevel = otherProfileInstance?.contact_light || maxLeagueLevel
      const name = otherProfileInstance?.name
      if (personalLevel > requiredLevel) {
        return t('userProfileCard.errors.lowLeagueLevel', {
          requiredLevel,
          personalLevel,
          name,
        })
      }
    }

    if (isTeamSendToPlayer && !personalInstance.verified)
      return t('userProfileCard.errors.profileNotVerified')

    return null
  }, [
    isTeamSendToPlayer,
    isPlayerSendToTeamOrClub,
    isTeamOrClubSendToPlayer,
    otherProfileInstance?.contact_light,
    personalInstance.league_level,
    personalInstance.verified,
    personalProfile.premium_account,
    t,
  ])

  const url = {
    pathname: translatedUrl(
      otherProfile
        ? generatePath(routes.messages.conversation.path, {id: otherProfile?.user_id})
        : routes.messages.path
    ),
    search: `?create=1`,
  }

  const [promotionEvent] = useMutation(() =>
    api.post('promotion_events', {promotion_event: {promotion_id: promotion.id, kind: 'click'}})
  )

  return (
    <Tooltip title={rejectReason}>
      <ButtonWrapper>
        {isPlayerSendToTeamOrClub && (
          <Tooltip title='Premium'>
            <PremiumIcon>
              <StarsIcon />
            </PremiumIcon>
          </Tooltip>
        )}
        <StyledButton
          onClick={requestButtonVacancyFeed && promotionEvent}
          id='buttonRequest'
          disabled={!!rejectReason}
          color='secondary'
          text={buttonText || t('userProfileCard.btnSendRequest')}
          to={url}
          startIcon={<MessagesSvg width='1.25em' height='1.25em' />}
          {...props}
        />
      </ButtonWrapper>
    </Tooltip>
  )
}

export default ButtonRequest
