import styled from 'styled-components'
import LazyImage from 'components/LazyImage'

export const Container = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 15,
  flex: 1,
  position: 'relative',
  background: theme.palette.grey[900],
}))

export const ContainerChildren = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  flex: 1,
  zIndex: 1,
})

export const BgImg = styled(LazyImage)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 0,
})
