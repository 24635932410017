import React, {createContext, useReducer, useEffect} from 'react'
import {useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import useUser from 'hooks/api/useUser'
import {setCount} from './actionTypes'

const initialState = {
  device_token: null,
  notifications: null,
  count: null,
}

export const NotificationContext = createContext(initialState)

const NotificationProvider = ({children}) => {
  const token = localStorage.getItem('token')
  const reducer = (state, action) => {
    switch (action.type) {
      case 'Notifications':
        return {...state, notifications: action.payload}
      case 'NotificationCount':
        return {...state, count: action.payload}
      case 'DeviceToken':
        return {...state, device_token: action.payload}
      default:
        return state
    }
  }
  const hasToken = token !== 'null'
  const api = useApi()
  const {userInfo} = useUser()
  const {data} = useQuery('count', () => api.notificationCount(), {
    enabled: hasToken && !!userInfo && userInfo.role !== 'admin',
  })
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    dispatch(setCount(data?.count))
  }, [data])

  return (
    <NotificationContext.Provider value={{state, dispatch}}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
