import React, {useContext, useEffect} from 'react'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import I18nContext from 'contexts/I18nContext'
import {ReactComponent as BellSvg} from 'images/icons/bell.svg'
import {useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'
import {setCount} from 'containers/NotificationProvider/actionTypes'
import IconButton from '../IconButton'

const ButtonNotifications = ({id, ...rest}) => {
  const {t} = useContext(I18nContext)
  const {state} = useContext(NotificationContext)
  return (
    <>
      <IconButton
        tooltip={t('header.notifications')}
        to={translatedUrl(routes.notifications.path)}
        Icon={BellSvg}
        count={state?.count}
        {...rest}
      />
    </>
  )
}
export default ButtonNotifications
