import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import NavigationLeftPlayer from 'components/navigations/NavigationLeftPlayer'
import ClubPersonalCardSmall from 'components/ClubPersonalCardSmall'
import PlayerSuggestions from 'components/PlayerSuggestions'
import NavigationLeftPress from 'components/navigations/NavigationLeftPress'
import NavigationLeftAdmin from 'components/navigations/NavigationLeftAdmin'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import Footer from 'components/Footer'
import {LeftCol} from './MainLayout.styles'
import {stickyOffset} from './MainLayout.config'

const TeamPersonalCard = ({profile}) => (
  <ClubPersonalCardSmall
    photoUrl={profile?.profile_photo_url}
    name={profile?.team_profile?.name}
    additionalName={profile?.team_profile?.club_profile?.name}
    profile={profile}
  />
)

/* Left column navigation or club info with suggestions */
const MainLayoutLeftCol = () => {
  const {profile, profileRole} = usePersonalProfile()
  const showLeftCol = useMediaQuery(theme => theme.breakpoints.up('collapseLeftRow'))

  if (!showLeftCol) return null
  return (
    <>
      <LeftCol offsetBottom={stickyOffset} offsetTop={stickyOffset + 80}>
        {profileRole === 'admin' && <NavigationLeftAdmin />}
        {profileRole === 'player' && <NavigationLeftPlayer />}
        {profileRole === 'press' && <NavigationLeftPress />}
        {profileRole === 'club' && (
          <ClubPersonalCardSmall
            photoUrl={profile?.profile_photo_url}
            name={profile?.club_profile?.name}
          />
        )}
        {profileRole === 'team' && <TeamPersonalCard profile={profile} />}
        {(profileRole === 'club' || profileRole === 'team') && (
          <PlayerSuggestions style={{marginTop: 20}} />
        )}
        {profileRole === 'player' && !profile?.premium_account && (
          <PlayerSuggestions style={{marginTop: 20}} />
        )}
        <Footer />
      </LeftCol>
    </>
  )
}

export default MainLayoutLeftCol
