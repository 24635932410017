import React, {useEffect, useContext, useMemo} from 'react'
import I18nContext from 'contexts/I18nContext'
import routes from 'routes'
import {setNotifications} from 'containers/NotificationProvider/actionTypes'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'
import {useGetData} from './useGetData'

const ShowBrowserNotification = ({data}) => {
  const {dispatch} = useContext(NotificationContext)
  const {payload, profileName, notification_type_id} = useGetData(data)
  const {t} = useContext(I18nContext)
  const titleHtml = useMemo(
    () =>
      t(`notifications.types.${notification_type_id}.browserNotificationTitle`, {
        profileName,
        ...payload,
      }),
    [payload, profileName, t, notification_type_id]
  )

  const showNotification = () => {
    try {
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          navigator.serviceWorker.ready
            .then(registration => {
              registration.showNotification('Poacher Sports', {
                body: titleHtml,
                icon:
                  'https://poacher-files-uncompressed.s3.eu-central-1.amazonaws.com/shrink_150x150/prod-profile_photos/2020-12-05%252014%253A47%253A58%2520%252B0000-Bildschirmfoto%25202020-12-05%2520um%252015.33.22.png',
                data: routes.notifications.path,
              })
            })
            .then(dispatch(setNotifications(null)))
        }
      })
    } catch (error) {
      if (error instanceof TypeError) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    showNotification()
  }, [])

  return <></>
}

export default ShowBrowserNotification
