import styled from 'styled-components'
import {
  Container as ContainerStyled,
  ContainerChildren as ContChildStyled,
  BgImg as BgImgStyled,
} from '../RegisterLayout/RegisterLayout.styles'

export const Container = styled(ContainerStyled)({})
export const ContainerChildren = styled(ContChildStyled)({})

export const BgImg = styled(BgImgStyled)({
  opacity: 0.3,
})
