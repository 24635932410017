import React, {useMemo} from 'react'
import * as PropTypes from 'prop-types'
import {useTheme} from '@material-ui/core/styles'
import {urlForShrinkedImg} from 'lib/urlForShrinkedImg'
import {ReactComponent as FallbackSvg} from 'images/icons/avatar_default.svg'
import {Wrapper, AvatarStyled, Img, SvgImg, BottomRight} from './Avatar.styles'

const Avatar = ({children, text, src, alt, svg, color, radius, badge, ...rest}) => {
  const theme = useTheme()
  const bgColor = useMemo(() => theme.resolveColor(color, true) || theme.palette.grey[400], [
    color,
    theme,
  ])
  const textColor = useMemo(() => theme.palette.getContrastText(bgColor), [bgColor, theme.palette])
  const withBorder = src || svg
  return (
    <Wrapper {...rest}>
      <AvatarStyled bgColor={bgColor} textColor={textColor} radius={radius} withBorder={withBorder}>
        {src ? (
          <>
            <Img src={urlForShrinkedImg(src, '150x150')} alt={alt} />
          </>
        ) : svg ? (
          <SvgImg as={svg} />
        ) : (
          text || children || <SvgImg as={FallbackSvg} />
        )}
      </AvatarStyled>
      {badge && <BottomRight>{badge}</BottomRight>}
    </Wrapper>
  )
}

Avatar.propTypes = {
  children: PropTypes.node,
  radius: PropTypes.number,
  text: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  svg: PropTypes.elementType,
  color: PropTypes.string,
  badge: PropTypes.node,
}

Avatar.defaultProps = {
  radius: 20,
}

export default Avatar
