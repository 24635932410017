import React from 'react'
import {Wrapper} from './NotFound.styles'

const NotFound = ({...rest}) => (
  <Wrapper {...rest}>
    <b>Not Found</b>
  </Wrapper>
)

export default NotFound
