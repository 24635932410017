import React from 'react'
import * as PropTypes from 'prop-types'
import {Wrapper, Text} from './NavigationNoLink.styles'

const NavigationNoLink = ({text, iconSvg: IconSvg, iconSrc, ...rest}) => (
  <Wrapper {...rest}>
    {IconSvg && <IconSvg />}
    {iconSrc && <img src={iconSrc} alt='' />}
    <Text>{text}</Text>
  </Wrapper>
)

NavigationNoLink.propTypes = {
  text: PropTypes.string.isRequired,
  iconSvg: PropTypes.object,
  iconSrc: PropTypes.string,
}
NavigationNoLink.defaultProps = {
  iconSvg: null,
  iconSrc: null,
}

export default NavigationNoLink
