import useProfileInfo from 'hooks/api/useProfileInfo'
import {types} from '../Notifications.config'

export const useGetData = ({notification_type_id, payload}) => {
  const {getProfile} = types[notification_type_id]
  const profile = getProfile(payload)
  const {profileName} = useProfileInfo(profile)

  return {payload, profileName, notification_type_id}
}
