import styled from 'styled-components'

export const Loader = styled.div(({isAbsolute, dia}) => ({
  position: isAbsolute ? 'absolute' : 'relative',
  width: dia,
  height: dia,
  margin: '0 auto',

  ...(isAbsolute && {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
}))

export const LoaderSvg = styled.svg`
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`
export const LoaderSvgCircle = styled.circle`
  fill: none;
  stroke: ${({color, theme}) => theme.resolveColor(color, true)};
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
`
