import React from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {localizeRoutePath} from 'utils/i18n'
import * as PropTypes from 'prop-types'
import NotFound from 'views/NotFound'

const LocalizedSwitch = ({children}) => (
  <Switch>
    {React.Children.map(children, child => {
      const path = child.props.path && localizeRoutePath(child.props.path)
      return (
        child && [
          path && typeof path !== 'object' && (
            <Redirect
              strict
              exact
              from={`${path}/`}
              to={{
                pathname: path,
              }}
            />
          ),
          React.cloneElement(child, {
            ...child.props,
            ...(path && {path}),
          }),
        ]
      )
    })}
    <Route>
      <NotFound />
    </Route>
  </Switch>
)

LocalizedSwitch.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocalizedSwitch
