import styled from 'styled-components'
import {CarouselProvider, Slider, ButtonBack, ButtonNext} from 'pure-react-carousel'

export const Wrapper = styled.div(() => ({
  width: '100%',
}))

export const Ad = styled.a(({theme}) => ({
  borderRadius: theme.radii.large,
  background: theme.palette.grey[600],
  overflow: 'hidden',
  display: 'block',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

export const AdImg = styled.img(() => ({
  display: 'block',
  width: '100%',
}))

export const Carousel = styled(CarouselProvider)({
  position: 'relative',
})

export const SliderStyled = styled(Slider)({
  marginTop: '20px',
})

export const SlideImg = styled.img(({theme}) => ({
  width: '220px',
  borderRadius: '10px',
  [theme.breakpoints.down('tabletS')]: {
    padding: '5px',
    borderRadius: '20px',
    width: '-webkit-fill-available',
  },
}))

export const BackBtn = styled(ButtonBack)(({theme}) => ({
  position: 'absolute',
  top: '130px',
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.palette.grey[600],
  ':hover': {
    color: theme.palette.grey[100],
  },
}))

export const NextBtn = styled(ButtonNext)(({theme}) => ({
  position: 'absolute',
  top: '130px',
  right: '0',
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.palette.grey[600],
  ':hover': {
    color: theme.palette.grey[100],
  },
}))
