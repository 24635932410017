import styled from 'styled-components'
import ButtonBase from '@material-ui/core/ButtonBase'

export const CellOuter = styled(ButtonBase)(({theme}) => ({
  padding: '10px 0',
  flexBasis: '33.33%',
  width: '33.33%',
  maxWidth: '33.33%',
  borderLeft: `1px solid ${theme.palette.grey[600]}`,
  '&:first-child': {
    borderLeft: 'none',
  },
}))

export const CellInner = styled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}))

export const CellValue = styled.span(({theme}) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: theme.typography.fontSizes.medium,
  fontWeight: 500,
}))

export const CellCaption = styled.span(({theme}) => ({
  fontSize: theme.typography.fontSizes.extraSmall,
  color: theme.palette.grey[400],
  textTransform: 'uppercase',
}))
