import {number, shape, string} from 'prop-types'

const pressGeneralType = shape({
  id: number.isRequired,
  about: string,
})

export const pressProfileType = shape({
  id: number.isRequired,
  name: string.isRequired,
  website: string,
  general: pressGeneralType,
})
