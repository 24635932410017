import styled from 'styled-components'

export const StyledImage = styled.img(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}))

export const Wrapper = styled.div(({isLoaded, animDuration, animDelay}) => ({
  opacity: 0,
  display: 'flex',
  flexDirection: 'column',

  '.image-lazy-loader': {
    '&-wrapper': {
      flex: 1,
    },
  },

  ...(isLoaded && {
    animation: 'fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1',
    animationFillMode: 'forwards',
    animationDuration: animDuration,
    animationDelay: animDelay,
  }),

  '@keyframes fadeInImg': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))
