import React, {useMemo} from 'react'
import * as PropTypes from 'prop-types'
import {nFormatter} from 'utils/helpers'
import {CellOuter, CellInner, CellCaption, CellValue} from './StatisticsCell.styles'

const StatisticsCell = ({value, text, ...rest}) => {
  const formattedValue = useMemo(() => (value != null ? nFormatter(value) : '-'), [value])
  return (
    <CellOuter {...rest}>
      <CellInner>
        <CellValue>{formattedValue}</CellValue>
        <CellCaption>{text}</CellCaption>
      </CellInner>
    </CellOuter>
  )
}

StatisticsCell.propTypes = {
  value: PropTypes.number,
  text: PropTypes.string,
}

export default StatisticsCell
