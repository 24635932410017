import React from 'react'
import * as PropTypes from 'prop-types'
import {avatarText} from 'utils/helpers'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {StyledAvatar} from './ProfileAvatar.styles'

const ProfileAvatar = ({src, name, ...rest}) => {
  const matchTabletS = useMediaQuery(theme => theme.breakpoints.up('tabletS'))
  return (
    <StyledAvatar radius={matchTabletS ? 45 : 38} src={src} text={avatarText(name)} {...rest} />
  )
}

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
}

export default ProfileAvatar
