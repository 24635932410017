import {useContext, useMemo} from 'react'
import moment from 'moment'
import I18nContext from '../../contexts/I18nContext'

const useOnlineStatus = ({lastSeen, biasInSeconds = 25}) => {
  const secondsDiff = useMemo(() => (lastSeen ? moment().diff(lastSeen, 'seconds', false) : null), [
    lastSeen,
  ])
  const isOnline = useMemo(() => secondsDiff != null && secondsDiff < biasInSeconds, [
    biasInSeconds,
    secondsDiff,
  ])

  const {t} = useContext(I18nContext)
  const lastSeenText = useMemo(
    () =>
      lastSeen
        ? t('userProfileCard.lastSeenAgo', {agoText: moment(lastSeen).fromNow()})
        : t('common.offline'),
    [lastSeen, t]
  )

  return {secondsDiff, isOnline, lastSeenText}
}

export default useOnlineStatus

// import {useEffect, useState} from 'react'
// import moment from 'moment'
//
// const calcDiff = lastSeen => (lastSeen ? moment().diff(lastSeen, 'seconds', false) : null)
//
// const useOnlineStatus = ({lastSeen}) => {
//   const [seconds, setSeconds] = useState(null)
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       const newDiff = calcDiff(lastSeen)
//       setSeconds(newDiff)
//     }, 1000)
//     return () => clearInterval(intervalId)
//   }, [lastSeen])
//
//   return {seconds}
// }
//
// export default useOnlineStatus
