import React, {useContext, useEffect, useState} from 'react'
import SEO from 'components/SEO'
import {SocketContext} from 'containers/SocketProvider/SocketProvider'
import useAuthToken from 'hooks/auth/useAuthToken'
import I18nContext from 'contexts/I18nContext'
import BrowserNotifications from 'views/Notifications/BrowserNotifications'
import {
  setNotifications,
  setCount,
  setDeviceToken,
} from 'containers/NotificationProvider/actionTypes'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'
import FooterMenu from 'components/FooterMenu'
import {getPlayerFooterItems, getPressFooterItems, getAdminFooterNavItems} from 'data/navigation'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useSwipeHandler from 'hooks/helpers/useSwipeHandler'
import {Keyboard} from '@capacitor/keyboard'
import {Capacitor} from '@capacitor/core'
import {PushNotifications} from '@capacitor/push-notifications'
import routes from 'routes'
import refreshToken from 'utils/refreshToken'
import {useHistory} from 'react-router-dom'
import useApi from 'hooks/api/useApi'
import {Container, Content} from './MainLayout.styles'
import MainLayoutHeader from './MainLayoutHeader'
import MainLayoutLeftCol from './MainLayoutLeftCol'
import MainLayoutRightCol from './MainLayoutRightCol'

const MainLayout = ({children}) => {
  const {state: socketState} = useContext(SocketContext)
  const [token, setToken] = useAuthToken()
  const {swipeBack} = useSwipeHandler()
  const isCollapsed = useMediaQuery(theme => theme.breakpoints.down('collapseLeftRow'))
  const {t} = useContext(I18nContext)
  const {refetch} = usePersonalProfile()
  const {state: notificationState, dispatch} = useContext(NotificationContext)
  const handleReceived = notification => {
    dispatch(setCount(notification.count))
    dispatch(setNotifications(notification.notification))
  }
  const web = Capacitor.getPlatform() === 'web'

  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    const subscription = socketState?.cable?.subscriptions.create(
      {channel: `NotificationChannel`, token},
      {received: handleReceived}
    )
    return () => socketState?.cable?.subscriptions.remove(subscription)
  }, [])

  useEffect(() => {
    const subscription = socketState?.cable?.subscriptions.create(
      {channel: `PaypalChannel`, token},
      {received: refetch}
    )
    return () => socketState?.cable?.subscriptions.remove(subscription)
  }, [])

  useEffect(() => {
    const keyboardDidShowListener =
      !web &&
      Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardVisible(true)
      })
    const keyboardDidHideListener =
      !web &&
      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardVisible(false)
      })

    return () => {
      if (!web) {
        keyboardDidHideListener.remove()
        keyboardDidShowListener.remove()
      }
    }
  }, [])

  const hasToken = !!token
  const api = useApi()
  const history = useHistory()

  useEffect(() => {
    if (!web && hasToken && !notificationState.device_token) {
      PushNotifications.requestPermissions()
      PushNotifications.register()
      PushNotifications.addListener('registration', device_token => {
        api
          .registerDeviceToken({
            device_token: device_token.value,
            device_type: Capacitor.getPlatform(),
          })
          .then(
            () => dispatch(setDeviceToken(device_token.value)),
            error => console.log(error)
          )
      })
      PushNotifications.addListener('pushNotificationActionPerformed', res => {
        history.push(routes[res.notification.data.route].path)
        PushNotifications.removeAllDeliveredNotifications()
      })
      return () => PushNotifications.removeAllListeners()
    }
    return () => {}
  }, [hasToken])

  useEffect(() => {
    if (hasToken) refreshToken(api, token, setToken, history)
  }, [])

  return (
    <>
      <SEO />
      <MainLayoutHeader />
      <Container {...swipeBack}>
        <MainLayoutLeftCol />
        <Content>{children}</Content>
        <MainLayoutRightCol />
      </Container>
      {!isKeyboardVisible && isCollapsed && (
        <FooterMenu
          playerItems={getPlayerFooterItems(t)}
          pressItems={getPressFooterItems(t)}
          adminItems={getAdminFooterNavItems(t)}
        />
      )}
      <BrowserNotifications />
    </>
  )
}
export default MainLayout
