import lazyLoad from 'components/LazyLoading/LazyLoading'

const Login = lazyLoad(() => import('views/Login'))
const ChangeEmail = lazyLoad(() => import('views/ChangeEmail'))
const ResetPassword = lazyLoad(() => import('views/ResetPassword'))
const ResetPasswordCreateNew = lazyLoad(() => import('views/ResetPasswordCreateNew'))
const Register = lazyLoad(() => import('views/Register'))
const RegisterSelectType = lazyLoad(() => import('views/RegisterSelectType'))
const RegisterFillInfo = lazyLoad(() => import('views/RegisterFillInfo'))
const RegisterComplete = lazyLoad(() => import('views/RegisterComplete'))
const RegisterCompleteSuggestions = lazyLoad(() =>
  import('views/RegisterComplete/RegisterCompleteSuggestions')
)
const PersonalProfile = lazyLoad(() => import('views/PersonalProfile'))
const PremiumPricesManagement = lazyLoad(() => import('views/Admin/PremiumPricesManagement'))
const EditProfile = lazyLoad(() => import('views/EditProfile'))
const Feed = lazyLoad(() => import('views/Feed'))
const SinglePostPage = lazyLoad(() => import('views/SinglePostPage'))
const Settings = lazyLoad(() => import('views/Settings'))
const DetailSearch = lazyLoad(() => import('views/DetailSearch'))
const DetailSearchResults = lazyLoad(() => import('views/DetailSearchResults'))
const ContactTrafficLight = lazyLoad(() => import('views/ContactTrafficLight'))
const GetPremium = lazyLoad(() => import('views/GetPremium'))
const BillingAddress = lazyLoad(() => import('views/BillingAddress'))
const ModalOrder = lazyLoad(() => import('views/ModalOrder'))
const ModalVacancyPromote = lazyLoad(() => import('views/ModalVacancyPromote'))
const OrderPageIos = lazyLoad(() => import('views/OrderPageIos'))
const PromoteVacancyIos = lazyLoad(() => import('views/PromoteVacancyIos'))
const Faq = lazyLoad(() => import('views/Faq'))
const PlayerProfile = lazyLoad(() => import('views/PlayerProfile'))
const PressProfile = lazyLoad(() => import('views/PressProfile'))
const ClubProfile = lazyLoad(() => import('views/ClubProfile'))
const TeamProfile = lazyLoad(() => import('views/TeamProfile'))
const Teammates = lazyLoad(() => import('views/Teammates'))
const ProfileTeammates = lazyLoad(() => import('views/ProfileTeammates'))
const Bookmarks = lazyLoad(() => import('views/Bookmarks'))
const Teams = lazyLoad(() => import('views/Teams'))
const Team = lazyLoad(() => import('views/Team'))
const Chat = lazyLoad(() => import('views/Chat'))
const Vacancies = lazyLoad(() => import('views/Vacancies'))
const VacancySingle = lazyLoad(() => import('views/VacancySingle'))
const VacanciesManagement = lazyLoad(() => import('views/Admin/VacanciesManagement'))
const PremiumUsersPanel = lazyLoad(() => import('views/Admin/PremiumUsersPanel'))
const Notifications = lazyLoad(() => import('views/Notifications'))
const NotFound = lazyLoad(() => import('views/NotFound'))

const PlayersManagement = lazyLoad(() => import('views/Admin/PlayersManagement'))
const ClubManagement = lazyLoad(() => import('views/Admin/ClubManagement'))
const TeamsManagement = lazyLoad(() => import('views/Admin/TeamsManagement'))
const PressesManagement = lazyLoad(() => import('views/Admin/PressesManagement'))
const AdsManagement = lazyLoad(() => import('views/Admin/AdsManagement'))
const I18nManagement = lazyLoad(() => import('views/Admin/I18nManagement'))

// import Login from '../views/Login'
// import Register from '../views/Register'
// import RegisterSelectType from '../views/RegisterSelectType'
// import RegisterFillInfo from '../views/RegisterFillInfo'
// import RegisterComplete from '../views/RegisterComplete'
// import ResetPassword from '../views/ResetPassword'
// import ResetPasswordCreateNew from '../views/ResetPasswordCreateNew'
// import ChangeEmail from '../views/ChangeEmail'
// import PersonalProfile from '../views/PersonalProfile'
// import PlayerProfile from '../views/PlayerProfile'
// import ClubProfile from '../views/ClubProfile'
// import TeamProfile from '../views/TeamProfile'
// import PressProfile from '../views/PressProfile'
// import EditProfile from '../views/EditProfile'
// import Teammates from '../views/Teammates'
// import ProfileTeammates from '../views/ProfileTeammates'
// import Feed from '../views/Feed'
// import Faq from '../views/Faq'
// import GetPremium from '../views/GetPremium'
// import DetailSearch from '../views/DetailSearch'
// import DetailSearchResults from '../views/DetailSearchResults'
// import Chat from '../views/Chat'
// import ContactTrafficLight from '../views/ContactTrafficLight'
// import Notifications from '../views/Notifications'
// import Settings from '../views/Settings'
// import Team from '../views/Team'
// import Teams from '../views/Teams'
// import Bookmarks from '../views/Bookmarks'
// import Vacancies from '../views/Vacancies'
// import VacancySingle from '../views/VacancySingle'
// import NotFound from '../views/NotFound'
// import PlayersManagement from '../views/Admin/PlayersManagement'
// import ClubManagement from '../views/Admin/ClubManagement'
// import TeamsManagement from '../views/Admin/TeamsManagement'
// import I18nManagement from '../views/Admin/I18nManagement'
// import AdsManagement from '../views/Admin/AdsManagement'
// import PressesManagement from '../views/Admin/PressesManagement'

const routes = {
  auth: {
    path: '/auth',
    login: {
      path: '/auth/login',
      exact: true,
      component: Login,
    },
    register: {
      path: '/auth/register/select-type',
      exact: true,
      component: RegisterSelectType,
      selectAccType: {
        path: '/auth/register',
        exact: true,
        component: Register,
      },
      complete: {
        path: '/auth/register/complete',
        component: RegisterComplete,
        step: {
          path: '/auth/register/complete/:id',
          exact: true,
        },
      },
      suggestions: {
        path: '/auth/register/complete/last',
        component: RegisterCompleteSuggestions,
        exact: true,
      },
    },
    resetPassword: {
      path: '/auth/reset-password',
      exact: true,
      component: ResetPassword,
      createNew: {
        path: '/auth/reset-password/new',
        exact: true,
        component: ResetPasswordCreateNew,
      },
    },
    changeEmail: {
      path: '/auth/change-email',
      exact: true,
      component: ChangeEmail,
    },
  },
  home: {
    path: '/',
    exact: true,
  },
  profile: {
    personal: {
      path: '/profile',
      exact: true,
      component: PersonalProfile,
    },
    player: {
      path: '/players/:id',
      exact: true,
      component: PlayerProfile,
    },
    club: {
      path: '/clubs/:id',
      exact: true,
      component: ClubProfile,
    },
    team: {
      path: '/teams/:id',
      exact: true,
      component: TeamProfile,
    },
    press: {
      path: '/press/:id',
      exact: true,
      component: PressProfile,
    },
  },
  posts: {
    path: '/posts/:post_id',
    exact: true,
    component: SinglePostPage,
  },
  edit: {
    path: '/profile/edit',
    exact: true,
    component: EditProfile,
  },
  teammates: {
    path: '/teammates',
    exact: true,
    component: Teammates,
  },
  profileFollowers: {
    path: '/profile-followers/:id',
    exact: true,
    component: ProfileTeammates,
  },
  feed: {
    path: '/feed',
    exact: true,
    component: Feed,
  },
  faq: {
    path: '/faq',
    exact: true,
    component: Faq,
  },
  getPremium: {
    path: '/get-premium',
    onlyFor: ['player'],
    exact: true,
    component: GetPremium,
    billingAddress: {
      path: '/get-premium/:id/billing-address',
      exact: true,
      component: BillingAddress,
    },
    modalOrder: {
      path: '/get-premium/:id',
      exact: true,
      component: ModalOrder,
    },
    orderIos: {
      path: '/get-premium-ios/:id',
      exact: true,
      component: OrderPageIos,
    },
  },
  search: {
    path: '/search',
    onlyFor: ['player', 'club', 'team'],
    exact: true,
    component: DetailSearch,
    results: {
      path: '/search/results',
      onlyFor: ['player', 'club', 'team'],
      exact: true,
      component: DetailSearchResults,
    },
  },
  messages: {
    path: '/messages',
    onlyFor: ['player', 'club', 'team'],
    component: Chat,
    conversation: {
      path: '/messages/:id',
      exact: true,
    },
  },
  contactLight: {
    path: '/contact-light',
    onlyFor: ['player', 'team', 'club'],
    exact: true,
    component: ContactTrafficLight,
  },
  notifications: {
    path: '/notifications',
    exact: true,
    component: Notifications,
  },
  settings: {
    path: '/settings',
    component: Settings,
    account: {
      path: '/settings/account',
      exact: true,
    },
    privacy: {
      path: '/settings/privacy',
      exact: true,
    },
    notification: {
      path: '/settings/notification',
      exact: true,
    },
    purchases: {
      path: '/settings/purchases',
      exact: true,
      onlyFor: ['player'],
    },
    invites: {
      path: '/settings/invites',
      exact: true,
      onlyFor: ['player', 'team'],
    },
  },
  team: {
    path: '/team',
    onlyFor: ['team'],
    exact: true,
    component: Team,
  },
  teams: {
    path: '/teams',
    onlyFor: ['club'],
    exact: true,
    component: Teams,
  },
  bookmarks: {
    path: '/bookmarks',
    onlyFor: ['club', 'team'],
    exact: true,
    component: Bookmarks,
  },
  vacancies: {
    // self profile vacancies
    path: '/profile-vacancies',
    onlyFor: ['club', 'team'],
    exact: true,
    component: Vacancies,

    // other profile vacancies
    vacanciesOtherProfile: {
      path: '/profile-vacancies/:user_profile_id',
      exact: true,
      component: Vacancies,
    },

    // single vacancy page
    singleVacancy: {
      path: '/vacancies/:id',
      exact: true,
      component: VacancySingle,
    },

    promote: {
      path: '/promote-vacancies/:prod_id/:vacancy_id/',
      exact: true,
      component: ModalVacancyPromote,
    },
    promoteIos: {
      path: '/promote-vacancies-ios/:prod_id/:vacancy_id/',
      exact: true,
      component: PromoteVacancyIos,
    },
  },
  notFound: {
    path: '/not-found',
    exact: true,
    component: NotFound,
  },
  admin: {
    path: '/admin',
    onlyFor: ['admin'],
    exact: false,
    playersManagement: {
      path: '/admin/players-management',
      exact: true,
      component: PlayersManagement,
    },
    // clubManagement: {
    //   path: '/admin/club-management',
    //   exact: true,
    //   component: ClubManagement,
    // },
    teamsManagement: {
      path: '/admin/teams-management',
      exact: true,
      component: TeamsManagement,
    },
    pressesManagement: {
      path: '/admin/presses-management',
      exact: true,
      component: PressesManagement,
    },
    adsManagement: {
      path: '/admin/ads-management',
      exact: true,
      component: AdsManagement,
    },
    i18nManagement: {
      path: '/admin/i18n-management',
      exact: true,
      component: I18nManagement,
    },
    premiumPlans: {
      path: '/admin/premium-plans',
      exact: true,
      component: PremiumPricesManagement,
    },
    vacancies: {
      path: '/admin/vacancies',
      exact: true,
      component: VacanciesManagement,
    },
    premiumPanel: {
      path: '/admin/premium-panel',
      exact: true,
      component: PremiumUsersPanel,
    },
  },
}

export default routes
