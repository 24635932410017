import React, {useContext} from 'react'
import {CircleFlag} from 'react-circle-flags'
import {avatarText, calculateYears} from 'utils/helpers'
import {profileType} from 'types'
import {ButtonSubscribe} from 'components/profileCards/common'
import useProfileInfo from 'hooks/api/useProfileInfo'
import {getPositionLabel} from 'utils/i18n'
import I18nContext from 'contexts/I18nContext'
import {
  CardAvatar,
  CardInfo,
  CardName,
  CardWrapper,
  OuterWrapper,
  LinkAbsolute,
} from './PlayerSuggestionCard.styles'

const PlayerSuggestionCard = ({profile, refetchSuggestions, ...rest}) => {
  const {t} = useContext(I18nContext)
  const {profileName, profilePath} = useProfileInfo(profile)
  return (
    <OuterWrapper {...rest}>
      <CardWrapper>
        <LinkAbsolute to={profilePath} />
        <CardAvatar
          src={profile.profile_photo_url}
          radius={20}
          text={avatarText(profileName)}
          badge={
            profile.player_profile?.general?.nationality && (
              <CircleFlag countryCode={profile.player_profile?.general?.nationality} />
            )
          }
        />
        <CardName>{profileName}</CardName>
        <CardInfo>
          {[
            calculateYears(profile.player_profile?.general?.date_of_birth),
            getPositionLabel(profile.player_profile?.position?.name, t, null),
          ]
            .filter(s => !!s)
            .join(', ')}
        </CardInfo>
        <ButtonSubscribe
          w100
          profile={profile}
          refetchSuggestions={refetchSuggestions}
          fromPlayerSuggestions
        />
      </CardWrapper>
    </OuterWrapper>
  )
}

PlayerSuggestionCard.propTypes = {
  profile: profileType.isRequired,
}

export default PlayerSuggestionCard
