import {useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import {cacheKeys} from 'api'

/**
 * @param profile
 * @returns {{followersCount: number, followingsCount: number}}
 */
export default function useProfileFollows({profile}) {
  const api = useApi()

  const {data: followingsCount} = useQuery([cacheKeys.followingCount, profile?.id], async () => {
    if (profile) {
      const {meta} = await api.getFollowings({user_profile_id: profile.id, limit: 0})
      return meta?.total
    }
    return undefined
  })

  const {data: followersCount} = useQuery([cacheKeys.followersCount, profile?.id], async () => {
    if (profile) {
      const {meta} = await api.getFollowers({user_profile_id: profile.id, limit: 0})
      return meta?.total
    }
    return undefined
  })

  return {
    followingsCount,
    followersCount,
  }
}
