import styled from 'styled-components'

export const Wrapper = styled.div(({onClick}) => ({
  position: 'relative',
  width: 'fit-content',
  cursor: onClick ? 'pointer' : null,
  textDecoration: 'none',
  color: 'inherit',
}))

export const AvatarStyled = styled.div(({theme, bgColor, textColor, radius, withBorder}) => ({
  background: bgColor,
  color: textColor,
  border: withBorder && `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '50%',
  width: radius * 2,
  height: radius * 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  lineHeight: 1,
  userSelect: 'none',
  overflow: 'hidden',
  fontSize: radius * 0.75,
  fontWeight: 500,
  fontFamily: theme.typography.fontFamilyTitle,
  position: 'relative',
}))

export const Img = styled.img(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  textAlign: 'center',
}))

export const SvgImg = styled.svg(() => ({
  fill: 'currentColor',
  stroke: 'none',
  width: '1.5em',
  height: '1.5em',
  display: 'inline-block',
}))

export const BottomRight = styled.div(() => ({
  position: 'absolute',
  bottom: '-10%',
  right: '-10%',
  width: '35%',
  height: '35%',
  fill: 'currentColor',
  '& > img, & > svg': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'block',
  },
}))
