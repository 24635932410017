import React, {useContext} from 'react'
import * as PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import {translatedUrl} from 'utils/i18n'
import {avatarText} from 'utils/helpers'
import Avatar from 'components/Avatar'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import routes from 'routes'
import FeedbackView from 'views/FeedbackView'
import {
  Wrapper,
  Item,
  ItemsWrapper,
  HeadWrapper,
  HeadItemWrapper,
  ChevronRight,
  HeadItemText,
} from './NavigationLeft.styles'

const NavigationLeft = ({items, ...rest}) => {
  const {t} = useContext(I18nContext)
  const {profileName, profile, profileRole} = usePersonalProfile()
  return (
    <Wrapper {...rest}>
      <HeadWrapper>
        {profileRole === 'admin' ? (
          <HeadItemWrapper id='left-nav-admin-profile' to={translatedUrl(routes.admin.path)} exact>
            <Avatar radius={17} style={{marginRight: 10}} />
            <HeadItemText>{t('admin.navigation.hiAdmin')}</HeadItemText>
          </HeadItemWrapper>
        ) : (
          <HeadItemWrapper
            id={`left-nav-${profileRole}-profile`}
            to={translatedUrl(routes.profile.personal.path)}
          >
            <Avatar
              text={avatarText(profileName)}
              src={profile?.profile_photo_url}
              radius={17}
              style={{marginRight: 10}}
            />
            <HeadItemText>{t('navigation.yourProfile')}</HeadItemText>
            <ChevronRight />
          </HeadItemWrapper>
        )}
      </HeadWrapper>
      <ItemsWrapper>
        {items.map(({to, iconSvg, text, premiumIcon, unreadMessagesIcon}) => (
          <Item
            id={`left-nav-${to}`}
            key={to}
            to={translatedUrl(to)}
            text={text}
            iconSvg={iconSvg}
            premiumIcon={premiumIcon}
            unreadMessagesIcon={unreadMessagesIcon}
          />
        ))}
        <FeedbackView />
      </ItemsWrapper>
    </Wrapper>
  )
}

NavigationLeft.propTypes = {
  items: PropTypes.array,
}

export default NavigationLeft
