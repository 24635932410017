import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import routes from 'routes'
import logoSmall from 'images/common/logo-small.png'
import {Keyboard} from '@capacitor/keyboard'
import {Capacitor} from '@capacitor/core'
import {useHistory} from 'react-router-dom'
import useSwipeHandler from 'hooks/helpers/useSwipeHandler'
import {
  Wrapper,
  Content,
  BodyPaddingStyle,
  Logo,
  LogoSmall,
  LogoLink,
  LeftWrapper,
  RightWrapper,
  CenterItems,
  LogoLinkK,
  ArrowBack,
} from './HeaderTopBar.styles'
import IconButton from '../IconButton'

const HeaderTopBar = ({setOpened, isCollapsed, rightNode, centerNode}) => {
  const web = Capacitor.getPlatform() === 'web'
  const ios = Capacitor.getPlatform() === 'ios'
  const history = useHistory()
  const {handleBack} = useSwipeHandler()
  const mainPagesRegex = /\/feed$|\/messages$|\/profile$|\/notifications$/

  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  const mainPagesCheck = () => {
    if (mainPagesRegex.test(history.location.pathname)) return true
    return false
  }

  useEffect(() => {
    const keyboardDidShowListener =
      !web &&
      Keyboard.addListener('keyboardDidShow', () => {
        setKeyboardVisible(true)
      })
    const keyboardDidHideListener =
      !web &&
      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardVisible(false)
      })

    return () => {
      if (!web) {
        keyboardDidHideListener.remove()
        keyboardDidShowListener.remove()
      }
    }
  }, [])
  return (
    <Wrapper>
      <Content>
        <LeftWrapper>
          {!mainPagesCheck() && ios ? <ArrowBack onClick={handleBack}>Back</ArrowBack> : null}
          {isKeyboardVisible ? (
            <LogoLinkK to={routes.home.path}>
              <Logo />
              <LogoSmall src={logoSmall} alt='Poacher Logo' />
            </LogoLinkK>
          ) : (
            <LogoLink to={routes.home.path}>
              <Logo />
              <LogoSmall src={logoSmall} alt='Poacher Logo' />
            </LogoLink>
          )}
        </LeftWrapper>
        <BodyPaddingStyle />
        <CenterItems>{centerNode}</CenterItems>
        <RightWrapper>
          {rightNode}
          {/* {isCollapsed && <IconButton onClick={() => setOpened(true)} Icon={BurgerSvg} />} */}
        </RightWrapper>
      </Content>
    </Wrapper>
  )
}

HeaderTopBar.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  rightNode: PropTypes.node,
  centerNode: PropTypes.node,
}
HeaderTopBar.defaultProps = {}

export default HeaderTopBar
