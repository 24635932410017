import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import routes from 'routes/config'
import LoadingCircle from 'components/LoadingCircle'
import {translatedUrl} from 'utils/i18n'
import useObligatoryPath from 'hooks/helpers/useObligatoryPath'
import useUser from 'hooks/api/useUser'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import DefaultRoute from './DefaultRoute'

/**
 * Routes for pages where user must has active session
 */
const AuthRoute = ({...rest}) => {
  const {t} = useTranslation()
  const {userInfo, isLoading: isLoadingUser} = useUser()
  const {isLoading: isLoadingProfile} = usePersonalProfile()
  const obligatoryPath = useObligatoryPath()
  const location = useLocation()
  if (isLoadingUser || isLoadingProfile) return <LoadingCircle />
  if (!userInfo)
    return (
      <Redirect
        to={{
          pathname: translatedUrl(routes.auth.login.path),
          state: {from: location, message: t('auth.errors.pageAccessDenied')},
        }}
      />
    )
  if (obligatoryPath) return <Redirect to={obligatoryPath} />

  return <DefaultRoute {...rest} />
}

export default AuthRoute
