import React, {useMemo} from 'react'
import * as PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import colorJs from 'color'
import {useTheme} from '@material-ui/core/styles'
import LoadingCircle from 'components/LoadingCircle'
import {
  btnSizes,
  ButtonStyled,
  ContentLoadingWrapper,
  IconStartWrapper,
  IconEndWrapper,
} from './Button.styles'

const Button = React.forwardRef(
  (
    {
      children,
      text,
      to,
      href,
      isLoading,
      disabled,
      color: colorStr,
      textColor: textColorStr,
      size,
      outlined,
      startIcon,
      endIcon,
      ...other
    },
    ref
  ) => {
    const theme = useTheme()

    const {mainColor, mainColorDark, textColor} = useMemo(() => {
      const palette = theme.resolveColor(colorStr)

      const bg = palette?.main || palette
      const bgDark = palette?.dark || colorJs(bg).darken(0.15)

      let txtClr
      if (textColorStr) {
        txtClr = theme.resolveColor(textColorStr, true)
      } else if (!outlined) {
        txtClr = palette?.contrastText || theme.palette.getContrastText(bg)
      } else {
        txtClr = bg
      }

      return {mainColor: bg, mainColorDark: bgDark, textColor: txtClr}
    }, [colorStr, outlined, textColorStr, theme])

    const props = {
      ...other,
      size,
      textColor,
      mainColor,
      mainColorDark,
      isLoading,
      disabled: disabled || isLoading,
      outlined,
      ref,
    }
    let inner = (
      <>
        {startIcon && <IconStartWrapper>{startIcon}</IconStartWrapper>}
        {text ? <span>{text}</span> : children}
        {endIcon && <IconEndWrapper>{endIcon}</IconEndWrapper>}
      </>
    )
    if (isLoading)
      inner = (
        <>
          <LoadingCircle
            color={textColor}
            dia={btnSizes[size].height * 0.5}
            thickness={5}
            isAbsolute
          />
          <ContentLoadingWrapper>{inner}</ContentLoadingWrapper>
        </>
      )

    if (to)
      return (
        <ButtonStyled component={Link} to={to} {...props}>
          {inner}
        </ButtonStyled>
      )
    if (href)
      return (
        <ButtonStyled component='a' href={href} {...props}>
          {inner}
        </ButtonStyled>
      )
    return <ButtonStyled {...props}>{inner}</ButtonStyled>
  }
)

Button.propTypes = {
  size: PropTypes.oneOf(Object.keys(btnSizes)),
  color: PropTypes.string,
  textColor: PropTypes.string,
  outlined: PropTypes.bool,
  noBorder: PropTypes.bool,
  w100: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  text: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
}

Button.defaultProps = {
  size: 'def',
  color: 'primary',
  outlined: false,
  noBorder: false,
  w100: false,
  isLoading: false,
  disabled: false,
}

export default Button
