import React from 'react'
import * as PropTypes from 'prop-types'
import {ErrorText} from './FormError.styles'

const FormError = ({message, ...rest}) => message && <ErrorText {...rest}>{message}</ErrorText>

FormError.propTypes = {
  message: PropTypes.string,
}

FormError.defaultProps = {}

export default FormError
