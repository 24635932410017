import React, {useContext, useState, useMemo, useEffect, useRef} from 'react'
import I18nContext from 'contexts/I18nContext'
import {translatedUrl} from 'utils/i18n'
import IconButton from 'components/headers/common/IconButton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {ReactComponent as BurgerSvg} from 'images/icons/burger.svg'
import {ReactComponent as FaqSvg} from 'images/icons/faq.svg'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import {getPressNavItems, getPlayerFooterItems} from 'data/navigation'
import routes from 'routes'
import ButtonPremium from 'components/PremiumCard/ButtonPremium'
import Footer from 'components/Footer'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'
import {Wrapper, ItemsWrapper, Item, FooterWrapper} from './FooterMenuPress.styles'

const FooterMenuPress = ({items}) => {
  const {t} = useContext(I18nContext)
  const [opened, setOpened] = useState(false)
  const sidebarItems = useMemo(
    () => [
      ...getPressNavItems(t),
      {
        to: routes.faq.path,
        text: t('navigation.faq'),
        iconSvg: FaqSvg,
      },
    ],
    [t]
  )

  const {state} = useContext(NotificationContext)

  return (
    <>
      <FooterWrapper>
        <Wrapper>
          <ItemsWrapper>
            {items.map(({to, iconSvg, text, onclick, count, style, unreadMessagesIcon}) => (
              <Item
                id={`left-nav-${to}`}
                key={to}
                to={to ? translatedUrl(to) : `#`}
                text={text}
                iconSvg={iconSvg}
                onClick={onclick ? () => setOpened(true) : () => {}}
                count={count ? state?.count : ''}
                style={style}
                unreadMessagesIcon={unreadMessagesIcon}
              />
            ))}
          </ItemsWrapper>
        </Wrapper>
        <HeaderSideBar
          opened={opened}
          setOpened={setOpened}
          items={sidebarItems}
          bottomNode={
            <>
              <Footer />
            </>
          }
        />
      </FooterWrapper>
    </>
  )
}

export default FooterMenuPress
