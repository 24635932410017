import React, {useContext, useMemo} from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import I18nContext from 'contexts/I18nContext'
import {ReactComponent as IconSvg} from 'images/icons/profile-card/bookmark.svg'
import {profileType} from 'types'
import useBookmarks from 'hooks/api/bookmarks/useBookmarks'
import useMakeBookmark from 'hooks/api/bookmarks/useMakeBookmark'
import useUnMakeBookmark from 'hooks/api/bookmarks/useUnMakeBookmark'

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  flexGrow: 1,
  maxWidth: 120,
  minWidth: 'min-content',
})

const StyledIcon = styled(({isActive, ...props}) => <IconSvg {...props} />)(
  ({theme, isActive}) => ({
    width: '1.25em',
    stroke: theme.palette.primary.main,
    fill: isActive ? theme.palette.primary.main : 'none',
  })
)

const ButtonBookmark = ({profile, ...props}) => {
  const {t} = useContext(I18nContext)

  const {data: bookmarkedProfiles, isLoading: isLoadingProfiles} = useBookmarks()
  const [makeBookmark, makeMeta] = useMakeBookmark(profile?.id)
  const [makeUnBookmark, unMakeMeta] = useUnMakeBookmark(profile?.id)

  const isActive = useMemo(() => bookmarkedProfiles?.find(({id}) => id === profile?.id), [
    bookmarkedProfiles,
    profile?.id,
  ])

  return (
    <>
      <StyledButton
        color='primary'
        textColor='common.white'
        outlined
        text={isActive ? t('userProfileCard.btnBookmarked') : t('userProfileCard.btnBookmark')}
        startIcon={<StyledIcon isActive={isActive} />}
        onClick={isActive ? makeUnBookmark : makeBookmark}
        isLoading={makeMeta.isLoading || unMakeMeta.isLoading || isLoadingProfiles}
        {...props}
      />
    </>
  )
}

ButtonBookmark.propTypes = {
  profile: profileType,
}

export default ButtonBookmark
