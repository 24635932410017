import {number, shape, string} from 'prop-types'

export const teamGeneralType = shape({
  id: number.isRequired,
  stadium: string,
  about: string,
})

export const teamProfileType = shape({
  id: number.isRequired,
  name: string.isRequired,
  gender: string.isRequired,
  division: string.isRequired,
  league_level: number.isRequired,
  league_name: string.isRequired,
  club_profile_id: number.isRequired,
  general: teamGeneralType,
})
