import {defaultLanguage, languages} from 'i18n/config'
import i18n from 'i18n'
import {setLocale} from 'yup'

export function getCurrentLanguage() {
  return (i18n.languages && i18n.languages[0]) || defaultLanguage
}

export function translatedUrl(url) {
  if (url == null) return null
  return translatedUrlWithLang({
    pathname: url,
    lang: getCurrentLanguage(),
    // search: window.location.search,
  })
}

export function getLangFromUrl(pathname) {
  const splitPath = pathname.split('/').filter(x => x.length > 0)
  if (languages.includes(splitPath[0])) return splitPath[0]
  return defaultLanguage
}

export function translatedUrlWithLang({pathname, search = '', lang}) {
  let newPath = ''
  if (languages.includes(lang) && lang !== defaultLanguage) newPath += `/${lang}`

  let splitPath = pathname.split('/').filter(x => x.length > 0)
  if (languages.includes(splitPath[0])) splitPath = splitPath.slice(1)
  newPath += `/${splitPath.join('/')}`

  return newPath + search
}

export function translatedUrlObj(url) {
  return {
    pathname: translatedUrlWithLang({pathname: url, lang: getCurrentLanguage()}),
    search: window.location.search,
  }
}

export function previousUrl(url) {
  const splitPath = url.split('/').filter(x => x.length > 0)
  splitPath.pop()
  return `/${splitPath.join('/')}`
}

export function previousUrlObj(url) {
  return {
    pathname: previousUrl(url),
    search: window.location.search,
  }
}

const langPrefix = `/:locale(${languages.filter(l => l !== defaultLanguage).join('|')})?`

export const localizeRoutePath = path => {
  switch (typeof path) {
    case 'undefined':
      return undefined
    case 'object':
      return path.map(key => langPrefix + key)
    default:
      return path === '*' ? path : `${langPrefix}${path}`
  }
}

export const setYupLocaleKeys = () => {
  setLocale({
    string: {
      // email: values => ({key: 'emailNotValid', values}),
      // min: values => ({key: 'stringTooShort', values}),
      // max: values => ({key: 'stringTooLong', values}),
    },
  })
}

export const getGenderLabel = (key, t, defaultValue = '-') =>
  key ? t(`genders.${key}`) : defaultValue
export const getPositionLabel = (key, t, defaultValue = '-') =>
  key ? t(`positions.${key}`) : defaultValue
export const getPositionAbbreviationsLabel = (key, t, defaultValue = '-') =>
  key ? t(`positionsAbbreviations.${key}`) : defaultValue
export const getSkillLabel = (key, t, defaultValue = '-') =>
  key ? t(`skills.${key}`) : defaultValue
export const getAccTypeName = (key, t, defaultValue = '-') =>
  key ? t(`accTypes.${key}`) : defaultValue
