import React, {useContext, useMemo} from 'react'
import {ReactComponent as FaqSvg} from 'images/icons/faq.svg'
import LanguagesDropdown from 'components/LanguagesDropdown'
import HeaderTopBar from 'components/headers/common/HeaderTopBar'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import HeaderWrapper from 'components/headers/common/HeaderWrapper'
import ButtonFaq from 'components/headers/common/ButtonFaq'
import ButtonNotifications from 'components/headers/common/ButtonNotifications'
import Search from 'components/headers/common/Search'
import I18nContext from 'contexts/I18nContext'
import {getPressNavItems} from 'data/navigation'
import routes from 'routes'
import Footer from 'components/Footer'

const HeaderPress = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const sidebarItems = useMemo(
    () => [
      ...getPressNavItems(t),
      {
        to: routes.faq.path,
        text: t('navigation.faq'),
        iconSvg: FaqSvg,
      },
    ],
    [t]
  )
  return (
    <HeaderWrapper collapseWidth='collapseLeftRow' {...rest}>
      {({opened, setOpened, isCollapsed}) => (
        <>
          <HeaderTopBar
            opened={opened}
            setOpened={setOpened}
            isCollapsed={isCollapsed}
            centerNode={
              <>
                {!isCollapsed && <ButtonFaq />}
                <Search />
                {!isCollapsed && <ButtonNotifications />}
              </>
            }
            rightNode={!isCollapsed && <LanguagesDropdown />}
          />
          <HeaderSideBar
            opened={opened}
            setOpened={setOpened}
            items={sidebarItems}
            bottomNode={
              <>
                <Footer />
              </>
            }
          />
        </>
      )}
    </HeaderWrapper>
  )
}

export default HeaderPress
