import React, {useContext} from 'react'
import NavigationLeft from 'components/navigations/common/NavigationLeft'
import I18nContext from 'contexts/I18nContext'
import {getAdminNavItems} from 'data/navigation'

const NavigationLeftAdmin = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return <NavigationLeft items={getAdminNavItems(t)} {...rest} />
}

export default NavigationLeftAdmin
