import styled from 'styled-components'
import {ReactComponent as CloseIconSvg} from 'images/icons/close.svg'
import Button from 'components/Button'
import NavigationItem from 'components/navigations/common/NavigationItem'
import {
  Text,
  Wrapper as NavigationItemWrapper,
} from 'components/navigations/common/NavigationItem/NavigationItem.styles'
import {ReactComponent as ChevronRightSvg} from 'images/icons/chevron_right.svg'

export const Box = styled.div(({theme}) => ({
  position: 'fixed',
  width: 380,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  top: 0,
  padding: theme.spacing(1),
  right: 0,
  bottom: 0,
  transition: 'transform 300ms ease-out',
  background: theme.palette.grey[700],
  borderLeft: `1px solid ${theme.palette.grey[600]}`,
  fontSize: theme.fontSizes.small,
  transform: 'translateX(100%)',
  zIndex: 2,

  [`@media (max-width: ${500}px)`]: {
    width: '100%',
  },

  '&.slide-enter-done, &.slide-enter-active': {
    transform: 'translateX(0)',
  },

  '&.slide-exit-done, &.slide-exit-active': {
    transform: 'translateX(100%)',
  },
}))

export const ItemsWrapper = styled.div(() => ({
  flex: 1,
}))

export const Item = styled(NavigationItem)(({theme}) => ({
  minHeight: 38,
  marginBottom: theme.spacing(0.5),
}))

export const ProfileItem = styled(NavigationItemWrapper)(({theme}) => ({
  flex: 1,
  minHeight: 48,
  paddingRight: 0,
  marginBottom: theme.spacing(1),
}))

export const ProfileItemText = styled(Text)(() => ({}))

export const ChevronRight = styled(ChevronRightSvg)(() => ({
  marginRight: '0 !important',
}))

export const DarkBg = styled.div(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  transition: 'opacity 200ms linear',
  zIndex: 1,
  opacity: 0,

  '&.fade-enter-active, &.fade-enter-done': {
    opacity: 1,
  },

  '&.fade-exit-active, &.fade-exit-done': {
    opacity: 0,
  },
}))

export const BottomItemsWrapper = styled.div(() => ({
  width: '100%',
}))

export const TopRow = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  // paddingBottom: theme.spacing(1),
  paddingTop: 'env(safe-area-inset-top, 20px)',
  paddingLeft: 'env(safe-area-inset-left)',
  paddingRight: 'env(safe-area-inset-right)',
  paddingBottom: 'env(safe-area-inset-bottom)',
}))

export const CloseButton = styled(Button)`
  min-width: 38px;
  margin-left: 0.5em;
`

export const CloseIcon = styled(CloseIconSvg)`
  fill: currentColor;
  width: 12px;
  height: 12px;
`
