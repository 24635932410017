import React from 'react'
import {SnackbarProvider as NotistackProvider, useSnackbar} from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(({palette, radii}) => ({
  contentRoot: {
    borderRadius: radii.default,
  },
  // variantError: {
  //   background: `${palette.error.light} !important`,
  //   color: `${palette.error.dark} !important`,
  //   '& $icon': {
  //     color: `${palette.error.dark} !important`,
  //   },
  // },
}))

const CloseButton = ({id}) => {
  const {closeSnackbar} = useSnackbar()
  return (
    <IconButton size='small' onClick={() => closeSnackbar(id)}>
      <CloseIcon />
    </IconButton>
  )
}

const SnackbarProvider = ({children}) => {
  const classes = useStyles()
  return (
    <NotistackProvider
      maxSnack={3}
      preventDuplicate
      dense
      action={key => <CloseButton key={key} id={key} />}
      classes={classes}
    >
      {children}
    </NotistackProvider>
  )
}

export default SnackbarProvider
