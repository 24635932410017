export default function fontFace(name, src, fontWeight = 400, fontStyle = 'normal') {
  return `
      @font-face{
          font-family: "${name}";
          src: url("${src}");
          font-style: ${fontStyle};
          font-weight: ${fontWeight};
          font-display: auto;
      }
  `
}
