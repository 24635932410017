import moment from 'moment'
import {getCurrentLanguage} from './i18n'

export function elementPosition(elem) {
  if (!elem) return null
  const rect = elem.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
}

export function elementPositionById(id) {
  return elementPosition(document.getElementById(id))
}

export function hasOwnPropertyCall(obj, key) {
  if (!obj) return false
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function floatToFixed({number, maxNums = 8, locale = 'en'}) {
  if (number == null) return 0
  const decimals = number.toString().replace(',', '.').split('.')[0].length
  return parseFloat(number).toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: Math.min(20, Math.max(0, maxNums - decimals)),
  })
}

export function isNumber(value) {
  return !isNaN(Number(value))
}

export function capitalize(str) {
  return str?.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str
}

export const formatDate = (date, format = 'MMM D, YYYY, h:mm A') => {
  if (date === null) return date
  return moment(date).format(format)
}

export const formatNumber = ({number, language, ...options}) => {
  let result = number
  if (result === null) result = 0
  return parseFloat(result).toLocaleString(language, options)
}

export const formatCurrency = ({number, language, ...options}) => {
  const currency = (options && options.currency) || 'USD'
  if (currency !== 'USD' && currency !== 'EUR' && currency !== 'GBP') {
    // eslint-disable-next-line prefer-object-spread
    const newOptions = Object.assign({}, options, {
      style: undefined,
      currency: undefined,
      currencyDisplay: undefined,
    })
    return `${formatNumber({number, language, ...newOptions})} ${currency}`
  }

  let result = number
  if (result === null) result = 0

  return parseFloat(result).toLocaleString(language, {
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
    currency,
    style: 'currency',
  })
}

export const formatPercent = ({number, language, ...options}) => {
  let result = number
  if (result === null) result = 0
  return parseFloat(result).toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
    style: 'percent',
  })
}

export const avatarText = str => {
  if (!str) return null
  return str
    .split(' ')
    .splice(0, 2)
    .map(s => (s.length > 0 ? s[0] : ''))
    .join('')
    .toUpperCase()
}

export const calculateYears = birthDate => {
  if (!birthDate) return null
  return moment().diff(birthDate, 'years', false)
}

export const birthDateFormatting = (birthDate, formatString = 'DD.MM.YYYY') => {
  if (!birthDate) return null
  return moment(birthDate).format(formatString)
}

export const nFormatter = (num, digits = 1) => {
  if (!num) return 0
  const si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'K'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const countryToFlag = isoCode =>
  typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode

export function formatHeight(height) {
  if (height == null) return null
  return `${formatNumber({number: parseFloat(height) / 100, language: getCurrentLanguage()})} m`
}

export function sortPlayerExperiences(experiences) {
  return experiences?.sort((e1, e2) => {
    if (e1.is_current) return -1
    if (e2.is_current) return 1
    return moment(e2.contract_expires).valueOf() - moment(e1.contract_expires).valueOf()
  })
}

export function getPlayerCurrentTeam(experiences) {
  return experiences?.filter(({is_current}) => is_current)?.[0]
}

export function sortPlayerStatistics(statistics) {
  return statistics?.sort(
    (d1, d2) => moment(d2.period_end).valueOf() - moment(d1.period_end).valueOf()
  )
}

export function parseUrl(url) {
  if (!url) return null
  return url.indexOf('://') === -1 && url.indexOf('mailto:') === -1 ? `http://${url}` : url
}

export const removeFalsyProps = (obj, checkFunc) =>
  Object.entries(obj).reduce((a, [k, v]) => {
    if (checkFunc ? checkFunc(v) : !!v)
      return {
        ...a,
        [k]: v,
      }
    return a
  }, {})

// export const getFileTypeKey = (fileType, fallback = null) => {
//   if (!fileType) return fallback
//   for (const [typeKey, typesArr] of Object.entries(types)) {
//     if (typesArr.includes(fileType)) return typeKey
//   }
//   return fallback
// }

export const getFileTypeKey = fileType => {
  if (!fileType) return null
  return fileType.split('/')[0]
}
