import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import {ReactComponent as CloseIconSvg} from 'images/icons/delete.svg'
import ButtonBase from '@material-ui/core/ButtonBase'

export const ModalStyled = styled(Modal)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  outline: 'none',
  [theme.breakpoints.up('tabletS')]: {
    padding: theme.spacing(1.5),
  },
  paddingTop: 'env(safe-area-inset-top)',
  paddingBottom: 'env(safe-area-inset-bottom)',
}))

export const ModalContainer = styled.div(({theme, maxWidth}) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.grey[700],
  color: theme.palette.common.white,
  width: '100%',
  outline: 'none',
  minHeight: 150,
  maxWidth: maxWidth ?? 600,
  [theme.breakpoints.up('tabletS')]: {
    borderRadius: theme.radii.default,
  },
  [theme.breakpoints.down('tabletS')]: {
    flex: 1,
  },
}))

export const ModalHead = styled.div(({theme}) => ({
  margin: theme.spacing(0, 2),
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: 50,
  borderBottom: `1px solid ${theme.palette.grey[600]}`,
}))

export const ModalTitle = styled.div(({theme}) => ({
  ...theme.typography.h3,
  marginRight: '0.5em',
}))

export const CloseButton = styled(ButtonBase)({
  borderRadius: '50%',
})

export const CloseIcon = styled(CloseIconSvg)({
  width: 24,
  height: 24,
  fill: '#818C99',
})

export const ModalBody = styled.div(({theme}) => ({
  padding: theme.spacing(1.5, 2),
  flex: 1,
  overflowY: 'auto',
  fontSize: theme.fontSizes.small,
}))
