const cacheKeys = {
  user: 'user',
  skills: 'skills',
  positions: 'positions',
  personalProfile: 'personalProfile',
  userProfile: 'userProfile',
  playerProfile: 'playerProfile',
  pressProfile: 'pressProfile',
  clubProfile: 'clubProfile',
  teamProfile: 'teamProfile',
  userFeedPosts: 'userFeedPosts',
  userPosts: 'userPosts',
  postComments: 'postComments',

  followingProfiles: 'followingProfiles',
  followersProfiles: 'subscribedProfiles',
  followingCount: 'followingCount',
  followersCount: 'followersCount',
  givenFollows: 'givenFollows',
  receivedFollows: 'receivedFollows',
  teammates: 'teammates',

  bookmarks: 'bookmarks',

  clubTeams: 'clubTeams',

  messages: 'messages',

  vacancies: (user_profile_id, params) => {
    const key = `vacancies-${user_profile_id}`
    if (params) return [key, params]
    return key
  },
  vacancySingle: id => `vacancySingle-${id}`,

  team: (teamId, params) => {
    const key = `team-${teamId}`
    if (params) return [key, params]
    return key
  },

  notifications: 'notifications',
  notificationsUnreadCount: 'notificationsUnreadCount',

  ads: 'ads',
}

export default cacheKeys
