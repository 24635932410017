import React from 'react'
import PropTypes from 'prop-types'
import bgImg from 'images/bg/bg-register-complete.jpg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SEO from 'components/SEO'
import {BgImg, Container, ContainerChildren} from './RegisterCompleteLayout.styles'
import TopBar from './TopBar'

const RegisterCompleteLayout = ({children}) => {
  const matchMobile = useMediaQuery(theme => theme.breakpoints.up('mobileL'))
  return (
    <Container>
      <SEO />
      <TopBar />
      {matchMobile && <BgImg src={bgImg} />}
      <ContainerChildren>{children}</ContainerChildren>
    </Container>
  )
}

RegisterCompleteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RegisterCompleteLayout
