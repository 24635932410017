import styled from 'styled-components'
import ContentContainer from 'components/ContentContainer'
import StickyBox from 'react-sticky-box'

export const LeftCol = styled(StickyBox)(({theme}) => ({
  maxWidth: 290,
  minWidth: 200,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginRight: theme.spacing(2),
}))

export const RightCol = styled(StickyBox)(({theme}) => ({
  maxWidth: 290,
  minWidth: 180,
  flex: 1,
  marginLeft: theme.spacing(2),
}))

export const Content = styled.main(() => ({
  flex: 2,
  position: 'relative',
  minWidth: 0,
  alignSelf: 'stretch',
}))

export const Container = styled(ContentContainer)(({theme}) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-start',
  position: 'relative',
  paddingTop: 15,
  paddingBottom: 70,
}))
