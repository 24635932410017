import React, {useContext, useState, useEffect} from 'react'
import Modal from 'components/Modal'
import I18nContext from 'contexts/I18nContext'
import Input from 'components/inputs/Input'
import {useMutation} from 'react-query'
import Form from 'components/Form'
import Button from 'components/Button'
import useApi from 'hooks/api/useApi'
import {Message} from './FeedbackModal.styles'

const FeedbackModal = ({open, onClose}) => {
  const {t} = useContext(I18nContext)
  const api = useApi()
  const [description, setDescription] = useState('')
  const [feedbackSuccess, setFeedbackSuccess] = useState(false)

  const [sendFeedback, {reset}] = useMutation(() => api.sendFeedback({description}), {
    onSuccess: () => {
      setFeedbackSuccess(true)
      reset()
    },
  })

  const handleDescription = e => {
    e.preventDefault()
    setDescription(e.target.value)
  }

  useEffect(() => {
    if (!open) {
      setFeedbackSuccess(false)
    }
  }, [open])

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={t('feedback.titleModal')}
        style={{
          paddingTop: 'env(safe-area-inset-top)',
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        {!feedbackSuccess ? (
          <Form>
            <Input
              name='description'
              multiline
              onChange={handleDescription}
              minRows={3}
              maxRows={8}
              placeholder={t('feedback.input')}
            />
            <Button
              type='submit'
              onClick={sendFeedback}
              minWidth={140}
              color='secondary'
              text={t('feedback.sendFeedback')}
              disabled={!description}
            />
          </Form>
        ) : (
          <Message>{t('feedback.message')}</Message>
        )}
      </Modal>
    </>
  )
}

export default FeedbackModal
