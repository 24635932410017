import React from 'react'
import routes from 'routes/config'
import {ReactComponent as HomeSvg} from 'images/icons/menu/home.svg'
import {ReactComponent as SearchSvg} from 'images/icons/menu/detailed_search.svg'
import {ReactComponent as UsersSvg} from 'images/icons/menu/teammates.svg'
import {ReactComponent as MessagesSvg} from 'images/icons/menu/messages.svg'
import {ReactComponent as ContactlightSvg} from 'images/icons/menu/contactlight.svg'
// import {ReactComponent as RequestSvg} from 'images/icons/menu/request.svg'
import {ReactComponent as BurgerSvg} from 'images/icons/burger.svg'
import {ReactComponent as FallbackSvg} from 'images/icons/avatar_default.svg'
import {ReactComponent as BellSvg} from 'images/icons/bell.svg'
import {ReactComponent as SettingsSvg} from 'images/icons/menu/settings.svg'
import {ReactComponent as TeamSvg} from 'images/icons/menu/team_club.svg'
import {ReactComponent as BookmarksSvg} from 'images/icons/menu/bookmarks.svg'
import {ReactComponent as VacancySvg} from 'images/icons/menu/vacancy.svg'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import StarsIcon from '@material-ui/icons/Stars'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined'
import * as PropTypes from 'prop-types'
import palette from 'styles/theme/palette'

export const getPlayerNavItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.search.path,
    text: t('navigation.search'),
    iconSvg: SearchSvg,
  },
  {
    to: routes.teammates.path,
    text: t('navigation.teammates'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.messages.path,
    text: t('navigation.messages'),
    iconSvg: MessagesSvg,
    unreadMessagesIcon: <FiberManualRecordIcon />,
  },
  {
    to: routes.contactLight.path,
    text: t('navigation.contactTraffic'),
    iconSvg: ContactlightSvg,
    premiumIcon: <StarsIcon />,
  },
  {
    to: routes.settings.path,
    text: t('navigation.settings'),
    iconSvg: SettingsSvg,
  },
]

export const getClubNavItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.teams.path,
    text: t('navigation.teams'),
    iconSvg: TeamSvg,
  },
  {
    to: routes.search.path,
    text: t('navigation.search'),
    iconSvg: SearchSvg,
  },
  {
    to: routes.teammates.path,
    text: t('navigation.teammates'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.contactLight.path,
    text: t('navigation.contactLight'),
    iconSvg: ContactlightSvg,
  },
  {
    to: routes.messages.path,
    text: t('navigation.messages'),
    iconSvg: MessagesSvg,
  },
  {
    to: routes.bookmarks.path,
    text: t('navigation.bookmarks'),
    iconSvg: BookmarksSvg,
  },
  {
    to: routes.vacancies.path,
    text: t('navigation.vacancy'),
    iconSvg: VacancySvg,
  },
]

export const getTeamNavItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.team.path,
    text: t('navigation.team'),
    iconSvg: TeamSvg,
  },
  {
    to: routes.search.path,
    text: t('navigation.search'),
    iconSvg: SearchSvg,
  },
  {
    to: routes.teammates.path,
    text: t('navigation.teammates'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.contactLight.path,
    text: t('navigation.contactLight'),
    iconSvg: ContactlightSvg,
  },
  {
    to: routes.messages.path,
    text: t('navigation.messages'),
    iconSvg: MessagesSvg,
    unreadMessagesIcon: <FiberManualRecordIcon />,
  },
  {
    to: routes.bookmarks.path,
    text: t('navigation.bookmarks'),
    iconSvg: BookmarksSvg,
  },
  {
    to: routes.vacancies.path,
    text: t('navigation.vacancy'),
    iconSvg: VacancySvg,
  },
]

export const getPressNavItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.teammates.path,
    text: t('navigation.subscribers'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.settings.path,
    text: t('navigation.settings'),
    iconSvg: SettingsSvg,
  },
]

export const getAdminNavItems = t => [
  // {
  //   to: routes.admin.clubManagement.path,
  //   text: t('navigation.admin.clubsManagement'),
  //   iconSvg: UsersSvg,
  // },
  {
    to: routes.admin.pressesManagement.path,
    text: t('navigation.admin.pressesManagement'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.playersManagement.path,
    text: t('navigation.admin.playersManagement'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.teamsManagement.path,
    text: t('navigation.admin.teamsManagement'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.adsManagement.path,
    text: t('navigation.admin.adsManagement'),
    iconSvg: ContactlightSvg,
  },
  // {
  //   to: routes.admin.i18nManagement.path,
  //   text: t('navigation.admin.i18nManagement'),
  //   iconSvg: BookmarksSvg,
  // },
  {
    to: routes.settings.path,
    text: t('navigation.settings'),
    iconSvg: SettingsSvg,
  },
  {
    to: routes.admin.premiumPlans.path,
    text: t('navigation.admin.premiumPlans.title'),
    iconSvg: StarsOutlinedIcon,
  },
  {
    to: routes.admin.vacancies.path,
    text: 'Vacancies',
    iconSvg: WorkOutlineIcon,
  },
  {
    to: routes.admin.premiumPanel.path,
    text: 'Premium Users Panel',
    iconSvg: CardMembershipIcon,
  },
]

export const getAdminFooterNavItems = t => [
  {
    to: routes.admin.pressesManagement.path,
    text: t('navigation.admin.pressesManagmentFooter'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.playersManagement.path,
    text: t('navigation.admin.playersManagmentFooter'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.teamsManagement.path,
    text: t('navigation.admin.teamsManagmentFooter'),
    iconSvg: UsersSvg,
  },
  {
    to: routes.admin.adsManagement.path,
    text: t('navigation.admin.adsFooterMenu'),
    iconSvg: ContactlightSvg,
  },
  // {
  //   to: routes.admin.i18nManagement.path,
  //   text: t('navigation.admin.i18nManagement'),
  //   iconSvg: BookmarksSvg,
  // },
  {
    to: routes.settings.path,
    text: t('navigation.settings'),
    iconSvg: SettingsSvg,
  },
]

export const getPlayerFooterItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.messages.path,
    text: t('navigation.messages'),
    iconSvg: MessagesSvg,
    unreadMessagesIcon: <FiberManualRecordIcon />,
  },
  {
    to: routes.profile.personal.path,
    text: t('navigation.profile'),
    iconSvg: PersonOutlineOutlinedIcon,
  },
  {
    to: routes.notifications.path,
    text: t('navigation.notifications'),
    iconSvg: BellSvg,
    count: PropTypes.number,
  },
  {
    text: t('navigation.menu'),
    iconSvg: BurgerSvg,
    onclick: PropTypes.func,
    style: {backgroundColor: 'transparent', fill: palette.grey[400]},
  },
]

export const getPressFooterItems = t => [
  {
    to: routes.feed.path,
    text: t('navigation.feed'),
    iconSvg: HomeSvg,
  },
  {
    to: routes.profile.personal.path,
    text: t('navigation.profile'),
    iconSvg: PersonOutlineOutlinedIcon,
  },
  {
    to: routes.notifications.path,
    text: t('navigation.notifications'),
    iconSvg: BellSvg,
    count: PropTypes.number,
  },
  {
    text: t('navigation.menu'),
    iconSvg: BurgerSvg,
    onclick: PropTypes.func,
    style: {backgroundColor: 'transparent', fill: palette.grey[400]},
  },
]
