import {useMemo} from 'react'
import routes from 'routes/config'
import {useRouteMatch} from 'react-router-dom'
import {localizeRoutePath, translatedUrl} from 'utils/i18n'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import {useRegisterAccType} from 'hooks/persisted'
import useUser from 'hooks/api/useUser'

export default function useObligatoryPath() {
  const {profile, profileRole} = usePersonalProfile()
  const {userInfo} = useUser()
  const [selectedType] = useRegisterAccType()

  const matchSelectTypePath = useRouteMatch({
    ...routes.auth.register,
    path: localizeRoutePath(routes.auth.register.path),
  })
  // const matchFillInfoPath = useRouteMatch({
  //   ...routes.auth.register.fillInfo,
  //   path: localizeRoutePath(routes.auth.register.fillInfo.path),
  // })

  const obligatoryPath = useMemo(() => {
    // user must be a team and has not already completed fill info step
    // if (profileRole === 'user' && userInfo?.invite_token) {
    //   return routes.auth.register.fillInfo
    // }
    // if user has no profile
    // if (profileRole === 'user' && !matchSelectTypePath) {
    //   if (!selectedType) {
    //     return routes.auth.register
    //   }
    // }

    if (!profile) return null

    // if (profileRole === 'player') {
    //   // if (profile?.player_profile?.experiences?.length <= 0) return routes.auth.register.fillInfo
    //   return routes.auth.register.suggestions
    // }

    // if (profileRole === 'team' && !profile?.team_profile?.verified) {
    //   return routes.auth.register.complete
    // }

    // if (profileRole === 'team' && (!profile?.team_profile?.general || !profile?.address)) {
    //   return routes.auth.register.complete
    // }

    // if (profileRole === 'press' && !profile?.press_profile?.verified) {
    //   return routes.auth.register.complete
    // }

    return null
  }, [
    // matchFillInfoPath,
    matchSelectTypePath,
    profile,
    profileRole,
    selectedType,
    userInfo?.invite_token,
  ])

  const matchCurrentPath = useRouteMatch({
    ...obligatoryPath,
    path: localizeRoutePath(obligatoryPath?.path),
  })

  return matchCurrentPath ? null : translatedUrl(obligatoryPath?.path)
}
