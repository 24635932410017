import React from 'react'
import * as PropTypes from 'prop-types'
import {useQuery} from 'react-query'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useApi from 'hooks/api/useApi'
import {Tooltip} from '@material-ui/core'
import {Wrapper, Text, PremiumIcon, UnreadMessages} from './NavigationItem.styles'

const NavigationItem = ({
  text,
  iconSvg: IconSvg,
  iconSrc,
  premiumIcon,
  unreadMessagesIcon,
  top,
  ...rest
}) => {
  const api = useApi()
  const {profile} = usePersonalProfile()

  const {data} = useQuery(
    `unreadCountReq ${profile?.id}`,
    async () => profile && api.get(`/user_profiles/${profile?.id}/new_requests_or_messages`),
    {refetchInterval: 6000}
  )

  const unReadMessagesOrRequests = data?.new_messages + data?.new_requests > 0

  return (
    <Wrapper {...rest}>
      {IconSvg && <IconSvg />}
      {iconSrc && <img src={iconSrc} alt='' />}
      <Text>{text}</Text>
      <Tooltip title='Premium'>
        <PremiumIcon>{premiumIcon}</PremiumIcon>
      </Tooltip>
      {unReadMessagesOrRequests && (
        <Tooltip title='You have some unread messages'>
          <UnreadMessages top={top}>{unreadMessagesIcon}</UnreadMessages>
        </Tooltip>
      )}
    </Wrapper>
  )
}

NavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  iconSvg: PropTypes.object,
  iconSrc: PropTypes.string,
  premiumIcon: PropTypes.object,
  unreadMessagesIcon: PropTypes.object,
  top: PropTypes.bool,
}
NavigationItem.defaultProps = {
  iconSvg: null,
  iconSrc: null,
  premiumIcon: null,
  unreadMessages: null,
  top: false,
}

export default NavigationItem
