import styled from 'styled-components'
import {CircleFlag} from 'react-circle-flags'
import SocialIconsGroup from 'components/SocialIconsGroup'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FlexRow from 'components/FlexRow'
import OnlineIndicator from '../../OnlineIndicator'

export const CardWrapper = styled.div(({theme}) => ({
  background: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: theme.radii.large,
}))

export const CardInner = styled.div(({theme}) => ({
  padding: '15px 20px',
  [theme.breakpoints.down('tabletS')]: {
    padding: 10,
  },
}))

export const MiddleRowWrapper = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}))

export const MiddleRowLeftContent = styled.div(({theme}) => ({
  display: 'flex',
  flex: 1,
  marginBottom: 15,
  minWidth: 250,
  [theme.breakpoints.up('mobileS')]: {
    marginRight: 10,
    width: '100%',
  },
}))

export const MiddleRowRightContent = styled.div(({theme}) => ({
  flex: 1,
  minWidth: 250,
}))

export const GeneralInfoWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}))

export const SocialIcons = styled(SocialIconsGroup)(() => ({
  marginTop: 10,
}))

export const TopWrapper = styled.div(({theme}) => ({
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}))

export const TopContent = styled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const TopNameWrapper = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',

  [theme.breakpoints.down('tabletS')]: {
    marginTop: 5,
  },
}))

export const ProfileName = styled.div(({theme}) => ({
  marginRight: '5px',
  display: 'inline-block',
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: theme.typography.fontSizes.title,
  fontWeight: 500,
  lineHeight: 1.12,

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.fontSizes.large,
  },

  [theme.breakpoints.down('mobileL')]: {
    fontSize: theme.typography.fontSizes.medium,
  },
}))

export const NationalityFlag = styled(CircleFlag)(({theme}) => ({
  marginRight: 8,
  width: 14,
  height: 14,
}))

export const ButtonsRow = styled(FlexRow)({
  marginTop: 15,
  width: '100%',
})

export const OnlineBadge = styled(OnlineIndicator)({
  margin: '5px 6px',
})

export const CheckMark = styled(CheckCircleOutlineIcon)(({theme}) => ({
  marginRight: '10px',
  color: theme.palette.primary.main,
}))
