import React from 'react'
import useInputProps from 'hooks/form/useInputProps'
import * as PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Container,
  Error,
  inputNatures,
  inputSizes,
  InputStyled,
  Label,
  TooltipIcon,
} from './Input.styles'

const Input = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    error,
    tooltip,
    className,
    style,
    styleProps,
    inputRef,
    ...rest
  } = useInputProps(props)
  return (
    <Container ref={ref} className={className} style={style}>
      <Error message={error} {...styleProps} />
      <InputStyled id={id} inputRef={inputRef} {...styleProps} {...rest} />
      {label && (
        <Label htmlFor={id} {...styleProps}>
          <span>{label}</span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <TooltipIcon />
            </Tooltip>
          )}
        </Label>
      )}
    </Container>
  )
})

Input.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(inputSizes)),
  nature: PropTypes.oneOf(Object.keys(inputNatures)),
  color: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  withBorder: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Input.defaultProps = {
  size: 'def',
  color: 'secondary',
  type: 'text',
  withBorder: true,
}

export default Input
