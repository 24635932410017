import styled from 'styled-components'
import ButtonBase from '@material-ui/core/ButtonBase'
import Avatar from 'components/Avatar'
import {Link} from 'react-router-dom'
import React from 'react'

export const OuterWrapper = styled.div(() => ({
  paddingTop: 20,
  margin: '0 auto',
  width: '100%',
}))

export const CardWrapper = styled.div(({theme}) => ({
  paddingBottom: 15,
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  background: theme.palette.grey[850],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: theme.radii.large,
  position: 'relative',
  [theme.breakpoints.down('tablet')]: {
    padding: '0 8px 8px 8px',
  },
}))

export const LinkAbsolute = styled(props => <ButtonBase component={Link} {...props} />)(
  ({theme}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    borderRadius: theme.radii.large,
  })
)

export const CardAvatar = styled(Avatar)(() => ({
  marginTop: -20,
  zIndex: 1,
}))

export const CardName = styled.span(({theme}) => ({
  ...theme.typography.h3,
  margin: '0.5em 0 0.2em',
  fontWeight: 600,
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    ...theme.typography.h4,
  },
}))

export const CardInfo = styled.span(({theme}) => ({
  marginBottom: 12,
  display: 'inline-block',
  fontSize: theme.fontSizes.small,
  color: theme.palette.grey[400],
  textAlign: 'center',
  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.fontSizes.extraSmall,
  },
}))
