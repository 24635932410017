import {useContext, useMemo} from 'react'
import {generatePath} from 'react-router-dom'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes/config'
import I18nContext from 'contexts/I18nContext'

/**
 * @param profile
 * @param options
 */
export default function useProfileInfo(profile, {fullName = true} = {}) {
  const {language} = useContext(I18nContext)

  // get profile role based on props
  const profileRole = useMemo(() => {
    if (!profile) return null
    if (profile.player_profile) return 'player'
    // if (profile.club_profile) return 'club'
    if (profile.team_profile) return 'team'
    if (profile.press_profile) return 'press'
    return 'user'
  }, [profile])

  // get profile name
  const profileName = useMemo(() => {
    if (profileRole === 'player') return profile?.player_profile?.name
    // if (profileRole === 'club') return profile?.club_profile?.name
    if (profileRole === 'team') {
      if (fullName) return `${profile?.team_profile?.name} (${profile?.team_profile?.club_name})`
      return profile?.team_profile?.name
    }
    if (profileRole === 'press') return profile?.press_profile?.name
    return null
  }, [
    fullName,
    // profile?.club_profile?.name,
    profile?.player_profile?.name,
    profile?.press_profile?.name,
    profile?.team_profile?.club_name,
    profile?.team_profile?.name,
    profileRole,
  ])

  // method url for api for editing profile
  const methodUrl = useMemo(() => {
    if (profileRole === 'player') return '/player/profiles'
    // if (profileRole === 'club') return '/club/profiles'
    if (profileRole === 'team') return '/team/profiles'
    if (profileRole === 'press') return '/press/profiles'
    return null
  }, [profileRole])

  // instance key name
  const instanceName = useMemo(() => {
    if (profileRole === 'player') return 'player_profile'
    // if (profileRole === 'club') return 'club_profile'
    if (profileRole === 'team') return 'team_profile'
    if (profileRole === 'press') return 'press_profile'
    return null
  }, [profileRole])

  // instance object
  const instance = profile?.[instanceName]
  // instance id
  const instanceId = instance?.id

  // profile path
  const profilePath = useMemo(() => {
    if (!instanceId) return null
    return translatedUrl(generatePath(routes.profile[profileRole]?.path, {id: instanceId}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profileRole, language])

  return {
    profileName,
    profileRole,
    profilePath,
    methodUrl,
    instanceName,
    instance,
    instanceId,
  }
}
