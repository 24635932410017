import styled from 'styled-components'
import color from 'color'
import ButtonBase from '@material-ui/core/ButtonBase'
import {mobileSize, sizes} from '../HeaderTopBar/HeaderTopBar.styles'

export const Wrapper = styled(ButtonBase)(({theme, $isSelected}) => ({
  appearance: 'none',
  position: 'relative',
  fontWeight: 500,
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  transitionDuration: `${theme.transitions.duration.short}ms`,
  borderRadius: theme.radii.default,
  padding: '0 4px',
  background: theme.palette.grey[600],
  color: $isSelected ? theme.palette.primary.main : theme.palette.grey[400],
  fill: 'currentColor',
  stroke: 'currentColor',

  maxWidth: 'none',
  height: sizes.default.buttonSize,
  width: sizes.default.buttonSize,
  [`@media (max-width: ${mobileSize}px)`]: {
    height: sizes.mobile.buttonSize,
    width: sizes.mobile.buttonSize,
  },
  '& > svg': {
    width: '50%',
    height: '50%',
  },

  '&:hover': {
    background: color(theme.palette.grey[600]).darken(0.15),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
}))

export const SelectedAbsolute = styled.div(({theme, $isSelected}) => ({
  borderRadius: theme.radii.default,
  position: 'absolute',
  top: 5,
  left: 5,
  right: 5,
  bottom: 5,
  opacity: $isSelected ? 1 : 0,
  background: color(theme.palette.primary.main).fade(0.9),
  zIndex: 0,
}))

export const Count = styled.div(({theme}) => ({
  borderRadius: '50rem',
  position: 'absolute',
  top: 4,
  right: 4,
  background: theme.palette.info.main,
  color: theme.palette.common.white,
  zIndex: 0,
  fontSize: 9,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 14,
  minWidth: 14,
  padding: '0 2px',
}))

export const TextSpan = styled.span(({theme}) => ({
  display: 'inline-block',
  paddingRight: '20px',
  marginLeft: '-10px',
}))
