import {shape, number, string, arrayOf, bool} from 'prop-types'
import {footType, positionType, skillType} from './other'

export const playerGeneralType = shape({
  id: number.isRequired,
  residence: string.isRequired,
  nationality: string.isRequired,
  place_of_birth: string,
  date_of_birth: string.isRequired,
  about: string,
})

export const playerExperienceType = shape({
  id: number.isRequired,
  // club info
  club_profile_id: number,
  club_name: string,
  division: string.isRequired,
  // team info
  team_profile_id: number,
  team_name: string,
  // league info
  league_level: number.isRequired,
  league_name: string.isRequired,
  // other
  country: string.isRequired,
  is_current: bool.isRequired,
  joined: string,
  contract_expires: string,
})

export const playerCharacteristicsType = shape({
  id: number.isRequired,
  height: number,
  weight: number,
  second_position: positionType,
  player_number: number,
  foot: footType,
  skills: arrayOf(skillType),
})

export const playerStatisticType = shape({
  id: number.isRequired,
  period_start: string.isRequired,
  period_end: string.isRequired,
  games_count: number.isRequired,
  starting_xi: number.isRequired,
  substitutes: number.isRequired,
  assists: number.isRequired,
  goals: number.isRequired,
  yellow_cards: number.isRequired,
  second_yellow_cards: number.isRequired,
  red_cards: number.isRequired,
})

export const playerProfileType = shape({
  id: number.isRequired,
  name: string.isRequired,
  gender: string.isRequired,
  position: positionType.isRequired,

  contact_light: number,
  transfer_status: bool.isRequired,

  general: playerGeneralType,
  characteristic: playerCharacteristicsType,
  statistics: arrayOf(playerStatisticType),
  experiences: arrayOf(playerExperienceType),
})
