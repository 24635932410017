import * as yup from 'yup'
import 'yup-phone'

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'website', function () {
  return this.matches(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    {
      excludeEmptyString: true,
      message: "This url doesn't seem to look valid",
    }
  )
})

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

// eslint-disable-next-line func-names
yup.addMethod(yup.number, 'numberNullable', function () {
  return this.transform(emptyStringToNull).nullable()
})

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'validatePhone', function () {
  // eslint-disable-next-line func-names
  return this.test('test-phone', "This phone number doesn't seem to look valid", value => {
    if (value) return yup.string().phone().isValidSync(value)
    return true
  })
})
