import {number, shape, string} from 'prop-types'

export const clubGeneralType = shape({
  id: number.isRequired,
  stadium: string,
  about: string,
})

export const clubProfileType = shape({
  id: number.isRequired,
  name: string.isRequired,
  country: string.isRequired,
  general: clubGeneralType,
})
