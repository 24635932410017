import styled from 'styled-components'
import NavigationItemFooter from 'components/navigations/common/NavigationItemFooter'

export const sizes = {
  default: {
    height: 80,
    buttonSize: 50,
  },
  mobile: {
    height: 60,
    buttonSize: 40,
  },
}

export const mobileSize = 600

export const Wrapper = styled.div(({theme}) => ({
  background: theme.palette.grey[700],
  width: '100%',
  paddingBottom: 'env(safe-area-inset-bottom)',
  position: 'fixed',
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  zIndex: 0,
}))

export const ItemsWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}))

export const FooterWrapper = styled.footer(() => ({
  zIndex: 1049,
  position: 'relative',
}))

export const Item = styled(NavigationItemFooter)(() => ({
  minHeight: 40,
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
}))
