import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import {Link, useRouteMatch} from 'react-router-dom'
import {SelectedAbsolute, Wrapper, Count, TextSpan} from './IconButton.styles'

const IconButton = ({tooltip, to, children, Icon, count, text, ...rest}) => {
  const match = useRouteMatch(to)
  const isSelected = to && match
  return (
    <Tooltip title={tooltip == null ? '' : tooltip}>
      <Wrapper component={to ? Link : undefined} to={to} $isSelected={isSelected} {...rest}>
        <SelectedAbsolute $isSelected={isSelected} />
        {count ? <Count>{count}</Count> : undefined}
        {Icon ? <Icon /> : children}
        {text ? <TextSpan>{text}</TextSpan> : children}
      </Wrapper>
    </Tooltip>
  )
}

export default IconButton
