import resolvePath from 'object-resolve-path'
import {hasOwnPropertyCall} from 'utils/helpers'

export const resolve = (obj, str) => {
  try {
    return resolvePath(obj, str)
  } catch (err) {
    return str
  }
}

export const resolveColor = (obj, str, getMain = false) => {
  try {
    const res = resolvePath(obj, `palette.${str}`)
    if (getMain && hasOwnPropertyCall(res, 'main')) return res.main
    return res
  } catch (err) {
    return str
  }
}
