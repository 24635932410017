import React, {useContext} from 'react'
import * as PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import useOnlineStatus from 'hooks/api/useOnlineStatus'
import Tooltip from '@material-ui/core/Tooltip'
import {Wrapper} from './OnlineIndicator.styles'

const OnlineIndicator = ({online, lastSeen, ...rest}) => {
  const {lastSeenText} = useOnlineStatus({lastSeen})
  const {t} = useContext(I18nContext)
  return (
    <Tooltip title={online ? `${t('tooltips.active')}` : lastSeenText}>
      <Wrapper isOnline={online} {...rest} />
    </Tooltip>
  )
}
OnlineIndicator.propTypes = {
  online: PropTypes.bool,
  lastSeen: PropTypes.string,
  size: PropTypes.number,
}
OnlineIndicator.defaultProps = {
  size: 8,
}
export default OnlineIndicator
