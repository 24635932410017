import {useMutation} from 'react-query'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useApi from 'hooks/api/useApi'
import useBookmarks from './useBookmarks'

export default function useUnMakeBookmark(profileId) {
  const {profile} = usePersonalProfile()
  const api = useApi()
  const {refetch} = useBookmarks()

  return useMutation(
    () => api.makeUnBookmark({user_profile_id: profile.id, profile_to_follow_id: profileId}),
    {
      onSuccess: refetch,
    }
  )
}
