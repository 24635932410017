import {useCallback} from 'react'
import {set} from 'lodash'
import {useQuery, useQueryCache} from 'react-query'
import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'
import useAuthToken from 'hooks/auth/useAuthToken'

export default function useUser(params) {
  const api = useApi()
  const queryCache = useQueryCache()
  const [token] = useAuthToken()

  const {data: userInfo, ...useQueryMethods} = useQuery(
    cacheKeys.user,
    () => {
      if (token) {
        return api.getUser()
      }
      return undefined
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!token,
      ...params,
    }
  )

  const updateUser = useCallback(
    (data, updatePath = null) => {
      queryCache.setQueryData(cacheKeys.user, oldUser =>
        updatePath ? set(oldUser, updatePath, data) : data
      )
    },
    [queryCache]
  )

  return {
    userInfo,
    updateUser,
    ...useQueryMethods,
  }
}
