import {shape, string, bool} from 'prop-types'

export const messageType = shape({
  id: string.isRequired,
  user_id: string.isRequired,
  conversation_id: string.isRequired,
  message_type: string.isRequired,
  content: string,
  is_read: bool,
  edited: bool,
  created_at: string.isRequired,
  updated_at: string.isRequired,
})

export const conversationType = shape({
  id: string.isRequired,
  user_id: string.isRequired,
  second_user_id: string.isRequired,
  status: string.isRequired,
  created_at: string.isRequired,
  updated_at: string.isRequired,
})
