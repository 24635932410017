import React, {useContext} from 'react'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'
import ShowBrowserNotification from './ShowBrowserNotification'

const BrowserNotifications = () => {
  const {state} = useContext(NotificationContext)
  return <>{state?.notifications && <ShowBrowserNotification data={state?.notifications} />}</>
}

export default BrowserNotifications
