import React, {useContext, useMemo} from 'react'
import {ReactComponent as FaqSvg} from 'images/icons/faq.svg'
import {ReactComponent as SettingsSvg} from 'images/icons/menu/settings.svg'
import LanguagesDropdown from 'components/LanguagesDropdown'
import {getClubNavItems} from 'data/navigation'
import I18nContext from 'contexts/I18nContext'
import routes from 'routes'
import HeaderWrapper from 'components/headers/common/HeaderWrapper'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import HeaderTopBar from 'components/headers/common/HeaderTopBar'
import Search from 'components/headers/common/Search'
import ButtonFaq from 'components/headers/common/ButtonFaq'
import ButtonSettings from 'components/headers/common/ButtonSettings'
import ButtonNotifications from 'components/headers/common/ButtonNotifications'

const HeaderClub = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const sidebarItems = useMemo(
    () => [
      ...getClubNavItems(t),
      {
        to: routes.faq.path,
        text: t('navigation.faq'),
        iconSvg: FaqSvg,
      },
      {
        to: routes.settings.path,
        text: t('navigation.settings'),
        iconSvg: SettingsSvg,
      },
    ],
    [t]
  )
  return (
    <HeaderWrapper collapseWidth='collapseTopNavigation' {...rest}>
      {({opened, setOpened, isCollapsed}) => (
        <>
          <HeaderTopBar
            opened={opened}
            setOpened={setOpened}
            isCollapsed={isCollapsed}
            centerNode={
              <>
                {!isCollapsed && <ButtonFaq />}
                {!isCollapsed && <ButtonSettings />}
                <Search />
                <ButtonNotifications />
              </>
            }
            rightNode={!isCollapsed && <LanguagesDropdown />}
          />
          <HeaderSideBar opened={opened} setOpened={setOpened} items={sidebarItems} />
        </>
      )}
    </HeaderWrapper>
  )
}

export default HeaderClub
