import React, {useContext} from 'react'
import * as PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import {StyledButton} from './ButtonPremium.styles'

const ButtonPremium = ({hideIfPremium, ...rest}) => {
  const {t} = useContext(I18nContext)
  const {profile, isLoading} = usePersonalProfile()
  if (hideIfPremium && (profile?.premium_account || isLoading)) return null
  return (
    <StyledButton
      text={t('header.premiumButton')}
      color='secondary'
      size='lg'
      to={translatedUrl(routes.getPremium.path)}
      minWidth={160}
      {...rest}
    />
  )
}

ButtonPremium.propTypes = {
  hideIfPremium: PropTypes.bool,
  withComingSoon: PropTypes.bool,
}

ButtonPremium.defaultProps = {
  hideIfPremium: false,
  withComingSoon: true,
}

export default ButtonPremium
