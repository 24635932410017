import styled from 'styled-components'
import Button from 'components/Button'

export const StyledButton = styled(Button)(({theme}) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: theme.fontSizes.small,
  background: `linear-gradient(273.98deg, #8ACACB -12.84%, #2A9985 69.95%)`,
  '&:hover': {
    background: `linear-gradient(273.98deg, #8ACACB -12.84%, #2A9985 69.95%)`,
    boxShadow: `0 0 0 1px #8ACACB`,
  },
}))
