import React, {createContext, useState} from 'react'
import ActionCable from 'actioncable'
import useAuthToken from '../../hooks/auth/useAuthToken'

const initialState = {cable: null}

export const SocketContext = createContext(initialState)

const SocketProvider = ({children}) => {
  const [state, setstate] = useState(initialState)

  const webSocketUrl = new URL(process.env.REACT_APP_API_BASE_URL)

  const [token] = useAuthToken()
  if (state.cable === null && !!token) {
    setstate({
      cable: ActionCable.createConsumer(`wss://${webSocketUrl?.hostname}/cable?token=${token}`),
    })
  }

  return (
    <>
      <SocketContext.Provider value={{state}}>{children}</SocketContext.Provider>
    </>
  )
}
export default SocketProvider
