import React, {useContext} from 'react'
import NavigationTop from 'components/navigations/common/NavigationTop'
import I18nContext from 'contexts/I18nContext'
import {getClubNavItems} from 'data/navigation'

const NavigationTopClub = ({children, ...rest}) => {
  const {t} = useContext(I18nContext)
  return <NavigationTop items={getClubNavItems(t)} {...rest} />
}

export default NavigationTopClub
