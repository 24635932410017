import {ReactComponent as RequestIcon} from 'images/icons/notifications/request.svg'
import {ReactComponent as BookmarkIcon} from 'images/icons/notifications/bookmark.svg'
import {ReactComponent as LikeIcon} from 'images/icons/notifications/like.svg'
import {ReactComponent as CommentIcon} from 'images/icons/notifications/comment.svg'
import {ReactComponent as MessageIcon} from 'images/icons/notifications/message.svg'
import {ReactComponent as SubscriberIcon} from 'images/icons/notifications/subscriber.svg'
import {ReactComponent as MarkingIcon} from 'images/icons/notifications/marking.svg'

export const getFilters = t => [
  {
    id: 'all',
    label: t('notifications.filters.all'),
    value: undefined,
  },
  {
    id: 'unread',
    label: t('notifications.filters.unread'),
    value: 0,
  },
  {
    id: 'read',
    label: t('notifications.filters.read'),
    value: 1,
  },
]

export const typesMap = {
  bookmark: 'bookmark',
  post_like: 'post_like',
  post_comment: 'post_comment',
  request: 'request',
  new_message: 'new_message',
  new_subscriber: 'new_subscriber',
  poacher_post: 'poacher_post',
  inactive_since: 'inactive_since',
  vacancy: 'vacancy',
  mentioned_user_comment: 'mentioned_user_comment',
  mentioned_user_post: 'mentioned_user_post',
}

export const types = {
  [typesMap.bookmark]: {
    Icon: BookmarkIcon,
    getProfile: () => null,
  },
  [typesMap.post_like]: {
    Icon: LikeIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.post_comment]: {
    Icon: CommentIcon,
    getProfile: payload => payload?.comment?.user_profile,
  },
  [typesMap.request]: {
    Icon: RequestIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.new_message]: {
    Icon: MessageIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.new_subscriber]: {
    Icon: SubscriberIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.poacher_post]: {
    Icon: SubscriberIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.vacancy]: {
    Icon: SubscriberIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.inactive_since]: {
    Icon: SubscriberIcon,
    getProfile: payload => payload?.user_profile,
  },
  [typesMap.mentioned_user_comment]: {
    Icon: CommentIcon,
    getProfile: payload => payload?.comment?.user_profile,
  },
  [typesMap.mentioned_user_post]: {
    Icon: MarkingIcon,
    getProfile: payload => payload?.post?.user_profile,
  },
}

export const limit = 10
