import React from 'react'
import * as PropTypes from 'prop-types'
import {translatedUrl} from 'utils/i18n'
import FeedbackView from 'views/FeedbackView'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Wrapper, Item, ItemsWrapper} from './NavigationTop.styles'

const NavigationTop = ({items, ...rest}) => {
  if (useMediaQuery(theme => theme.breakpoints.down('collapseTopNavigation'))) return null

  return (
    <Wrapper {...rest}>
      <ItemsWrapper>
        {items.map(({to, iconSvg, text, unreadMessagesIcon}) => (
          <Item
            id={`top-nav-${to}`}
            key={to}
            to={translatedUrl(to)}
            text={text}
            iconSvg={iconSvg}
            top={true}
            unreadMessagesIcon={unreadMessagesIcon}
          />
        ))}
        <FeedbackView />
      </ItemsWrapper>
    </Wrapper>
  )
}

NavigationTop.propTypes = {
  items: PropTypes.array,
}

export default NavigationTop
