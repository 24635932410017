import React from 'react'
import PropTypes from 'prop-types'
import {Loader, LoaderSvg, LoaderSvgCircle} from './LoadingCircle.styles'

const LoadingCircle = ({color, thickness, ...rest}) => (
  <Loader {...rest}>
    <LoaderSvg viewBox='25 25 50 50'>
      <LoaderSvgCircle cx='50' cy='50' r='20' color={color} strokeWidth={thickness} />
    </LoaderSvg>
  </Loader>
)

LoadingCircle.propTypes = {
  dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  isAbsolute: PropTypes.bool,
}

LoadingCircle.defaultProps = {
  dia: 40,
  thickness: 4,
  color: 'primary',
  isAbsolute: false,
}

export default LoadingCircle
