import React, {useMemo} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PlayerSuggestions from 'components/PlayerSuggestions'
import {matchPath, useLocation} from 'react-router-dom'
import {localizeRoutePath} from 'utils/i18n'
import routes from 'routes'
import Advertisements from 'components/Advertisements'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import LoadingCircle from 'components/LoadingCircle'
import {routesWithRightCol, stickyOffset} from './MainLayout.config'
import {RightCol} from './MainLayout.styles'

/* Right column with ads or suggestions */
const MainLayoutRightCol = () => {
  const {pathname} = useLocation()
  const {profile, isLoading, profileRole} = usePersonalProfile()
  const showRightColMedia = useMediaQuery(theme => theme.breakpoints.up('collapseRightRow'))

  // Match routes where the right column should be displayed (with advertisements or other info)
  const showRightCol = useMemo(
    () =>
      showRightColMedia &&
      matchPath(pathname, localizeRoutePath(routesWithRightCol)) &&
      !matchPath(pathname, localizeRoutePath(routes.edit.path)),
    [showRightColMedia, pathname]
  )

  const adsOnProd = process.env.REACT_APP_SHOW_ADS === 'true'

  if (!showRightCol) return null
  return (
    <RightCol offsetBottom={stickyOffset} offsetTop={stickyOffset + 80}>
      {isLoading ? (
        <LoadingCircle />
      ) : profileRole === 'player' && profile?.premium_account ? (
        <PlayerSuggestions />
      ) : (
        adsOnProd && <Advertisements />
      )}
    </RightCol>
  )
}

export default MainLayoutRightCol
