import React, {useContext} from 'react'
import * as PropTypes from 'prop-types'
import {useQuery} from 'react-query'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useApi from 'hooks/api/useApi'
import {Tooltip} from '@material-ui/core'
import {Wrapper, Text, Count, UnreadMessages} from './NavigationItemFooter.styles'

const NavigationItemFooter = ({
  text,
  iconSvg: IconSvg,
  iconSrc,
  count,
  unreadMessagesIcon,
  ...rest
}) => {
  const api = useApi()
  const {profile} = usePersonalProfile()

  const {data} = useQuery(
    `unreadCountReq ${profile?.id}`,
    async () => profile && api.get(`/user_profiles/${profile?.id}/new_requests_or_messages`),
    {refetchInterval: 6000}
  )

  const unReadMessagesOrRequests = data?.new_messages + data?.new_requests > 0

  return (
    <Wrapper {...rest}>
      {count ? <Count>{count}</Count> : undefined}
      {IconSvg && <IconSvg />}
      {iconSrc && <img src={iconSrc} alt='' />}
      <Text>{text}</Text>
      {unReadMessagesOrRequests && (
        <Tooltip title='You have some unread messages'>
          <UnreadMessages>{unreadMessagesIcon}</UnreadMessages>
        </Tooltip>
      )}
    </Wrapper>
  )
}

NavigationItemFooter.propTypes = {
  text: PropTypes.string.isRequired,
  iconSvg: PropTypes.object,
  iconSrc: PropTypes.string,
  premiumIcon: PropTypes.object,
  unreadMessagesIcon: PropTypes.object,
  count: PropTypes.number,
}
NavigationItemFooter.defaultProps = {
  iconSvg: null,
  iconSrc: null,
  premiumIcon: null,
  unreadMessagesIcon: null,
}

export default NavigationItemFooter
