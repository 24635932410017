import React from 'react'
import * as PropTypes from 'prop-types'
import {Route} from 'react-router-dom'
import LoadingCircle from 'components/LoadingCircle'
import useUser from 'hooks/api/useUser'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import routes from '../config'

/**
 * Default route for pages, you can pass array of roles
 */
const DefaultRoute = ({onlyFor, ...rest}) => {
  const {isLoading: isLoadingUser} = useUser()
  const {isLoading: isLoadingProfile, profileRole} = usePersonalProfile()
  if (onlyFor) {
    if (isLoadingUser || isLoadingProfile) return <LoadingCircle />
    if (onlyFor.indexOf(profileRole) < 0) return <Route component={routes.notFound.component} />
  }
  return <Route {...rest} />
}

DefaultRoute.propTypes = {
  onlyFor: PropTypes.arrayOf(PropTypes.string),
}

DefaultRoute.defaultProps = {
  onlyFor: null,
}

export default DefaultRoute
