import {fontSizes} from './typography'

export const props = {
  MuiTooltip: {
    arrow: true,
    placement: 'top',
    enterDelay: 150,
    leaveDelay: 100,
  },
  MuiPagination: {
    shape: 'rounded',
  },
}

export const overrides = {
  MuiAutocomplete: {
    hasPopupIcon: {
      '&$hasClearIcon $inputRoot': {
        paddingRight: '0 !important',
      },
    },
    option: {
      lineHeight: 1.25,
      fontSize: fontSizes.small,
      minHeight: 36,
    },
    clearIndicator: {
      padding: 2,
    },
    popupIndicator: {
      padding: 2,
    },
    endAdornment: {
      position: 'relative',
      top: 0,
      right: 0,
      paddingRight: '0.25em',
    },
  },
  MuiMenuItem: {
    root: {
      lineHeight: 1.25,
      fontSize: fontSizes.small,
      minHeight: 36,
    },
  },
  MuiSvgIcon: {
    root: {
      fontSize: fontSizes.large,
    },
    fontSizeSmall: {
      fontSize: fontSizes.default,
    },
  },
  MuiIconButton: {
    root: {
      fontSize: 'inherit',
    },
  },
  MuiSelect: {
    icon: {
      fill: 'currentColor',
      color: 'inherit',
      transitionDuration: '100ms',
      width: '1.5em',
      height: '1.5em',
      top: 'calc(50% - 0.75em)',
      right: '0.5em',
    },
  },
  MuiPickersDay: {
    dayDisabled: {
      opacity: 0.4,
    },
  },
  MuiPickersYear: {
    yearDisabled: {
      opacity: 0.4,
    },
  },
  MuiTooltip: {
    tooltipPlacementTop: {
      marginBottom: 10,
    },
  },
  MuiPagination: {
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  MuiTablePagination: {
    selectIcon: {
      width: '1em',
      height: '1em',
      right: 4,
      top: 'auto',
    },
  },
}
