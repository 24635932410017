import React, {useCallback, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import * as PropTypes from 'prop-types'
import {languages, defaultLanguage} from 'i18n/config'
import {getLangFromUrl, translatedUrlWithLang} from 'utils/i18n'
import {useTranslation} from 'react-i18next'
import moment from 'moment'
import 'moment/min/locales'

const I18nContext = React.createContext({})

const I18nContextProvider = ({children}) => {
  const {i18n, t} = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const changeLanguage = useCallback(
    lang => {
      i18n.changeLanguage(lang).then(() => {
        history.replace({
          ...location,
          pathname: translatedUrlWithLang({lang, pathname: location.pathname}),
        })
      })
    },
    [history, i18n, location]
  )

  useEffect(() => {
    if (i18n.language && i18n.language !== getLangFromUrl(location.pathname)) {
      changeLanguage(i18n.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, location.pathname])

  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  return (
    <I18nContext.Provider
      value={{
        t,
        i18n,
        language: i18n.language,
        languages,
        defaultLanguage,
        changeLanguage,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

I18nContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {I18nContextProvider}
export default I18nContext
