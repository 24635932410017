import autoBind from 'auto-bind'
import axios from 'axios'
import ApiError from './ApiError'

export default class ApiBase {
  constructor({baseUrl, commonHeaders, onUnauthorized, timeout = 100000}) {
    this.axiosClient = axios.create({
      baseURL: baseUrl,
      timeout,
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        ...commonHeaders,
      },
      responseType: 'json',
    })

    this.axiosClient.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          const {status, statusText} = error.response
          const errData = error.response.data
          let errMsg = `${statusText} (${status})`
          if (errData && errData.message) errMsg = errData.message
          else if (errData && errData.error) errMsg = errData.error
          else if (errData && errData.errors) errMsg = errData.errors

          const errObj = new ApiError(errMsg, status)
          if (
            status === 401 ||
            status === 403
            // errObj.toString() === 'JWT token is invalid' ||
            // errObj.toString() === 'Invalid segment encoding' ||
            // errObj.toString() === 'Signature has expired' ||
            // errObj.toString() === 'Signature verification raised' ||
            // errObj.toString().startsWith('This account is banned due to')
          ) {
            onUnauthorized && onUnauthorized(errObj)
          }
          throw errObj
        } else if (error.message) {
          throw new ApiError(error.message)
        } else {
          throw new ApiError('Unhandled error happened...')
        }
      }
    )

    autoBind(this)
  }

  async request(options, fullResponse) {
    const response = await this.axiosClient.request(options)
    if (fullResponse) return response
    return response.data
  }

  async get(url, params = null, fullResponse = false, other) {
    return this.request({url, method: 'get', params, ...other}, fullResponse)
  }

  async post(url, data = null, fullResponse = false, other) {
    return this.request({url, method: 'post', data, ...other}, fullResponse)
  }

  async put(url, data = null, fullResponse = false, other) {
    return this.request({url, method: 'put', data, ...other}, fullResponse)
  }

  async patch(url, data = null, fullResponse = false, other) {
    return this.request({url, method: 'patch', data, ...other}, fullResponse)
  }

  async delete(url, params = null, fullResponse = false, other) {
    return this.request({url, method: 'delete', params, ...other}, fullResponse)
  }

  async createOrUpdate(url, data, id = null) {
    return this.request({
      url: url + (id ? `/${id}` : ''),
      method: id ? 'put' : 'post',
      data,
    })
  }
}
