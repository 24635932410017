import React from 'react'
import styled from 'styled-components'
import ButtonBase from '@material-ui/core/ButtonBase'

export const btnSizes = {
  sm: {
    height: 34,
    fontSize: 'small',
    borderRadius: 'default',
  },
  def: {
    height: 38,
    fontSize: 'small',
    borderRadius: 'default',
  },
  md: {
    height: 44,
    fontSize: 'small',
    borderRadius: 'default',
  },
  lg: {
    height: 50,
    fontSize: 'default',
    borderRadius: 'default',
  },
}

export const IconStartWrapper = styled.div({
  marginRight: '0.5em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const IconEndWrapper = styled.div({
  marginLeft: '0.5em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ContentLoadingWrapper = styled.div`
  opacity: 0;
`

export const ButtonStyled = styled(
  ({
    mainColor,
    mainColorDark,
    textColor,
    w100,
    size,
    outlined,
    noBorder,
    minWidth,
    isLoading,
    ref,
    ...props
  }) => <ButtonBase ref={ref} {...props} />
)(
  ({
    theme,
    size,
    mainColor,
    mainColorDark,
    textColor,
    outlined,
    noBorder,
    minWidth,
    w100,
    disabled,
  }) => ({
    appearance: 'none',
    position: 'relative',
    padding: '0 10px',
    fontWeight: 500,
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    transitionDuration: `${theme.transitions.duration.short}ms`,
    fontFamily: theme.typography.fontFamily,

    height: btnSizes[size].height,
    fontSize: theme.resolve(`fontSizes.${btnSizes[size].fontSize}`),
    borderRadius: theme.resolve(`radii.${btnSizes[size].borderRadius}`),

    background: outlined ? 'transparent' : mainColor,
    color: textColor,
    fill: textColor,
    stroke: textColor,
    border: noBorder ? null : outlined ? `1px solid ${mainColor}` : null,

    minWidth,
    width: w100 ? '100%' : null,
    maxWidth: w100 ? null : 'max-content',

    '&:hover': {
      background: outlined ? 'transparent' : mainColorDark,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    },

    ...(disabled && {
      opacity: 0.6,
      cursor: 'not-allowed',
      transform: 'none',
      filter: 'none',
      IconStartWrapper: {
        opacity: 0.7,
      },
      IconEndWrapper: {
        opacity: 0.7,
      },
    }),
  })
)
