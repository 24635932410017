import styled from 'styled-components'
import PlayerSuggestionCardComp from 'components/PlayerSuggestionCard'

export const Wrapper = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  background: theme.palette.grey[700],
  borderRadius: theme.radii.large,
  padding: '20px 15px',
}))

export const PlayerSuggestionsTitle = styled.div(({theme}) => ({
  ...theme.typography.h3,
  textAlign: 'center',
}))

export const PlayerSuggestionsSubtitle = styled.div(({theme}) => ({
  fontSize: theme.fontSizes.small,
  color: theme.palette.grey[400],
  marginTop: 4,
  textAlign: 'center',
}))

export const PlayerSuggestionCard = styled(PlayerSuggestionCardComp)(() => ({
  marginTop: '15px',
  width: '100%',
}))
