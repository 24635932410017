import autoBind from 'auto-bind'
import ApiBase from './ApiBase'

export default class ApiMethods extends ApiBase {
  constructor(props) {
    super(props)
    autoBind(this)
  }

  /**
   * AUTH
   */

  async login(user) {
    const {data, headers} = await this.post(
      '/auth/login',
      {
        user,
      },
      true
    )
    return {
      user: data,
      token: headers.authorization,
    }
  }

  async logout() {
    return this.delete('/auth/logout')
  }

  async signUp({...user}, inviter_id) {
    const {data, headers} = await this.post(
      '/auth/signup',
      {
        user,
        inviter_id,
      },
      true
    )
    return {
      user: data.user,
      token: headers.authorization,
    }
  }

  async emailConfirm(confirmation_token) {
    const {data, headers} = await this.get(
      '/auth/confirmation',
      {
        confirmation_token,
      },
      true,
      {}
    )
    return {
      user: data.user,
      token: headers.authorization,
    }
  }

  async resendEmailConfirm({email}) {
    return this.post('/auth/confirmation', {
      user: {
        email,
      },
    })
  }

  async passwordResetRequest({email}) {
    return this.post('/auth/password', {
      user: {
        email,
      },
    })
  }

  async passwordResetConfirm({reset_password_token, password, password_confirmation}) {
    return this.patch('/auth/password', {
      user: {
        reset_password_token,
        password,
        password_confirmation,
      },
    })
  }

  async changePassword({current_password, password, password_confirmation}) {
    return this.put('/auth/signup', {
      user: {
        current_password,
        password,
        password_confirmation,
      },
    })
  }

  async deleteUser(id) {
    return this.delete(`/users/${id}`)
  }

  /**
   * PROFILES
   */

  /**
   * @param {string} id - to get self user profile, pass 'profile' as id
   * @returns {Promise}
   */
  async getProfile(id) {
    const {user_profile} = await this.get(`/user_profiles/${id}`)
    return user_profile
  }

  async getUser(id) {
    const {user} = await this.get(!id ? `/users` : `/users/${id}`)
    return user
  }

  async getSkills() {
    const {skills} = await this.get(`/skills`)
    return skills
  }

  async getPositions() {
    const {positions} = await this.get(`/positions`)
    return positions
  }

  async updateSkills(characteristicsId, skillsIdArray) {
    return this.post(`/player/characteristics/${characteristicsId}/update_skills`, {
      skills: skillsIdArray,
    })
  }

  async getPlayerProfile({id}) {
    return this.get(`/player/profiles/${id}`).then(res => res.player_profile)
  }

  async getPressProfile({id}) {
    return this.get(`/press/profiles/${id}`).then(res => res.press_profile)
  }

  async getClubProfile({id}) {
    return this.get(`/club/profiles/${id}`).then(res => res.club_profile)
  }

  async getTeamProfile({id}) {
    return this.get(`/team/profiles/${id}`).then(res => res.team_profile)
  }

  async getPlayerSuggestions({limit = 6}) {
    return this.get('/player/profiles', {limit}).then(res =>
      Promise.all(res.player_profiles.map(({user_profile_id}) => this.getProfile(user_profile_id)))
    )
  }

  async randomUsers() {
    return this.get('/random_users')
  }

  /**
   * FILE UPLOADING
   *
   *
   * @typedef {Object} FileUploadResult
   * @property {string} url
   * @property {string} compressed_url
   *
   * @param {FormData} formData - new FormData() object with 'file' prop (for file data) and 'folder' to upload data to exact folder on S3
   * @returns {Promise<FileUploadResult>}
   *
   * @example
   * const file = inputRef.current.files[0]
   * const data = new FormData()
   * data.append('file', file)
   * data.append('folder', 'post_attachments')
   * const fileUrl = await api.uploadFile(data)
   */
  async uploadFile(formData) {
    const {url, compressed_url} = await this.post(`/files/upload`, formData, false, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      timeout: 200000,
    })
    return {url, compressed_url}
  }

  // TODO: update docs
  /**
   * POSTS
   *
   * @typedef {Object} MediaFile
   * @property {string} attachment_type - can be "image" or "video" or any other
   * @property {string} attachment_url - URL of the attachment (use uploadFile method)
   *
   * @typedef {Object} Post
   * @property {string} text - post content
   * @property {MediaFile[]} media_files - array of attachments
   */

  /**
   * @param {Post} postData
   * @returns {Promise<Post>}
   *
   * @example
   * const post = await api.createPost({
   *   text: "Post example 1",
   *   media_files_attributes: [
   *     {
   *       attachment_type: "image",
   *       attachment_url: "https://e0.365dm.com/20/10/768x432/skysports-pitch-to-post-liverpool_5119402.jpg?20201005134041",
   *     }
   *   ]
   * })
   */
  async createPost(postData, mentionedUsers) {
    const {post} = await this.post(`/posts`, {
      post: postData,
      mentioned_users: mentionedUsers,
    })
    return post
  }

  /**
   * @param {string} id - post id
   * @returns {Promise<Post>}
   */
  async getPost(id) {
    const {post} = await this.get(`/posts/${id}`)
    return post
  }

  /**
   * @param {string} id - post id to delete
   * @returns {Promise<Post>}
   */
  async deletePost(id) {
    const {post} = await this.delete(`/posts/${id}`)
    return post
  }

  /**
   * @param {string} id - post id to report
   * @returns {Promise<Post>}
   */
  async reportPost(id, referenceType, reportReason, reportcomment) {
    const {post} = await this.post(`/reports`, {
      reference_id: id,
      reference_type: referenceType,
      report_reason: reportReason,
      report_comment: reportcomment,
    })
    return post
  }

  /**
   * @param {string} id - post id to block
   * @returns {Promise<Post>}
   */

  async unblockUser(id, user_id_to_block) {
    const {post} = await this.post(`/users/${id}/unblock`, {
      user_id: user_id_to_block,
    })
    return post
  }

  /**
   * @param {string} id - post id to update
   * @param {Post} data - post data
   * @returns {Promise<Post>}
   */
  async updatePost(id, {...data}) {
    const {post} = await this.put(`/posts/${id}`, {post: data})
    return post
  }

  /**
   * Get posts linked to user
   * @param {string} user_profile_id - user profile id
   * @param params
   * @returns {Promise<{data: Post[], count: number}>}
   */
  async getUserPosts({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/posts`, params)
  }

  /**
   * Get posts from profiles followed by user
   * @param {string} user_profile_id - user profile id
   * @param params
   * @returns {Promise<{data: Post[], count: number}>}
   */
  async getUserFeedPosts({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/feed`, params)
  }

  /**
   * Create post comment
   * @param {string} postId
   * @param {Object} commentData
   */
  async createComment(postId, commentData) {
    const {comment} = await this.post(`/posts/${postId}/comment`, {
      comment: commentData,
      mentioned_users: commentData.mentionedUsers,
    })
    return comment
  }

  async getPostComments(postId) {
    return this.get(`/posts/${postId}/comments`)
  }

  async deleteComment(id) {
    const {comment} = await this.delete(`/comments/${id}`)
    return comment
  }

  /**
   * LIKES
   */

  /**
   * Create like for reference
   * @param reference_id
   * @param reference_type Post Comment MediaFile
   * @returns {Promise<void>}
   */
  async createLike(reference_id, reference_type) {
    const {like} = await this.post(`/likes`, {reference_id, reference_type})

    return like
  }

  async deleteLike(reference_id) {
    const {like} = await this.delete(`/likes/${reference_id}`)

    return like
  }

  async checkIfProfileLikesObject(profileId, referenceId, referenceType) {
    try {
      const {like} = await this.get(
        `user_profiles/${profileId}/liked?reference_id=${referenceId}&reference_type=${referenceType}`
      )

      return like
    } catch (err) {
      return null
    }
  }

  /**
   * FOLLOWS
   */

  /**
   * @param {string} user_profile_id - user profile id
   * @param params
   * @returns {Promise<{data: Object[], count: number}>}
   */
  async getFollowings({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/followings`, params)
  }

  async getGivenFollows({user_profile_id}) {
    return this.get(`/user_profiles/${user_profile_id}/given_follows`)
  }

  /**
   * @param {string} user_profile_id - user profile id
   * @param params
   * @returns {Promise<{data: Object[], count: number}>}
   */
  async getFollowers({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/followers`, params)
  }

  async getReceivedFollows({user_profile_id}) {
    return this.get(`/user_profiles/${user_profile_id}/received_follows`)
  }

  async makeFollow({user_profile_id, profile_to_follow_id}) {
    return this.post(`/user_profiles/${user_profile_id}/follow`, {
      profile_to_follow_id,
    })
  }

  async makeUnFollow({user_profile_id, profile_to_follow_id}) {
    return this.post(`/user_profiles/${user_profile_id}/unfollow`, {
      profile_to_follow_id,
    })
  }

  /**
   * BOOKMARKS
   */
  async getBookmarks({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/bookmarks`, params)
  }

  async makeBookmark({user_profile_id, profile_to_follow_id}) {
    return this.post(`/user_profiles/${user_profile_id}/bookmark`, {
      profile_to_follow_id,
    })
  }

  async makeUnBookmark({user_profile_id, profile_to_follow_id}) {
    return this.post(`/user_profiles/${user_profile_id}/unbookmark`, {
      profile_to_follow_id,
    })
  }

  /**
   * TEAM INVITATION
   */
  async sendTeamInvitation({club_profile_id, inv_email, inv_name, inv_role}) {
    return this.post(`/club/profiles/${club_profile_id}/invite_team`, {
      inv_email,
      inv_name,
      inv_role,
    })
  }

  async getInvitedTeams({club_profile_id}) {
    return this.get(`/club/profiles/${club_profile_id}/invited_teams`)
  }

  async sendInviteTeam({email}) {
    return this.post('/team/profiles/invite_team', {email})
  }

  /**
   * FRIEND INVITATION
   */
  async sendFriendInvitation({email, user_id}) {
    return this.post('/invites', {email, user_id})
  }

  async getInvitedFriends() {
    const {invites} = await this.get('/invites')
    return invites
  }

  async claimGift(id) {
    return this.post(`/player/profiles/${id}/claim_gift`)
  }

  async inviteViaSMS({phone_number}) {
    return this.post('/invites/invite_by_sms', {phone_number})
  }

  /**
   * MESSAGES
   */

  /**
   * {
    "conversation": {
        "id": "546c0475-b0d3-4b70-8dbf-88f44f917c18",
        "user_id": "9b081943-e415-4db8-9697-c1d8c491e321",
        "second_user_id": "f3db1410-4e72-42c8-896f-ec1937dc19b4",
        "status": "approved",
        "created_at": "2020-11-29T20:43:14.835Z",
        "updated_at": "2020-11-29T20:43:14.835Z"
    }
}
   * @param second_user_id
   * @returns {Promise<*>}
   */
  async createConversation(second_user_id) {
    const {conversation} = await this.post(`/conversations`, {
      second_user_id,
    })
    return conversation
  }

  async deleteConversation(conversation_id) {
    const {conversation} = await this.delete(`/conversations/${conversation_id}`)
    return conversation
  }

  async approveConversation(conversation_id) {
    const {conversation} = await this.post(`/conversations/${conversation_id}/approve`)
    return conversation
  }

  async blockUser(myUserId, toBlockUserId) {
    const {blocked_user} = await this.post(`/users/${myUserId}/block`, {
      user_id: toBlockUserId,
    })
    return blocked_user
  }

  async createMessage({conversation_id, content, message_type = 'text'}) {
    return this.post(`/messages`, {message: {conversation_id, content, message_type}})
  }

  /**
   * Endpoint return all conversation messages with applied filters if applicable
   * @param conversation_id {String}
   * @param filters {Object}
   * @param filters.message_type {'text' | 'image' | 'file'}
   * @param filters.is_read {Boolean}
   * @param filters.content {String}
   * @returns {Promise<void>}
   */
  async getConversationMessages(conversation_id, {message_type, is_read, content} = {}) {
    const {messages} = await this.get('/messages', {
      conversation_id,
      message_type,
      is_read,
      content,
    })
    return messages
  }

  async makeMessageRead(message_id) {
    const {message} = await this.post(`/messages/${message_id}/make_read`)
    return message
  }

  /**
   * NOTIFICATIONS
   */

  async getNotifications(params) {
    return this.get('/notifications', {
      ...params,
    })
  }

  async notificationRead(id) {
    const {notification} = await this.post(`/notifications/${id}/make_read`)
    return notification
  }

  async notificationCount() {
    return this.get('notifications/unread_notifications_count')
  }

  /**
   * VACANCIES
   */

  async getVacancy(vacancy_id) {
    const {vacancy} = await this.get(`/vacancies/${vacancy_id}`)
    return vacancy
  }

  async getVacancies({user_profile_id, ...params}) {
    return this.get(`/user_profiles/${user_profile_id}/vacancies`, params)
  }

  async getProducts() {
    return this.get(`/products`)
  }

  async createVacancy(vacancyDate) {
    const {vacancy} = await this.post(`/vacancies`, {
      vacancy: vacancyDate,
    })
    return vacancy
  }

  async createPromotionRequest(data) {
    const {vacancy} = await this.post(`/promotions`, {
      promotion: data,
    })
    return vacancy
  }

  async updateVacancy(vacancyDate, vacancy_id) {
    return this.put(`/vacancies/${vacancy_id}`, {
      vacancy: vacancyDate,
    }).then(res => res.vacancy)
  }

  async deleteVacancy(vacancy_id) {
    const {vacancy} = await this.delete(`/vacancies/${vacancy_id}`)
    return vacancy
  }

  /**
   * TEAMMATES
   */

  async getTeammates({team_id, ...params}) {
    return this.get(`/team/profiles/${team_id}/teammates`, params)
  }

  async createTeammate(teammateDate) {
    const {teammate} = await this.post(`/teammates`, {
      teammate: teammateDate,
    })
    return teammate
  }

  async updateTeammate(teammateDate, teammate_id) {
    return this.put(`/teammates/${teammate_id}`, {
      teammate: teammateDate,
    })
  }

  async deleteTeammate(teammate_id) {
    return this.delete(`/teammates/${teammate_id}`)
  }

  // Get Link Preview from user post
  async getLinkPreview(link) {
    return this.get(`/link_preview?link=${link}`)
  }

  // Send users feedback
  async sendFeedback({description}) {
    return this.post('/feedback', {description})
  }

  // Admin deletes users

  async adminDeleteUser(user_id) {
    return this.delete(`/delete_user/${user_id}`)
  }

  // Get likes
  async getLikes(id) {
    return this.get(`/posts/${id}/likes`)
  }

  // Get Billing Address
  async getBillingAddress(id) {
    return this.get(`/user_profiles/${id}/billing_address`)
  }

  // Cancel Subscription
  async cancelSub(id) {
    return this.post(`/subscriptions/${id}/cancel`)
  }

  // Onboarding Process
  async onboardingProcessCompleted(id) {
    return this.put(`user_profiles/${id}/completed_onboarding`)
  }

  async onboardingStatus(id, user_type) {
    return this.get(`${user_type}/profiles/${id}/onboarding_status`)
  }

  // Ads' impressions
  async adsImpressions(ad_ids) {
    return this.post('ad_tools/impress', {ad_ids})
  }

  // Register Device token
  async registerDeviceToken(params) {
    return this.post('devices', params)
  }

  // Unregister Device token
  async unRegisterDeviceToken(params) {
    return this.delete('devices/delete_by_device_token', params)
  }

  // Apple Pay
  async applePayPurchase(apple_product_id, p) {
    return this.post('ios_subscriptions', {apple_product_id, product: p})
  }

  // Poacher Sport Profile
  async getPoacherProfile(email) {
    return this.get(`user_profiles/by_email?email=${email}`)
  }

  // Refresh Token
  async refreshToken() {
    return this.get('auth/new_token', null, true)
  }

  // Change language for user account
  async changeLanguage(id, language) {
    return this.put(`users/${id}`, {language})
  }
}
