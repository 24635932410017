import styled from 'styled-components'
import React from 'react'
import {NavLink} from 'react-router-dom'
import color from 'color'
import ButtonBase from '@material-ui/core/ButtonBase'

export const Wrapper = styled(props => <ButtonBase {...props} component={NavLink} />)(
  ({theme}) => ({
    color: theme.palette.common.white,
    fill: theme.palette.grey[400],
    stroke: theme.palette.grey[400],
    fontWeight: 500,
    borderRadius: theme.radii.default,
    padding: '0.4em 1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    lineHeight: 1.15,
    textDecoration: 'none',
    fontFamily: theme.typography.fontFamilyTitle,

    '&:hover': {
      background: color(theme.palette.primary.main).fade(0.95),
    },
    '&.active': {
      fill: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
      background: color(theme.palette.primary.main).fade(0.9),
    },

    '& > svg, & > img': {
      width: 20,
      height: 20,
      flex: '0 0 20px',
      objectFit: 'contain',
      objectPosition: 'center',
    },
  })
)

export const Text = styled.span(({theme}) => ({
  fontSize: theme.fontSizes.extraSmall,
  paddingTop: '5px',
}))

export const Count = styled.div(({theme}) => ({
  borderRadius: '50rem',
  position: 'absolute',
  top: 4,
  right: 15,
  background: theme.palette.info.main,
  color: theme.palette.common.white,
  zIndex: 1,
  fontSize: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 14,
  minWidth: 14,
  padding: '0 2px',
}))

export const UnreadMessages = styled.span(({theme, top}) => ({
  position: 'absolute',
  top: -5,
  right: -5,
  color: theme.palette.info.main,
  stroke: theme.palette.info.main,
  '& circle': {
    r: 4,
  },
}))
