const fontSizes = {
  superSmall: '0.625rem', // 10px
  extraSmall: '0.75rem', // 12px
  small: '0.875rem', // 14px
  default: '1rem', // 16px
  medium: '1.125rem', // 18px
  large: '1.25rem', // 20px
  title: '1.5rem', // 24px
  titleLarge: '1.875rem', // 30px
}

const fontFamilyDefault = 'Roboto, sans-serif'
const fontFamilyTitle = 'Poppins, sans-serif'

const typography = {
  fontSize: 16,
  fontSizes,
  fontFamily: fontFamilyDefault,
  fontFamilyTitle,
  button: {
    fontSize: fontSizes.small,
    fontWeight: 500,
    textTransform: null,
    lineHeight: 1.4,
    minHeight: 38,
  },
  h1: {
    fontFamily: fontFamilyTitle,
    fontWeight: 600,
    fontSize: fontSizes.title,
  },
  h2: {
    fontFamily: fontFamilyTitle,
    fontWeight: 500,
    fontSize: fontSizes.large,
  },
  h3: {
    fontFamily: fontFamilyTitle,
    fontWeight: 500,
    fontSize: fontSizes.medium,
  },
  h4: {
    fontFamily: fontFamilyTitle,
    fontWeight: 500,
    fontSize: fontSizes.medium,
  },
  h5: {
    fontFamily: fontFamilyTitle,
    fontWeight: 500,
    fontSize: fontSizes.medium,
  },
  h6: {
    fontFamily: fontFamilyTitle,
    fontWeight: 500,
    fontSize: fontSizes.medium,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: fontSizes.default,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: fontSizes.small,
  },
  subtitle3: {
    fontWeight: 400,
    fontSize: fontSizes.extraSmall,
  },
  smallCaption: {
    fontWeight: 500,
    fontSize: fontSizes.superSmall,
  },
}

export {fontSizes}
export default typography
