import React, {useContext} from 'react'
import NavigationTop from 'components/navigations/common/NavigationTop'
import I18nContext from 'contexts/I18nContext'
import {getTeamNavItems} from 'data/navigation'

const NavigationTopTeam = ({children, ...rest}) => {
  const {t} = useContext(I18nContext)
  return <NavigationTop items={getTeamNavItems(t)} {...rest} />
}

export default NavigationTopTeam
