import {arrayOf, number, shape, string} from 'prop-types'
import {footType, positionType, skillType} from './other'

export const vacancyType = shape({
  id: string.isRequired,
  age_from: number,
  age_to: number,
  height: number,
  league_level: number,
  about: string,
  user_profile_id: string,
  created_at: string,
  foot: footType,
  position: positionType,
  skills: arrayOf(skillType),
})
