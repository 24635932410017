import _ from 'lodash'
import baseConfig from './base'
import developmentConfig from './development'
import stagingConfig from './staging'
import productionConfig from './production'

const envConfig =
  baseConfig.env === 'development'
    ? developmentConfig
    : baseConfig.env === 'staging'
    ? stagingConfig
    : productionConfig

const config = _.merge(envConfig, baseConfig)

export default config
