import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import routes from 'routes/config'
import LoadingCircle from 'components/LoadingCircle'
import useObligatoryPath from 'hooks/helpers/useObligatoryPath'
import useUser from 'hooks/api/useUser'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import DefaultRoute from './DefaultRoute'

/**
 * Will redirect to home page if user has active session
 */
const NonAuthOnlyRoute = ({...rest}) => {
  const {userInfo, isLoading} = useUser()
  const {isLoading: isLoadingProfile} = usePersonalProfile()
  const obligatoryPath = useObligatoryPath()

  const location = useLocation()
  const {from} = location.state || {from: {pathname: routes.home.path}}
  if (isLoading || isLoadingProfile) return <LoadingCircle />
  if (userInfo) {
    return <Redirect to={obligatoryPath || from} />
  }
  return <DefaultRoute {...rest} />
}

export default NonAuthOnlyRoute
