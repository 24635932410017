import React from 'react'
import {useSwipeable} from 'react-swipeable'
import {useHistory} from 'react-router-dom'
import {Capacitor} from '@capacitor/core'

const useSwipeHandler = () => {
  const history = useHistory()
  const chatPathRegex = /\/messages\/[A-Za-z0-9-]+/
  const mainPagesRegex = /\/feed$|\/messages$|\/profile$|\/notifications$|\/contact-light$|\/get-premium$/
  const currentPath = history.location.pathname
  const ios = Capacitor.getPlatform() === 'ios'

  const handleBack = () => {
    if (chatPathRegex.test(currentPath)) history.replace('/messages/')
    history.goBack()
  }

  const mainPagesCheck = () => {
    if (mainPagesRegex.test(currentPath)) return true
    return false
  }

  const swipeBack = useSwipeable({
    onSwipedRight: () => ios && !mainPagesCheck() && handleBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  })

  const swipeBackOnSliders = useSwipeable({
    onSwipedRight: () => ios && handleBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  })

  return {swipeBack, swipeBackOnSliders, handleBack}
}

export default useSwipeHandler
