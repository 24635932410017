import React, {useContext} from 'react'
import NavigationLeft from 'components/navigations/common/NavigationLeft'
import {getPlayerNavItems} from 'data/navigation'
import I18nContext from 'contexts/I18nContext'

const NavigationLeftPlayer = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return <NavigationLeft items={getPlayerNavItems(t)} {...rest} />
}

export default NavigationLeftPlayer
