import React, {useContext, useMemo} from 'react'
import styled from 'styled-components'
import I18nContext from 'contexts/I18nContext'
import Button from 'components/Button'
import {useMutation, useQuery} from 'react-query'
import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import {profileType} from 'types'

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  flexGrow: 1,
  maxWidth: 180,
  minWidth: 'min-content',
})

const ButtonSubscribe = ({
  profile,
  refetchSuggestions = () => {},
  fromPlayerSuggestions,
  ...props
}) => {
  const {t} = useContext(I18nContext)
  const api = useApi()
  const {profile: personalProfile} = usePersonalProfile()

  const poacherSportsOfficialEmail = process.env.REACT_APP_OFFICIAL_POACHER_SPORTS_EMAIL

  const {data: givenFollows, isLoading: isLoadingGivenFollows, refetch} = useQuery(
    cacheKeys.givenFollows,
    async () => {
      if (personalProfile) {
        const {follows} = await api.getGivenFollows({user_profile_id: personalProfile.id})
        return follows
      }
      return undefined
    }
  )

  const isSubscribed = useMemo(
    () => givenFollows?.find(({followed_user_id}) => followed_user_id === profile?.id),
    [givenFollows, profile?.id]
  )

  const [subscribe, subscribeMeta] = useMutation(
    () => api.makeFollow({user_profile_id: personalProfile.id, profile_to_follow_id: profile?.id}),
    {
      onSuccess: refetch,
    }
  )

  const [unsubscribe, unsubscribeMeta] = useMutation(
    () =>
      api.makeUnFollow({user_profile_id: personalProfile.id, profile_to_follow_id: profile?.id}),
    {
      onSuccess: refetch,
    }
  )

  const handleSubscribe = () => {
    subscribe()
    setTimeout(() => {
      fromPlayerSuggestions && refetchSuggestions(profile?.user_id)
    }, 1500)
  }

  const showSubscribeButton =
    !isSubscribed || (isSubscribed && profile.press_profile?.email !== poacherSportsOfficialEmail)

  return (
    <>
      {showSubscribeButton && (
        <StyledButton
          color={isSubscribed ? 'rgba(144, 144, 155, 0.2)' : 'primary'}
          textColor={isSubscribed ? 'grey[400]' : undefined}
          text={
            isSubscribed ? t('userProfileCard.btnUnsubscribe') : t('userProfileCard.btnSubscribe')
          }
          onClick={isSubscribed ? unsubscribe : handleSubscribe}
          isLoading={unsubscribeMeta.isLoading || subscribeMeta.isLoading || isLoadingGivenFollows}
          {...props}
        />
      )}
    </>
  )
}

ButtonSubscribe.propTypes = {
  profile: profileType,
}

export default ButtonSubscribe
