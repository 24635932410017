import React from 'react'
import PropTypes from 'prop-types'
import {CSSTransition} from 'react-transition-group'
import LanguagesDropdown from 'components/LanguagesDropdown'
import {translatedUrl} from 'utils/i18n'
import {useSwipeable} from 'react-swipeable'
import routes from 'routes'
import Avatar from 'components/Avatar'
import {avatarText} from 'utils/helpers'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import FeedbackView from 'views/FeedbackView'
// import Footer from 'components/Footer'
import {
  ItemsWrapper,
  Box,
  DarkBg,
  BottomItemsWrapper,
  CloseButton,
  TopRow,
  CloseIcon,
  Item,
  ProfileItemText,
  ChevronRight,
  ProfileItem,
} from './HeaderSideBar.styles'

const HeaderSideBar = ({opened, setOpened, items, bottomNode}) => {
  const {profileName, profile} = usePersonalProfile()
  const swipeBack = useSwipeable({
    onSwipedRight: () => setOpened(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  })
  return (
    <>
      <CSSTransition in={opened} classNames='slide' timeout={300} unmountOnExit>
        <Box {...swipeBack}>
          <TopRow>
            <LanguagesDropdown
              buttonProps={{
                size: 'def',
              }}
            />
            <CloseButton color='grey[600]' textColor='grey[400]' onClick={() => setOpened(false)}>
              <CloseIcon />
            </CloseButton>
          </TopRow>
          <ItemsWrapper>
            {profile && (
              <ProfileItem
                to={translatedUrl(routes.profile.personal.path)}
                onClick={() => setOpened(false)}
              >
                <Avatar
                  text={avatarText(profileName)}
                  src={profile?.profile_photo_url}
                  radius={17}
                  style={{marginRight: 10}}
                />
                <ProfileItemText>{profileName}</ProfileItemText>
                <ChevronRight />
              </ProfileItem>
            )}
            {items?.map(({to, iconSvg, text, premiumIcon}) => (
              <Item
                key={to}
                to={translatedUrl(to)}
                text={text}
                iconSvg={iconSvg}
                premiumIcon={premiumIcon}
                onClick={() => setOpened(false)}
              />
            ))}
            <FeedbackView />
          </ItemsWrapper>
          <BottomItemsWrapper>
            {bottomNode}
            {/* <Footer /> */}
          </BottomItemsWrapper>
        </Box>
      </CSSTransition>
      <CSSTransition in={opened} classNames='fade' timeout={300} unmountOnExit>
        <DarkBg onClick={() => setOpened(false)} />
      </CSSTransition>
    </>
  )
}

HeaderSideBar.propTypes = {
  opened: PropTypes.bool.isRequired,
  setOpened: PropTypes.func.isRequired,
  items: PropTypes.array,
  bottomNode: PropTypes.node,
}

HeaderSideBar.defaultProps = {}

export default HeaderSideBar
