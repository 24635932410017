import React, {useContext} from 'react'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import I18nContext from 'contexts/I18nContext'
import {ReactComponent as SettingsSvg} from 'images/icons/menu/settings.svg'
import IconButton from '../IconButton'

const ButtonSettings = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return (
    <IconButton
      tooltip={t('header.settings')}
      to={translatedUrl(routes.settings.path)}
      Icon={SettingsSvg}
      {...rest}
    />
  )
}

export default ButtonSettings
