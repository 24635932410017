import Button from 'components/Button'
import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({}))

export const Content = styled.p(({theme}) => ({
  padding: '10px',
}))

export const MainTitle = styled.p(({theme}) => ({
  fontSize: theme.typography.fontSizes.medium,
  fontWeight: 'bold',
}))

export const Title = styled.p(({theme}) => ({
  fontWeight: 'bolder',
  fontSize: theme.typography.fontSizes.large,
  textAlign: 'center',
}))

export const SubTitle = styled.p(({theme}) => ({
  fontWeight: 'bold',
}))

export const Text = styled.p(({theme}) => ({}))

export const Buttons = styled.div(({theme}) => ({
  display: 'flex',
  padding: '10px',
  backgroundColor: theme.palette.grey[900],
  width: '100%',
  justifyContent: 'space-evenly',
  position: 'fixed',
  bottom: 0,
}))

export const ButtonAgree = styled(Button)(({theme}) => ({
  fontSize: theme.typography.fontSizes.large,
  padding: `11px 15px`,
}))

export const ButtonDisAgree = styled(Button)(({theme}) => ({
  fontSize: theme.typography.fontSizes.large,
  padding: `11px 15px`,
}))
