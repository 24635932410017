import styled from 'styled-components'

export const Wrapper = styled.footer(({theme}) => ({
  paddingTop: 10,
  fontSize: theme.fontSizes.extraSmall,
  color: theme.palette.grey[400],
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}))

export const Link = styled.a.attrs({target: '_blank', rel: 'noopener'})({
  color: 'inherit',
  textDecoration: 'none',
  lineHeight: 1.2,
  margin: '3px 0',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:not(:last-child):after': {
    content: '"\u2022"',
    display: 'inline-block',
    margin: '0 0.5em',
  },
})
