import React, {useContext} from 'react'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import {ReactComponent as FaqSvg} from 'images/icons/faq.svg'
import I18nContext from 'contexts/I18nContext'
import IconButton from '../IconButton'

const ButtonFaq = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return (
    <IconButton
      tooltip={t('header.faq')}
      to={translatedUrl(routes.faq.path)}
      Icon={FaqSvg}
      {...rest}
    />
  )
}

export default ButtonFaq
