import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({}))

export const Content = styled.p(({theme}) => ({}))

export const Title = styled.p(({theme}) => ({}))

export const SubTitle = styled.p(({theme}) => ({}))

export const Text = styled.p(({theme}) => ({}))
