import {useCallback, useContext, useMemo, useState} from 'react'
import shortid from 'shortid'
import {useTheme} from '@material-ui/core/styles'
import I18nContext from 'contexts/I18nContext'
import {useFormContext} from 'react-hook-form'

export default function useInputProps({
  id: idPrivate,
  name,
  size,
  nature: propNature,
  color,
  disabled,
  placeholder,
  error: propError,
  onBlur: onBlurCb,
  onFocus: onFocusCs,
  inputRef,
  ...rest
}) {
  const {t} = useContext(I18nContext)
  const formContext = useFormContext()
  const theme = useTheme()
  const id = useMemo(() => idPrivate || `input-${name}-${shortid.generate()}`, [idPrivate, name])

  const error = useMemo(() => {
    let resultError
    if (propError != null) resultError = propError
    else resultError = formContext?.errors?.[name]

    if (!resultError) return null

    if (resultError.message) {
      if (resultError.message?.key)
        return t(`validationErrors.${resultError.message.key}`, resultError.message.values)
      return resultError.message?.split('_').join(' ')
    }
    return resultError.toString()
  }, [propError, formContext, name, t])

  const [isFocused, setIsFocused] = useState(false)
  const onFocus = useCallback(
    e => {
      setIsFocused(true)
      return onFocusCs && onFocusCs(e)
    },
    [onFocusCs]
  )
  const onBlur = useCallback(
    e => {
      setIsFocused(false)
      return onBlurCb && onBlurCb(e)
    },
    [onBlurCb]
  )

  const nature = useMemo(() => propNature || theme.palette.type, [propNature, theme.palette.type])

  const styleProps = useMemo(
    () => ({
      size,
      nature,
      color,
      disabled,
      isFocused,
      isInvalid: !!error,
    }),
    [color, disabled, error, isFocused, nature, size]
  )

  return {
    id,
    name,
    placeholder,
    error,
    onBlur,
    onFocus,
    isFocused,
    size,
    nature,
    disabled,
    styleProps,
    inputRef: inputRef !== undefined ? inputRef : formContext?.register,
    ...rest,
  }
}
