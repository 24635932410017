import {useCallback, useContext} from 'react'
import {useQueryCache} from 'react-query'
import {useHistory} from 'react-router-dom'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes/config'
import useApi from 'hooks/api/useApi'
import {PushNotifications} from '@capacitor/push-notifications'
import useAuthToken from 'hooks/auth/useAuthToken'
import {SocketContext} from 'containers/SocketProvider/SocketProvider'
import {NotificationContext} from 'containers/NotificationProvider/NotificationProvider'

import {setDeviceToken} from 'containers/NotificationProvider/actionTypes'
import {Capacitor} from '@capacitor/core'

export default function useLogout() {
  const api = useApi()
  const history = useHistory()
  const queryCache = useQueryCache()
  const [token, setToken] = useAuthToken()
  const {state} = useContext(SocketContext)
  const {state: notificationState, dispatch} = useContext(NotificationContext)

  return useCallback(async () => {
    queryCache.clear()
    try {
      if (Capacitor.getPlatform() !== 'web') {
        if (notificationState.device_token) {
          await api
            .unRegisterDeviceToken({device_token: notificationState.device_token})
            .then(() => {
              if (token) api.logout()
              dispatch(setDeviceToken(null))
              PushNotifications.removeAllDeliveredNotifications()
            })
        }
      }
      if (token) await api.logout()
    } finally {
      state?.cable?.disconnect()
      history.replace(translatedUrl(routes.auth.login.path))
    }
  }, [api, history, queryCache, setToken, token])
}
