import React, {useContext, useEffect, useState} from 'react'
// import * as PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import {useMutation} from 'react-query'
import useApi from 'hooks/api/useApi'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {Wrapper, SearchInput, SearchIcon} from './Search.styles'
import SearchResultsBottom from './SearchResultsBottom'

const Search = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const api = useApi()
  const [query, setQuery] = useState('')
  const [getPlayers, playersInfo] = useMutation(() =>
    api
      .get('/player/profiles', {query, search_fields: 'name', limit: 5, basics_only: true})
      .then(res => res.player_profiles)
  )
  const [getTeams, teamsInfo] = useMutation(() =>
    api
      .get('/team/profiles', {
        query,
        search_fields: ['team_profiles.name', 'team_profiles.club_name'].join(','),
        limit: 5,
        basics_only: true,
      })
      .then(res => res.team_profiles)
  )
  const [getPresses, pressesInfo] = useMutation(() =>
    api
      .get('/press/profiles', {query, search_fields: 'name', limit: 5, basics_only: true})
      .then(res => res.press_profiles)
  )

  useEffect(() => {
    if (query) {
      Promise.all([getPlayers(), getTeams(), getPresses()]).then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <ClickAwayListener onClickAway={() => setQuery('')}>
      <Wrapper {...rest}>
        <SearchInput
          name='search'
          placeholder={t('topSearch.searchPlaceholder')}
          withBorder={false}
          endAdornment={<SearchIcon />}
          value={query}
          onChange={e => setQuery(e.target.value)}
          autoComplete='off'
          autoCorrect='off'
          spellCheck='off'
          type='search'
        />
        <SearchResultsBottom
          opened={!!query}
          playersInfo={playersInfo}
          teamsInfo={teamsInfo}
          pressesInfo={pressesInfo}
          onClose={() => setQuery('')}
        />
      </Wrapper>
    </ClickAwayListener>
  )
}

Search.propTypes = {}

export default Search
