import React, {useContext} from 'react'
import NavigationLeft from 'components/navigations/common/NavigationLeft'
import I18nContext from 'contexts/I18nContext'
import {getPressNavItems} from 'data/navigation'

const NavigationLeftPress = ({...rest}) => {
  const {t} = useContext(I18nContext)
  return <NavigationLeft items={getPressNavItems(t)} {...rest} />
}

export default NavigationLeftPress
