import styled from 'styled-components'
import NavigationItem from 'components/navigations/common/NavigationItem'
import ContentContainer from 'components/ContentContainer'

export const Wrapper = styled.div(({theme}) => ({
  background: theme.palette.grey[850],
  borderBottom: `1px solid ${theme.palette.grey[600]}`,
  minHeight: 60,
  fontSize: theme.fontSizes.small,
  display: 'flex',
  overflowX: 'auto',
}))

export const ItemsWrapper = styled(ContentContainer)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  maxWidth: 'auto',
  paddingLeft: 15,
  paddingRight: 15,
}))

export const Item = styled(NavigationItem)(() => ({
  whiteSpace: 'nowrap',
  minHeight: 34,
  margin: '0 5px',
}))
