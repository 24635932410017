import styled from 'styled-components'
import PropTypes from 'prop-types'

const padding = 15
const maxWidth = 1280

const ContentContainer = styled.div`
  max-width: ${padding * 2 + maxWidth}px;
  margin: 0 auto;
  padding-left: ${padding}px;
  padding-right: ${padding}px;
  width: 100%;
`

ContentContainer.propTypes = {
  withNavPadding: PropTypes.bool,
}

export default ContentContainer
