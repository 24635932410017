import React, {useContext} from 'react'
import I18nContext from 'contexts/I18nContext'
import * as PropTypes from 'prop-types'
import LanguagesDropdown from 'components/LanguagesDropdown'
import Button from 'components/Button'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useLogout from 'hooks/auth/useLogout'
import {Wrapper} from './TopBar.styles'

const TopBar = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const {profile} = usePersonalProfile()
  const logout = useLogout()
  return (
    <Wrapper {...rest}>
      <LanguagesDropdown
        buttonProps={{
          color: 'rgba(144, 144, 155, 0.1)',
          textColor: 'grey[400]',
          size: 'def',
        }}
      />
      {profile && (
        <Button
          text={t('auth.logOutBtn').toUpperCase()}
          size='def'
          color='rgba(144, 144, 155, 0.1)'
          textColor='grey[400]'
          onClick={logout}
          style={{marginLeft: 10}}
          minWidth={85}
        />
      )}
    </Wrapper>
  )
}

TopBar.propTypes = {
  children: PropTypes.node,
}

export default TopBar
