import styled from 'styled-components'
import React from 'react'
import {Link} from 'react-router-dom'
import ButtonBase from '@material-ui/core/ButtonBase'

export const Wrapper = styled(({isActive, ...props}) => <ButtonBase component={Link} {...props} />)(
  ({theme, isActive}) => ({
    padding: '17px 20px 22px 20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: theme.palette.grey[900],
    color: theme.palette.grey[0],
    textDecoration: 'none',
    border: isActive
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.grey[600]}`,
    borderRadius: theme.radii.large,
    transitionDuration: `${theme.transitions.duration.short}ms`,
  })
)

export const Name = styled.span(({theme, isActive}) => ({
  paddingBottom: 3,
  marginTop: 14,
  width: '100%',
  color: isActive ? theme.palette.primary.main : null,
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: theme.typography.fontSizes.large,
  fontWeight: 500,
  // background: isActive ? `rgba(244, 182, 28, 0.05)` : null,
  borderRadius: 5,
  transitionDuration: `${theme.transitions.duration.short}ms`,
}))

export const SmallName = styled.span(({theme}) => ({
  fontFamily: theme.typography.fontFamilyTitle,
  fontSize: theme.typography.fontSizes.extraSmall,
  color: theme.palette.grey[400],
  fontWeight: 500,
  marginTop: 5,
}))

export const CheckMark = styled.span(({theme, isActive}) => ({
  alignSelf: 'center',
  fontSize: 'x-large',
  marginTop: 14,
  paddingLeft: 3,
  color: isActive ? theme.palette.primary.main : null,
  transitionDuration: `${theme.transitions.duration.short}ms`,
}))
