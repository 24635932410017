import {shape, string, number, bool, oneOf, oneOfType} from 'prop-types'
import {playerProfileType} from './player'
import {clubProfileType} from './club'
import {pressProfileType} from './press'
import {teamProfileType} from './team'
import {addressType, socialsType} from './other'

export const profileType = shape({
  id: oneOfType([number, string]).isRequired,
  profile_photo_url: string,
  player_profile: playerProfileType,
  club_profile: clubProfileType,
  press_profile: pressProfileType,
  team_profile: teamProfileType,
  social: socialsType,
  address: addressType,
  premium_account: bool,
})

export const userType = shape({
  id: oneOfType([number, string]).isRequired,
  email: string.isRequired,
  email_confirmed: bool.isRequired,
  role: oneOf(['user', 'admin', 'player', 'club', 'press', 'team']).isRequired,
  profile: profileType,
})
