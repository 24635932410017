import React, {useEffect} from 'react'
import {Redirect} from 'react-router-dom'
import routes, {AuthRoute, LocalizedSwitch, NonAuthOnlyRoute, DefaultRoute as Route} from 'routes'
import RegisterLayout from 'layouts/RegisterLayout'
import MainLayout from 'layouts/MainLayout'
import useScrollTo from 'hooks/helpers/useScrollTo'
import RegisterCompleteLayout from 'layouts/RegisterCompleteLayout'
import useUser from 'hooks/api/useUser'
import {setYupLocaleKeys} from 'utils/i18n'
import SocketProvider from 'containers/SocketProvider/SocketProvider'
import ReactGA from 'react-ga'
import {Capacitor} from '@capacitor/core'

const RedirectFromHome = () => {
  const {userInfo, isLoading} = useUser()
  if (isLoading) return null
  if (userInfo?.role === 'admin') return <Redirect to={routes.admin.path} />
  return <Redirect to={routes.feed.path} />
}

const ios = Capacitor.getPlatform() === 'ios'

const App = () => {
  useScrollTo()
  useEffect(() => {
    setYupLocaleKeys()
    if (ios) {
      ReactGA.initialize('G-8RXWHNHDX3')
    }
  }, [])

  return (
    <LocalizedSwitch>
      {/* Not found route */}
      <Route {...routes.notFound} />

      {/* Complete register steps */}
      <AuthRoute path={routes.auth.register.suggestions.path}>
        <RegisterCompleteLayout>
          <LocalizedSwitch>
            <Route {...routes.auth.register.suggestions} />
          </LocalizedSwitch>
        </RegisterCompleteLayout>
      </AuthRoute>

      <Route path={routes.auth.path}>
        <RegisterLayout>
          <LocalizedSwitch>
            {/* Login */}
            <NonAuthOnlyRoute {...routes.auth.login} />

            {/* Register */}
            {/* <AuthRoute {...routes.auth.register.fillInfo} /> */}
            <NonAuthOnlyRoute {...routes.auth.register.selectAccType} />
            <NonAuthOnlyRoute {...routes.auth.register} />

            {/* Reset password */}
            <NonAuthOnlyRoute {...routes.auth.resetPassword} />
            <Route {...routes.auth.resetPassword.createNew} />

            {/* Change email */}
            <Route {...routes.auth.changeEmail} />

            {/* Fallback: redirect to login */}
            <Redirect to={routes.auth.login.path} />
          </LocalizedSwitch>
        </RegisterLayout>
      </Route>

      <AuthRoute {...routes.admin}>
        <SocketProvider>
          <MainLayout>
            <LocalizedSwitch>
              {/* <AuthRoute {...routes.admin.clubManagement} /> */}
              <AuthRoute {...routes.admin.playersManagement} />
              <AuthRoute {...routes.admin.teamsManagement} />
              <AuthRoute {...routes.admin.pressesManagement} />
              <AuthRoute {...routes.admin.adsManagement} />
              <AuthRoute {...routes.admin.i18nManagement} />
              <AuthRoute {...routes.admin.premiumPlans} />
              <AuthRoute {...routes.admin.vacancies} />
              <AuthRoute {...routes.admin.premiumPanel} />
              <Redirect to={routes.admin.pressesManagement.path} />
            </LocalizedSwitch>
          </MainLayout>
        </SocketProvider>
      </AuthRoute>

      <Route>
        <SocketProvider>
          <MainLayout>
            <LocalizedSwitch>
              <AuthRoute {...routes.profile.player} />
              <AuthRoute {...routes.profile.press} />
              <AuthRoute {...routes.profile.club} />
              <AuthRoute {...routes.profile.team} />
              <AuthRoute {...routes.profileFollowers} />
              <AuthRoute {...routes.faq} />
              <AuthRoute {...routes.settings} />
              <AuthRoute {...routes.feed} />
              <AuthRoute {...routes.posts} />
              <AuthRoute {...routes.edit} />
              <AuthRoute {...routes.profile.personal} />
              <AuthRoute {...routes.teammates} />
              <AuthRoute {...routes.getPremium} />
              <AuthRoute {...routes.getPremium.billingAddress} />
              <AuthRoute {...routes.getPremium.modalOrder} />
              <AuthRoute {...routes.getPremium.orderIos} />
              <AuthRoute {...routes.search} />
              <AuthRoute {...routes.search.results} />
              <AuthRoute {...routes.contactLight} />
              <AuthRoute {...routes.teams} />
              <AuthRoute {...routes.team} />
              <AuthRoute {...routes.bookmarks} />
              <AuthRoute {...routes.vacancies} />
              <AuthRoute {...routes.vacancies.vacanciesOtherProfile} />
              <AuthRoute {...routes.vacancies.singleVacancy} />
              <AuthRoute {...routes.vacancies.promote} />
              <AuthRoute {...routes.vacancies.promoteIos} />
              <AuthRoute {...routes.messages} />
              <AuthRoute {...routes.notifications} />

              <AuthRoute path={routes.home.path} exact component={RedirectFromHome} />
            </LocalizedSwitch>
          </MainLayout>
        </SocketProvider>
      </Route>
    </LocalizedSwitch>
  )
}

export default App
