import {useQuery} from 'react-query'
import {cacheKeys} from 'api'
import useApi from '../api/useApi'

export default function useAdsQuery(params, queryConfig) {
  const api = useApi()
  return useQuery(
    [cacheKeys.ads, params],
    () => api.get('/ad_tools', params).then(res => res.ad_tools),
    queryConfig
  )
}
