import styled from 'styled-components'
import NavigationItem from 'components/navigations/common/NavigationItem'
import {
  Wrapper as NavigationItemWrapper,
  Text,
} from 'components/navigations/common/NavigationItem/NavigationItem.styles'
import {ReactComponent as ChevronRightSvg} from 'images/icons/chevron_right.svg'
import NavigationNoLink from '../NavigationNoLink'

export const Wrapper = styled.div(({theme}) => ({
  background: theme.palette.grey[900],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: theme.radii.large,
  overflow: 'hidden',
  fontSize: theme.fontSizes.small,
}))

export const HeadWrapper = styled.div(({theme}) => ({
  background: theme.palette.grey[700],
  padding: 15,
  display: 'flex',
}))

export const HeadItemWrapper = styled(NavigationItemWrapper)(() => ({
  flex: 1,
  minHeight: 54,
  paddingRight: 10,
}))

export const HeadItemText = styled(Text)(() => ({}))

export const ChevronRight = styled(ChevronRightSvg)(() => ({
  marginRight: '0 !important',
}))

export const ItemsWrapper = styled.div(({theme}) => ({
  padding: theme.spacing(0, 1.5),
}))

export const Item = styled(NavigationItem)(({theme}) => ({
  margin: theme.spacing(1, 0),
  minHeight: 40,
}))
