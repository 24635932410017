import React from 'react'
import HeaderPlayer from 'components/headers/HeaderPlayer'
import HeaderClub from 'components/headers/HeaderClub'
import HeaderTeam from 'components/headers/HeaderTeam'
import HeaderPress from 'components/headers/HeaderPress'
import HeaderDefault from 'components/headers/HeaderDefault'
import HeaderAdmin from 'components/headers/HeaderAdmin'
import NavigationTopClub from 'components/navigations/NavigationTopClub'
import NavigationTopTeam from 'components/navigations/NavigationTopTeam'
import usePersonalProfile from 'hooks/api/usePersonalProfile'

const MainLayoutHeader = () => {
  const {profileRole} = usePersonalProfile()

  if (profileRole === 'player') return <HeaderPlayer />
  if (profileRole === 'club')
    return (
      <>
        <HeaderClub />
        <NavigationTopClub />
      </>
    )
  if (profileRole === 'team')
    return (
      <>
        <HeaderTeam />
        <NavigationTopTeam />
      </>
    )
  if (profileRole === 'press') return <HeaderPress />
  if (profileRole === 'admin') return <HeaderAdmin />
  return <HeaderDefault />
}

export default MainLayoutHeader
