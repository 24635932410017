export default class ApiError extends Error {
  constructor(error, status) {
    let message = error
    if (Array.isArray(error)) {
      message = error.join('; ')
    } else if (typeof error === 'object' && error !== null) {
      message = Object.entries(error)
        .map(([, v]) => v)
        .join('; ')
    }
    super(message)
    this.message = message
    this.name = 'ApiError'
    this.status = status
    this.errorsObject = error
  }

  toString() {
    return this.message.toString()
  }
}
