import {useCallback, useMemo, useContext} from 'react'
import {set} from 'lodash'
import {UserProfileContext} from 'containers/UserProfileProvider/UserProfileProvider'
import {useQuery, useQueryCache} from 'react-query'
import {cacheKeys} from 'api'
import useApi from 'hooks/api/useApi'
import useAuthToken from 'hooks/auth/useAuthToken'
import useProfileInfo from 'hooks/api/useProfileInfo'
import useUser from './useUser'

/**
 * @param {Object} useQueryConfig
 * @returns {{profileName: string, updateProfile: Function, profile: Object, profilePosts: Array, profileRole: string, profilePostsCount: number, refetch: Function}}
 */
export default function usePersonalProfile(useQueryConfig = null) {
  const api = useApi()
  const queryCache = useQueryCache()
  const [token] = useAuthToken()
  const {userInfo} = useUser()
  const userRole = userInfo?.role
  const {state, setstate} = useContext(UserProfileContext)
  const cacheKey = useMemo(() => [cacheKeys.personalProfile, userInfo?.id], [userInfo?.id])

  const {data: profile, refetch, ...restParams} = useQuery(
    cacheKey,
    async () => {
      if (token && userInfo && userRole !== 'admin') {
        return api.getProfile('profile').then(prof => {
          setstate(prof)
          return prof
        })
      }
      return null
    },
    {
      refetchOnMount: false,
      enabled: !!token,
      ...useQueryConfig,
    }
  )

  const updateProfile = useCallback(
    (data, updatePath = null) => {
      queryCache.setQueryData(cacheKey, oldUser =>
        updatePath ? set(oldUser, updatePath, data) : data
      )
    },
    [cacheKey, queryCache]
  )

  const {profileRole, ...profileInfo} = useProfileInfo(profile, {fullName: false})

  return {
    profile,
    state,
    refetch,
    updateProfile,
    profileRole: profileRole || userRole,
    ...profileInfo,
    ...restParams,
  }
}
