import React, {useContext} from 'react'
import styled from 'styled-components'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes'
import Button from 'components/Button'
import I18nContext from 'contexts/I18nContext'
import {ReactComponent as MessagesSvg} from 'images/icons/menu/messages.svg'
import {generatePath} from 'react-router-dom'
import usePersonalProfile from 'hooks/api/usePersonalProfile'

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  flexGrow: 1,
  maxWidth: 180,
  minWidth: 'min-content',
})

const ButtonMessage = ({profile, buttonText, ...props}) => {
  const {t} = useContext(I18nContext)
  const {instance: personalInstance, profileRole: personalRole} = usePersonalProfile()
  const isTeamVerified = personalRole === 'team' ? personalInstance.verified : true
  const isDisabled = !isTeamVerified
  const url = {
    pathname: translatedUrl(
      profile
        ? generatePath(routes.messages.conversation.path, {id: profile?.user_id})
        : routes.messages.path
    ),
    search: `?create=1`,
  }

  return (
    <StyledButton
      disabled={isDisabled}
      color='secondary'
      text={buttonText || t('userProfileCard.btnSendMessage')}
      to={url}
      startIcon={<MessagesSvg width='1.25em' height='1.25em' />}
      {...props}
    />
  )
}

export default ButtonMessage
