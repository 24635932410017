import React, {useContext, useState} from 'react'
import {Storage} from '@capacitor/storage'
import Modal from 'components/Modal'
import {
  Wrapper,
  Content,
  Title,
  SubTitle,
  Text,
  MainTitle,
  ButtonAgree,
  ButtonDisAgree,
  Buttons,
} from './Terms.styles'

const Terms = ({setTermsAgree, TermsContext}) => {
  const {dispatch} = useContext(TermsContext)
  const [modalOpen, setModalOpen] = useState(false)
  const agree = async () => {
    await Storage.set({key: 'poacher_terms_agreed', value: 'true'}).then(() =>
      dispatch(setTermsAgree(true))
    )
  }

  return (
    <Wrapper>
      {modalOpen ? (
        <Modal open={modalOpen} onClose={() => setModalOpen(false)} title='Achtung!'>
          <p>
            Willst du die Datenschutzerklärung wirklich ablehnen? In diesem Fall hast du leider
            nicht die Möglichkeit, POACHER zu nutzen.
          </p>
        </Modal>
      ) : (
        <>
          <Content>
            <Title>Datenschutzerklärung</Title>
            <MainTitle>I. Allgemein</MainTitle>
            <Text>
              Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und behandeln diese
              vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerklärung. Diese Datenschutzerklärung gilt für unsere mobilen iPhone- und
              Android- Apps (im Folgenden „APP“). In ihr werden Art, Zweck und Umfang der
              Datenerhebung im Rahmen der APP-Nutzung erläutert. Wir weisen darauf hin, dass die
              Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </Text>
            <SubTitle>Verantwortliche Stelle</SubTitle>
            <Text>
              Verantwortliche Stelle für die Datenverarbeitung im Rahmen dieser APP ist: <br />
            </Text>
            <Text>
              POACHER Sports GmbH <br />
              In der Taufe 7<br />
              D – 51427 Bergisch Gladbach
              <br />
              E-Mail: info@poacher-sports.de <br />
              Website: poacher-sports.de
              <br />
              Tel.: +49 1525 2953757
              <br />
              „Verantwortliche Stelle“ ist die Stelle, die personenbezogene Daten (z. B. Namen,
              E-Mail- Adressen etc.) erhebt, verarbeitet oder nutzt
            </Text>
            <SubTitle>Datenschutzbeauftragter</SubTitle>
            <Text> Unseren Datenschutzbeauftragten erreichen Sie unter:</Text>
            <Text>
              Marcel Andrijanic
              <br />
              In der Taufe 8<br />
              D – 51427 Bergisch Gladbach
              <br />
              E-Mail: m.andrijanic@poacher-sports.de
              <br />
              Tel.: +49 173 6039753
            </Text>
            <SubTitle>Allgemeine Speicherdauer personenbezogener Daten</SubTitle>
            <Text>
              Vorbehaltlich abweichender oder konkretisierender Angaben innerhalb dieser
              Datenschutzerklärung werden die von dieser APP erhobenen personenbezogenen Daten
              gespeichert, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
              widerrufen oder der Zweck für die Datenspeicherung entfällt. Sofern eine gesetzliche
              Pflicht zur Aufbewahrung oder ein sonstiger gesetzlich anerkannter Grund zur
              Speicherung der Daten (z. B. berechtigtes Interesse) besteht, werden die betreffenden
              personenbezogenen Daten nicht vor Wegfall des jeweiligen Aufbewahrungsgrundes
              gelöscht.
            </Text>
            <SubTitle>Rechtsgrundlagen zur Speicherung personenbezogener Daten</SubTitle>
            <Text>
              Die Verarbeitung personenbezogener Daten ist nur zulässig, wenn eine wirksame
              Rechtsgrundlage für die Verarbeitung dieser Daten besteht. Sofern wir Ihre Daten
              verarbeiten, geschieht dies regelmäßig auf Grundlage Ihrer Einwilligung nach Art. 6
              Abs. 1 lit. a DSGVO (z. B. bei freiwilliger Angabe Ihrer Daten in der Anmeldemaske
              oder im Rahmen des Kontaktformulars), zum Zwecke der Vertragserfüllung nach Art. 6
              Abs. 1 lit. b DSGVO (z. B. bei Nutzung von In-APP- Käufen oder der Nutzung sonstiger
              kostenpflichtiger APP-Funktionen) oder aufgrund berechtigter Interessen nach Art. 6
              Abs. 1 lit. f DSGVO, die stets mit Ihren Interessen abgewogen werden (z. B. im Rahmen
              von Werbemaßnahmen). Die jeweils einschlägigen Rechtsgrundlagen werden ggf. an
              gesonderter Stelle im Rahmen dieser Datenschutzerklärung konkretisiert.
            </Text>
            <SubTitle>Verschlüsselung</SubTitle>
            <Text>
              Diese APP nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung
              vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als APP-Betreiber
              senden, oder der Kommunikation der APPNutzer untereinander, eine Verschlüsselung.
              Durch diese Verschlüsselung wird verhindert, dass die Daten, die Sie übermitteln, von
              unbefugten Dritten mitgelesen werden können.
            </Text>
            <SubTitle>Änderung dieser Datenschutzerklärung </SubTitle>
            <Text>
              Wir behalten uns das Recht vor, diese Datenschutzbestimmungen unter Einhaltung der
              gesetzlichen Vorgaben jederzeit zu ändern.
            </Text>

            <MainTitle>II. Ihre Rechte </MainTitle>
            <Text>
              Die DSGVO gewährt Betroffenen, deren personenbezogene Daten von uns verarbeitet
              werden, bestimmte Rechte, über die wir Sie an dieser Stelle aufklären möchten:
            </Text>
            <SubTitle>Widerruf Ihrer Einwilligung zur Datenverarbeitung</SubTitle>
            <Text>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer Einwilligung möglich. Diese werden
              wir vor Beginn der Datenverarbeitung ausdrücklich bei Ihnen einholen. Sie können diese
              Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
              uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge
              bleibt vom Widerruf unberührt.
            </Text>
            <SubTitle>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
              Direktwerbung (art. 21 DSGVO)
            </SubTitle>
            <Text>
              Wenn die Datenverarbeitung auf Grundlage von Art. 6 abs. 1 lit. e oder f DSGVO
              erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen
              Situation ergeben, gegen die Verarbeitung Sie betreffender personenbezogener Daten
              Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
              Profiling. Die jeweiligen Rechtsgrundlagen, auf denen eine Verarbeitung beruht,
              entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
              die betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
              können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
              Interessen, Rechte und Freiheiten überwiegen oder die der Verarbeitung der
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dienen.
              <br />
              Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so
              haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender
              personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für
              das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
              widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke
              der Direktwerbung verwendet.
            </Text>
            <SubTitle>Beschwerderecht bei einer Aufsichtsbehörde</SubTitle>
            <Text>
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei
              einer Aufsichtsbehörde zu. Das Beschwerderecht besteht unbeschadet anderweitiger
              verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
            </Text>
            <SubTitle>Auskunft, Löschung und Berichtigung </SubTitle>
            <Text>
              Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
              personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
              Datenverarbeitung sowie ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
              unter der im Impressum angegebenen Adresse an uns wenden.
            </Text>
            <SubTitle>Recht auf Einschränkung der Verarbeitung</SubTitle>
            <Text>
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
              zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen
              Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
              folgenden Fällen:
              <ul>
                <li>
                  Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
                  bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer
                  der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </li>
                <li>
                  Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah /
                  geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung
                  verlangen
                </li>
                <li>
                  Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
                  Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben
                  Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </li>
                <li>
                  Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
                  Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch
                  nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die
                  Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
              </ul>
              <br />
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen
              diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
              Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines
              wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
              verarbeitet werden.
            </Text>
            <SubTitle>Recht auf Datenübertragbarkeit </SubTitle>
            <Text>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
              eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
              gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
              Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
              soweit es technisch machbar ist.
            </Text>
            <MainTitle>III. Zugriffsrechte der APP</MainTitle>
            <Text>
              Zur Bereitstellung unserer Dienste über die APP benötigen wir die im folgenden
              aufgezählten Zugriffsrechte, die es uns ermöglichen, auf bestimmte Funktionen Ihres
              Geräts zuzugreifen. <br />
              <ul>
                <li>Standortdaten</li>
                <li>Gerätenummer Ihres Smartphones. </li>
                <li>Kontakte Ihrer Kontaktliste </li>
                <li>Fotos, Videos </li>
                <li>Anruflisten</li>
                <li>Kamera</li>
                <li>Mikrofon</li>
              </ul>
              <br />
              Der Zugriff auf die Gerätefunktionen ist erforderlich, um die Funktionalitäten der APP
              zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art.
              6 Abs. 1 lit. a DSGVO und/oder – sofern ein Vertrag geschlossen wurde – die Erfüllung
              unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO). Die Speicherdauer
              für die so erfassten Daten ist wie folgt geregelt:
              <br /> Unser Motto ist: Der Schutz Deiner Daten ist uns wichtig. Aus diesem Grund
              speichern wir die Daten nur so lange, wie wir dazu auch berechtigt sind. Das heißt,
              wir speichern die Daten bis der Verarbeitungszweck entfallen ist. Es gilt eine
              gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die Daten von uns
              gelöscht. Eine Ausnahme hiervon machen wir nur dann, wenn sie zur Vertragserfüllung
              oder Vertragsanbahnung erforderlich sind.
            </Text>
            <MainTitle>IV. Erfassung personenbezogener Daten im Rahmen der APP-Nutzung </MainTitle>
            <SubTitle>Allgemein </SubTitle>
            <Text>
              Wenn Sie unsere APP nutzen, erfassen wir folgende personenbezogene Daten von Ihnen:{' '}
              <br />
              <ul>
                <li>Vor- und Nachname</li>
                <li>E-Mail-Adresse</li>
                <li>Nutzungsdaten</li>
                <li>IP-Adresse </li>
                <li>Gerätekennung</li>
                <li>Metadaten</li>
              </ul>
              <br />
              Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, um die
              Funktionalitäten der APP zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung
              ist unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre
              Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO und/oder – sofern ein Vertrag
              geschlossen wurde – die Erfüllung unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1
              lit. b DSGVO). <br />
              Die Speicherdauer für die so erfassten Daten ist wie folgt geregelt:
              <br />
              Unser Motto ist: Der Schutz Deiner Daten ist uns wichtig. Aus diesem Grund speichern
              wir die Daten nur so lange, wie wir dazu auch berechtigt sind. Das heißt, wir
              speichern die Daten bis der Verarbeitungszweck entfallen ist. Es gilt eine gesetzliche
              Aufbewahrungsfrist. Nach Ablauf der Frist werden die Daten von uns gelöscht. Eine
              Ausnahme hiervon machen wir nur dann, wenn sie zur Vertragserfüllung oder
              Vertragsanbahnung erforderlich sind.
            </Text>
            <SubTitle>Anfrage innerhalb der APP, per E-Mail, Telefon oder Telefax </SubTitle>
            <Text>
              Wenn Sie uns kontaktieren (z. B. via Kontaktformular innerhalb der App, per E-Mail,
              Telefon oder Telefax), wird Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (z. B. Name, Anfrage) zum Zwecke der Bearbeitung Ihres
              Anliegens bei uns gespeichert und verarbeitet. Die Verarbeitung dieser Daten erfolgt
              auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
              eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich ist. In allen übrigen fallen beruht die Verarbeitung auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen
              (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen haben. Die von Ihnen an uns per
              Kontaktanfrage übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
              auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
              Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen –
              bleiben unberührt. Wir geben Ihre Daten nicht ohne Ihre Einwilligung weiter.
            </Text>
            <SubTitle>Kommentarfunktion in dieser APP </SubTitle>
            <Text>
              Für die Kommentarfunktion in dieser APP werden neben Ihrem Kommentar auch Angaben zum
              Zeitpunkt der Erstellung des Kommentars und, wenn Sie nicht anonym posten, der von
              Ihnen gewählte Nutzername gespeichert. Die Speicherung der Kommentare erfolgt auf
              Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die Kommentare und die
              damit verbundenen Daten werden gespeichert und verbleiben im Kommentarbereich und auf
              unseren Servern, bis der kommentierte Inhalt vollständig gelöscht wurde
            </Text>
            <SubTitle>Newsletterdaten</SubTitle>
            <Text>
              Wenn Sie den in unserer APP angebotenen Newsletter beziehen möchten, benötigen wir von
              Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten,
              dass Sie der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des
              Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten
              verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben
              sie nicht an Dritte weiter. Der Newsletterversand geschieht auf Grundlage Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
              <b>Diese Einwilligung können Sie jederzeit widerrufen.</b> Die von Ihnen zum Zwecke
              des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
              Austragung aus dem Newsletter gespeichert und nach der Austragung gelöscht.
            </Text>
            <MainTitle>V. Datenanalyse </MainTitle>
            <Text>
              Wenn Sie auf unsere APP zugreifen, kann Ihr Verhalten mit Hilfe bestimmter
              Analyse-Werkzeuge statistisch ausgewertet und zu Werbe- und Markforschungszwecken oder
              zur Verbesserung unserer Angebote analysiert werden. Bei der Verwendung derartiger
              Tools achten wir auf die Einhaltung der gesetzlichen Datenschutzbestimmungen. Beim
              Einsatz externer Dienstleister (Auftragsverarbeiter) stellen wir durch entsprechende
              Verträge mit den Dienstleistern sicher, dass die Datenverarbeitung den deutschen und
              europäischen Datenschutzstandards entspricht.
            </Text>
            <SubTitle>Stand: September, 2021</SubTitle>
          </Content>
          <Buttons>
            <ButtonDisAgree onClick={() => setModalOpen(true)}>Disagree</ButtonDisAgree>
            <ButtonAgree onClick={agree}>Agree</ButtonAgree>
          </Buttons>
        </>
      )}
    </Wrapper>
  )
}

export default Terms
