import React, {useContext, useMemo} from 'react'
import {ReactComponent as FaqSvg} from 'images/icons/faq.svg'
import I18nContext from 'contexts/I18nContext'
import LanguagesDropdown from 'components/LanguagesDropdown'
import HeaderTopBar from 'components/headers/common/HeaderTopBar'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import HeaderWrapper from 'components/headers/common/HeaderWrapper'
import Search from 'components/headers/common/Search'
import ButtonFaq from 'components/headers/common/ButtonFaq'
import ButtonNotifications from 'components/headers/common/ButtonNotifications'
import {getPlayerNavItems, getPlayerFooterItems} from 'data/navigation'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import routes from 'routes'
import ButtonPremium from 'components/PremiumCard/ButtonPremium'
import Footer from 'components/Footer'
import FooterMenu from 'components/FooterMenu'

const HeaderPlayer = ({...rest}) => {
  const {t} = useContext(I18nContext)
  // const sidebarItems = useMemo(
  //   () => [
  //     ...getPlayerNavItems(t),
  //     {
  //       to: routes.faq.path,
  //       text: t('navigation.faq'),
  //       iconSvg: FaqSvg,
  //     },
  //   ],
  //   [t]
  // )
  return (
    <HeaderWrapper collapseWidth='collapseLeftRow' {...rest}>
      {({opened, setOpened, isCollapsed}) => (
        <>
          <HeaderTopBar
            opened={opened}
            setOpened={setOpened}
            isCollapsed={isCollapsed}
            centerNode={
              <>
                {!isCollapsed && <ButtonFaq />}
                <Search />
                {!isCollapsed && <ButtonNotifications />}
              </>
            }
            rightNode={
              !isCollapsed && (
                <>
                  <LanguagesDropdown />
                  <ButtonPremium id='header.get-premium' hideIfPremium />
                </>
              )
            }
          />
          {/* <HeaderSideBar
            opened={opened}
            setOpened={setOpened}
            items={sidebarItems}
            bottomNode={
              <>
                <Footer />
                <ButtonPremium hideIfPremium w100 onClick={() => setOpened(false)} size='def' />
              </>
            }
          /> */}
        </>
      )}
    </HeaderWrapper>
  )
}

export default HeaderPlayer
