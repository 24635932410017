import styled from 'styled-components'
import Button from 'components/Button'
import {ReactComponent as IconSvg} from 'images/icons/chevron_down.svg'

export const Text = styled.span(() => ({
  marginLeft: '0.5em',
}))

export const ButtonTrigger = styled(Button)(() => ({
  padding: '0 0.75em',
  fontWeight: 400,
}))

export const SelectIcon = styled(IconSvg)(() => ({
  fontSize: '1em',
  width: '1em',
  height: '1em',
  marginLeft: '0.25em',
}))
