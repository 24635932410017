import React, {lazy, Suspense} from 'react'
import ProgressBar from 'react-topbar-progress-indicator'

ProgressBar.config({
  barColors: {
    0: '#E84E1B',
    1: '#f4b61c',
  },
  shadowBlur: 0,
})

const Lazyloading = (importFunc, {fallback = null} = {}) => {
  const LazyComponent = lazy(importFunc)
  return props => (
    <Suspense fallback={fallback || <ProgressBar />}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

Lazyloading.defaultProps = {
  fallback: null,
}

export default Lazyloading
