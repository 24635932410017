import React, {useEffect, useReducer, createContext, useState, useContext} from 'react'
import {Capacitor} from '@capacitor/core'
import Terms from 'components/Terms'
import {Storage} from '@capacitor/storage'
import LoadingCircle from 'components/LoadingCircle'
import {Wrapper} from './PrivacyPolicy.styles'
import {setTermsAgree} from './actionTypes'

const initialState = {agreedTerms: false}
export const TermsContext = createContext(initialState)

const PrivacyPolicy = ({children}) => {
  const isWeb = Capacitor.getPlatform() === 'web'
  const reducer = (state, action) => {
    switch (action.type) {
      case 'Terms':
        return {...state, agreedTerms: action.payload}
      default:
        return state
    }
  }

  const [loading, setLoading] = useState(true)

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(async () => {
    const {value} = await Storage.get({key: 'poacher_terms_agreed'})
    if (value === 'true') dispatch(setTermsAgree(true))
    setLoading(false)
  }, [])

  return (
    <>
      {loading ? (
        <LoadingCircle />
      ) : (
        <TermsContext.Provider value={{state, dispatch}}>
          {!isWeb && !state.agreedTerms ? (
            <Terms setTermsAgree={setTermsAgree} TermsContext={TermsContext} />
          ) : (
            children
          )}
        </TermsContext.Provider>
      )}
    </>
  )
}

export default PrivacyPolicy
