import {useQuery} from 'react-query'
import {cacheKeys} from 'api'
import usePersonalProfile from 'hooks/api/usePersonalProfile'
import useApi from 'hooks/api/useApi'

export default function useBookmarks() {
  const {profile} = usePersonalProfile()
  const api = useApi()

  return useQuery(cacheKeys.bookmarks, async () => {
    if (profile) {
      const {user_profiles} = await api.getBookmarks({user_profile_id: profile.id})
      return user_profiles
    }
    return undefined
  })
}
