import React from 'react'
import {useRouteMatch} from 'react-router-dom'
import Avatar from 'components/Avatar'
import {translatedUrl} from 'utils/i18n'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import routes from 'routes'
import {string} from 'prop-types'
import {avatarText} from 'utils/helpers'
import {Name, SmallName, Wrapper, CheckMark} from './ClubPersonalCardSmall.styles'

const ClubPersonalCardSmall = ({photoUrl, name, additionalName, profile, ...rest}) => {
  const to = translatedUrl(routes.profile.personal.path)
  const match = useRouteMatch({
    path: to,
  })
  const isVerified = profile?.team_profile?.verified

  return (
    <Wrapper id='team-profile' to={to} isActive={!!match} {...rest}>
      <Avatar radius={46} src={photoUrl} text={avatarText(name)} />
      <div style={{display: 'flex'}}>
        <Name isActive={!!match}>{name}</Name>
        {additionalName && <SmallName>{additionalName}</SmallName>}
        {isVerified && (
          <CheckMark isActive={!!match}>
            <CheckCircleOutlineIcon />
          </CheckMark>
        )}
      </div>
    </Wrapper>
  )
}

ClubPersonalCardSmall.propTypes = {
  name: string,
  photoUrl: string,
  additionalName: string,
}

export default ClubPersonalCardSmall
