const breakpoints = {
  unit: 'px',
  values: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tabletS: 600,
    tablet: 768,
    tabletL: 992,
    laptop: 1024,
    laptopS: 1199,
    laptopM: 1280,
    laptopL: 1440,
    desktop: 2560,
    collapseRightRow: 650,
    collapseLeftRow: 1100,
    collapseTopNavigation: 1100,
  },
  get down() {
    return key => {
      const value = this.values[key] ? `${this.values[key] - 0.5}${this.unit}` : key
      return `@media (max-width:${value})`
    }
  },
  get up() {
    return key => {
      const value = this.values[key] ? `${this.values[key]}${this.unit}` : key
      return `@media (min-width:${value})`
    }
  },
}

export default breakpoints
