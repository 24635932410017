import React, {useCallback, useState} from 'react'
import * as PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import {StyledImage, Wrapper} from './LazyImage.styles'

const LazyImage = ({
  lazyLoadProps,
  wrapperStyle,
  animDuration,
  animDelay,
  onError,
  fallbackSrc,
  ...imgProps
}) => {
  const [isLoaded, setLoaded] = useState(false)

  const handleImgError = useCallback(
    e => {
      if (fallbackSrc) {
        e.target.src = fallbackSrc
      }
      onError?.(e)
    },
    [fallbackSrc, onError]
  )

  return (
    <Wrapper
      style={wrapperStyle}
      animDuration={animDuration}
      animDelay={animDelay}
      isLoaded={isLoaded}
    >
      <LazyLoad classNamePrefix='image-lazy-loader' once {...lazyLoadProps}>
        <StyledImage onLoad={() => setLoaded(true)} onError={handleImgError} {...imgProps} />
      </LazyLoad>
    </Wrapper>
  )
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string,
  animDuration: PropTypes.string,
  animDelay: PropTypes.string,
  lazyLoadProps: PropTypes.object,
  wrapperStyle: PropTypes.object,
  onError: PropTypes.func,
}

LazyImage.defaultProps = {
  animDuration: '500ms',
  animDelay: '100ms',
}
export default LazyImage
