import React from 'react'
import styled, {css} from 'styled-components'
import FormError from 'components/FormError'
import InputBase from '@material-ui/core/InputBase'
import {ReactComponent as TooltipSvg} from 'images/icons/tooltip-icon.svg'

export const inputSizes = {
  def: {
    height: 38,
    padding: '0 0 0 15px',
    fontSize: 'small',
    borderRadius: 'default',
    label: {
      fontSize: 'extraSmall',
    },
  },
  md: {
    height: 44,
    padding: '0 0 0 18px',
    fontSize: 'small',
    borderRadius: 'default',
    label: {
      fontSize: 'extraSmall',
    },
  },
}

export const inputNatures = {
  light: {
    background: 'grey[50]',
    color: 'grey[550]',
    borderColor: 'grey[100]',
    placeholderColor: 'grey[300]',
    label: {
      color: 'grey[600]',
    },
  },
  dark: {
    background: 'grey[600]',
    color: 'grey[200]',
    borderColor: 'grey[500]',
    placeholderColor: 'grey[400]',
    label: {
      color: 'grey[500]',
    },
  },
}

export const Container = styled.div(({theme}) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-start',
  marginBottom: 12,
  width: '100%',
  fontFamily: theme.typography.fontFamily,
}))

export const inputWrapperCss = css(
  ({theme, nature, color, size, disabled, isFocused, isInvalid, withBorder}) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transitionDuration: `${theme.transitions.duration.shorter}ms`,
    overflow: 'hidden',
    borderWidth: withBorder ? 1 : 0,
    borderStyle: 'solid',
    fontWeight: 400,

    background: theme.resolveColor(inputNatures[nature].background),
    color: theme.resolveColor(inputNatures[nature].color),
    borderColor: theme.resolveColor(inputNatures[nature].borderColor),

    minHeight: inputSizes[size].height,
    fontSize: theme.resolve(`fontSizes.${inputSizes[size].fontSize}`),
    borderRadius: theme.resolve(`radii.${inputSizes[size].borderRadius}`),

    '&:hover': {
      borderColor: !isInvalid ? theme.resolveColor(color, true) : theme.palette.error.main,
    },
    ...(disabled && {
      filter: 'brightness(90%)',
      opacity: 0.85,
      cursor: 'not-allowed',
    }),
    ...(isFocused && {
      borderColor: theme.resolveColor(color, true),
      boxShadow: `0 0 0 1px ${theme.resolveColor(color, true)}`,
    }),
    ...(isInvalid && {
      borderColor: theme.palette.error.main,
      boxShadow: 'none',
    }),
  })
)

export const inputInnerCss = css(({theme, disabled, size, nature}) => ({
  width: '100%',
  height: '100%',
  minHeight: inputSizes[size].height - 2,
  flex: 1,
  appearance: 'none',
  outline: 'none',
  background: 'none',
  border: 'none',
  fontSize: 'inherit',
  padding: size ? inputSizes[size].padding : undefined,
  color: 'inherit',
  boxSizing: 'border-box',

  ...(disabled && {
    pointerEvents: 'none',
  }),

  '&::input-placeholder': {
    color: nature ? theme.resolveColor(inputNatures[nature].placeholderColor) : undefined,
    opacity: 1,
  },
}))

export const InputStyled = styled(
  ({size, nature, color, isFocused, isInvalid, withBorder, ...props}) => <InputBase {...props} />
)`
  ${inputWrapperCss};
  input,
  textarea {
    ${inputInnerCss};
  }
  textarea {
    min-height: auto;
  }
`

export const Error = styled(({size, ...props}) => <FormError {...props} />)(({theme, size}) => ({
  fontSize: theme.resolve(`fontSizes.${inputSizes[size].label.fontSize}`),
  lineHeight: 1.2,
  marginTop: 4,
  marginBottom: 0,
}))

export const Label = styled.label(({theme, size, nature, color, isFocused, isInvalid}) => ({
  padding: '0 0 0.25em',
  fontSize: theme.resolve(`fontSizes.${inputSizes[size].label.fontSize}`),
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  color: theme.resolveColor(inputNatures[nature].label.color),
  lineHeight: 1,
  userSelect: 'none',
  transitionDuration: `${theme.transitions.duration.shorter}ms`,
  ...(isFocused && {
    color: theme.resolveColor(color, true),
  }),
  ...(isInvalid && {
    color: theme.palette.error.main,
  }),
}))

export const TooltipIcon = styled(TooltipSvg)({
  fill: 'currentColor',
  marginLeft: '0.25em',
  marginBottom: 1,
  width: '1.1em',
  height: '1.1em',
  display: 'inline-block',
})
