import styled, {createGlobalStyle} from 'styled-components'
import {Link} from 'react-router-dom'
import {ReactComponent as LogoSvg} from 'images/common/logo-beta.svg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export const sizes = {
  default: {
    height: 80,
    buttonSize: 50,
  },
  mobile: {
    height: 70,
    buttonSize: 40,
  },
}

export const mobileSize = 600

export const BodyPaddingStyle = createGlobalStyle(() => ({
  body: {
    paddingTop: sizes.default.height,
    [`@media (max-width: ${mobileSize}px)`]: {
      paddingTop: sizes.mobile.height,
    },
  },
}))

export const Wrapper = styled.div(({theme, ...props}) => ({
  background: theme.palette.grey[700],
  width: '100%',
  height: 'auto',
  position: 'fixed',
  top: 0,
  left: 0,
  borderBottom: `1px solid ${theme.palette.grey[600]}`,
  paddingTop: 'env(safe-area-inset-top)',
}))

export const FlexWrapper = styled.div(({theme}) => ({
  display: 'flex',
  // paddingTop: 'env(safe-area-inset-top, 20px)',
  paddingLeft: 'env(safe-area-inset-left)',
  paddingRight: 'env(safe-area-inset-right)',
  // paddingBottom: 'env(safe-area-inset-bottom)',
  height: sizes.default.buttonSize,
  [`@media (max-width: ${mobileSize}px)`]: {
    height: sizes.mobile.buttonSize,
  },
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(1),
  },
}))

export const LeftWrapper = styled(FlexWrapper)(() => ({
  flex: 1,
  minWidth: 'min-content',
  display: 'flex',
  alignItems: 'center',
}))

export const RightWrapper = styled(FlexWrapper)(() => ({
  flex: 1,
  minWidth: 'min-content',
  justifyContent: 'flex-end',
}))

export const CenterItems = styled(FlexWrapper)(({theme}) => ({
  padding: theme.spacing(0, 1),
  maxWidth: 600,
  width: '100%',
}))

export const LogoLinkK = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const Logo = styled(LogoSvg)(() => ({
  width: 180,
  height: 'auto',

  [`@media (max-width: ${mobileSize}px)`]: {
    display: 'none',
  },
}))

export const LogoSmall = styled.img(() => ({
  width: 'auto',
  height: 36,
  [`@media (min-width: ${mobileSize + 1}px)`]: {
    display: 'none',
  },
}))

export const Content = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  height: 70,
  padding: theme.spacing(0, 1.5),
}))

export const ArrowBack = styled(ArrowBackIosIcon)(({theme}) => ({
  fontSize: '16px !important',
  margin: '0px 7px 0 0 !important',
}))
