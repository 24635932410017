import React, {useContext, useMemo} from 'react'
import LanguagesDropdown from 'components/LanguagesDropdown'
import HeaderTopBar from 'components/headers/common/HeaderTopBar'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import HeaderWrapper from 'components/headers/common/HeaderWrapper'
import I18nContext from 'contexts/I18nContext'
import {getAdminNavItems} from 'data/navigation'

const HeaderAdmin = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const sidebarItems = useMemo(() => [...getAdminNavItems(t)], [t])
  return (
    <HeaderWrapper collapseWidth='collapseLeftRow' {...rest}>
      {({opened, setOpened, isCollapsed}) => (
        <>
          <HeaderTopBar
            opened={opened}
            setOpened={setOpened}
            isCollapsed={isCollapsed}
            rightNode={!isCollapsed && <LanguagesDropdown />}
          />
          <HeaderSideBar opened={opened} setOpened={setOpened} items={sidebarItems} />
        </>
      )}
    </HeaderWrapper>
  )
}

export default HeaderAdmin
