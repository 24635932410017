import React, {useContext, useEffect, useState} from 'react'
import I18nContext from 'contexts/I18nContext'
import {useQuery} from 'react-query'
import useApi from 'hooks/api/useApi'
import LoadingCircle from 'components/LoadingCircle'
import {
  PlayerSuggestionsSubtitle,
  PlayerSuggestionCard,
  PlayerSuggestionsTitle,
  Wrapper,
} from './PlayerSuggestions.styles'

const PlayerSuggestions = ({...rest}) => {
  const {t} = useContext(I18nContext)
  const api = useApi()
  const [suggestions, setSuggestions] = useState()
  const [optionalPlayerSuggestions, setOptionalPlayerSuggestions] = useState()
  const {data, isLoading} = useQuery('playerSuggestions', () => api.randomUsers())
  const {data: optionalSuggestions, refetch} = useQuery('optionalPlayerSuggestions', () =>
    api.randomUsers()
  )

  const refetchSuggestions = user_id => {
    refetch()
    setSuggestions(
      [optionalPlayerSuggestions[0]].concat(
        suggestions.filter(suggestion => suggestion.user_id !== user_id)
      )
    )
  }
  useEffect(() => {
    setSuggestions(data?.user_profiles)
  }, [data])

  useEffect(() => {
    setOptionalPlayerSuggestions(optionalSuggestions?.user_profiles)
  }, [optionalSuggestions])

  return (
    <Wrapper {...rest}>
      <>
        <PlayerSuggestionsTitle>{t('playerSuggestions.title')}</PlayerSuggestionsTitle>
        <PlayerSuggestionsSubtitle>{t('playerSuggestions.subtitle')}</PlayerSuggestionsSubtitle>
        {isLoading ? (
          <LoadingCircle dia={30} />
        ) : (
          suggestions?.map(profile => (
            <PlayerSuggestionCard
              key={profile.id}
              profile={profile}
              w100
              refetchSuggestions={refetchSuggestions}
            />
          ))
        )}
      </>
    </Wrapper>
  )
}

export default PlayerSuggestions
