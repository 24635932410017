import React, {useEffect, useRef, useState} from 'react'
import * as PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {Wrapper} from './HeaderWrapper.styles'

const HeaderWrapper = ({collapseWidth, children, ...rest}) => {
  const navRef = useRef(null)
  const [opened, setOpened] = useState(false)
  const isCollapsed = useMediaQuery(theme => theme.breakpoints.down(collapseWidth))
  useEffect(() => {
    if (opened) {
      if (!isCollapsed) {
        setOpened(false)
        enableBodyScroll(navRef.current)
      } else {
        disableBodyScroll(navRef.current)
      }
    } else {
      enableBodyScroll(navRef.current)
    }
  }, [opened, isCollapsed, navRef])

  // release overflow hidden on unmount
  useEffect(
    () => () => {
      navRef.current && enableBodyScroll(navRef.current)
    },
    [navRef]
  )
  return (
    <Wrapper ref={navRef} {...rest}>
      {children({opened, setOpened, isCollapsed, collapseWidth})}
    </Wrapper>
  )
}

HeaderWrapper.propTypes = {
  collapseWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.func,
}

export default HeaderWrapper
