import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import I18nContext from 'contexts/I18nContext'

const SEO = props => {
  const {t, language} = useContext(I18nContext)

  const {title, meta, titleTemplate} = props
  const defaultTitle = props.defaultTitle || t('seo.titleDefault')
  const description = props.description || t('seo.descriptionDefault')

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={titleTemplate || `%s | ${defaultTitle}`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  title: null,
  defaultTitle: null,
  titleTemplate: null,
  description: null,
  meta: [],
}

SEO.propTypes = {
  title: PropTypes.string,
  defaultTitle: PropTypes.string,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
