import styled from 'styled-components'

export const SocialIconLink = styled.a(() => ({
  display: 'flex',
  width: 24,
  height: 24,

  '& svg': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}))
