import {shape, number, string, oneOf} from 'prop-types'

export const socialsType = shape({
  fupa: string,
  transfermarkt: string,
  facebook: string,
  instagram: string,
})

export const addressType = shape({
  id: number.isRequired,
  country: string.isRequired,
  city: string.isRequired,
  address_line_1: string.isRequired,
  address_line_2: string,
  zip_code: string.isRequired,
  website: string,
})

export const positionType = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const skillType = shape({
  id: number.isRequired,
  name: string.isRequired,
  image_url: string,
})

export const footType = oneOf(['left', 'right', 'both', ''])

export const viewAsType = oneOf(['personal', 'player', 'club', 'press', 'team', 'admin'])

export const adType = shape({
  id: number.isRequired,
  link: string,
  image_url: string,
})
