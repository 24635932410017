import React, {forwardRef, useEffect, useImperativeHandle} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as PropTypes from 'prop-types'
import {useSnackbar} from 'notistack'
import {FormStyled} from './Form.styles'

const Form = forwardRef(
  (
    {children, error, yupSchema, onSubmit, mode, formProps, style, className, ...hookProps},
    ref
  ) => {
    const methods = useForm({
      mode,
      resolver: yupResolver(yupSchema),
      ...hookProps,
    })

    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    useEffect(() => {
      if (error == null) {
        // reset error
        methods.clearErrors('global')
        closeSnackbar()
      } else {
        // set errors by name to show under input
        if (error.errorsObject)
          Object.entries(error.errorsObject).forEach(([key, err]) => {
            methods.setError(key, {
              type: 'apiError',
              message: err.toString(),
            })
          })

        // set global message error with all errors
        methods.setError('global', {
          type: 'apiError',
          message: error.toString(),
        })

        enqueueSnackbar(error.toString(), {
          variant: 'error',
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useImperativeHandle(ref, () => ({...methods}))

    return (
      <FormProvider {...methods}>
        <FormStyled
          onSubmit={methods.handleSubmit(onSubmit)}
          noValidate
          style={style}
          className={className}
          {...formProps}
        >
          {children}
        </FormStyled>
      </FormProvider>
    )
  }
)

Form.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  mode: PropTypes.string,
  yupSchema: PropTypes.object,
  error: PropTypes.object,
  formProps: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
}

Form.defaultProps = {
  mode: 'all',
}

export default Form
