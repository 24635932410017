const defaultLanguage = 'de'
const languages = ['de', 'en']

// https://www.i18next.com/overview/configuration-options
const i18nextOptions = {
  keySeparator: '.',
  nsSeparator: ':',
  ns: ['translation'],
  defaultNs: 'translation',
  // transSupportBasicHtmlNodes: false,
  transKeepBasicHtmlNodesFor: ['br'],
  fallbackLng: 'en',
  whitelist: languages,
  nonExplicitWhitelist: false,
  returnEmptyString: false,
  returnNull: false,
  wait: true,
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    addPath: '/locales/add/{{lng}}/{{ns}}',
  },
  detection: {
    // order and from where user language should be detected
    order: ['querystring', 'localStorage', 'cookie', 'path', 'navigator'],
    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'CC',
    lookupLocalStorage: 'lang',
    lookupFromPathIndex: 0,
    // cache user language on
    caches: ['localStorage'],
    // only detect languages that are in the whitelist
    checkWhitelist: true,
  },
  parseMissingKeyHandler: key => {
    const splitKey = key.split('.')
    return splitKey[splitKey.length - 1]
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  react: {
    wait: true,
    defaultTransParent: 'div',
    hashTransKey(defaultValue) {
      // eslint-disable-next-line no-console
      console.log('missing key', defaultValue)
      return defaultValue
    },
  },
}

module.exports = {
  languages,
  defaultLanguage,
  i18nextOptions,
}
