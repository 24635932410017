import styled from 'styled-components'
import ButtonBase from '@material-ui/core/ButtonBase'
import {Link} from 'react-router-dom'
import React from 'react'

export const animName = 'search-appear'
export const animDuration = 150

export const Wrapper = styled.div(({theme}) => ({
  position: 'absolute',
  bottom: -10,
  left: 0,
  width: '100%',
  minWidth: 240,
  minHeight: 140,
  maxHeight: 400,
  overflowY: 'auto',
  transform: 'translateY(100%)',
  background: theme.palette.grey[600],
  boxShadow: '10px 23px 55px rgba(0, 0, 0, 0.98)',
  borderRadius: theme.radii.default,
  zIndex: '1',
  padding: theme.spacing(1.5),

  transitionDuration: `${animDuration}ms`,
  [`&.${animName}-enter-active, &.${animName}-enter-done`]: {
    opacity: 1,
  },
  [`&.${animName}-exit-active, &.${animName}-exit-done`]: {
    opacity: 0,
  },
}))

export const MainTitle = styled.div(({theme}) => ({
  ...theme.typography.h3,
  marginBottom: '0.75em',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.2,
}))

export const CategoryTitle = styled.div(({theme}) => ({
  fontSize: theme.typography.fontSizes.small,
  color: theme.palette.grey[400],
  fontWeight: 500,
  marginBottom: '0.5em',
  marginTop: '0.5em',
  textTransform: 'uppercase',
  lineHeight: 1.2,
}))

export const ProfileSearchPreviewWrapper = styled(({...props}) => (
  <ButtonBase component={Link} {...props} />
))(({theme}) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: theme.radii.small,
  padding: '5px 0',
  fontSize: theme.typography.fontSizes.small,
}))

export const ProfileSearchPreviewContent = styled.div({
  marginLeft: '0.5em',
  lineHeight: 1.2,
})

export const ProfileSearchPreviewName = styled.div({
  fontWeight: 500,
})

export const ProfileSearchPreviewNameSecondary = styled.div(({theme}) => ({
  marginTop: 2,
  color: theme.palette.grey[200],
}))
