import React, {useContext} from 'react'
import * as PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import {CSSTransition} from 'react-transition-group'
import LoadingCircle from 'components/LoadingCircle'
import Avatar from 'components/Avatar'
import {avatarText} from 'utils/helpers'
import routes from 'routes'
import {generatePath} from 'react-router-dom'
import {
  Wrapper,
  MainTitle,
  CategoryTitle,
  ProfileSearchPreviewWrapper,
  ProfileSearchPreviewContent,
  ProfileSearchPreviewName,
  ProfileSearchPreviewNameSecondary,
  animName,
  animDuration,
} from './SearchResultsBottom.styles'

const ProfileSearchPreview = ({profile, route, ...rest}) => {
  const profilePhoto = profile?.user_profile?.profile_photo_url
  return (
    <ProfileSearchPreviewWrapper to={generatePath(route, profile)} {...rest}>
      <Avatar radius={16} text={avatarText(profile.name)} src={profilePhoto} />
      <ProfileSearchPreviewContent>
        <ProfileSearchPreviewName>{profile.name}</ProfileSearchPreviewName>
        {/* Club Team Logic */}
        {profile?.club_name && (
          <ProfileSearchPreviewNameSecondary>
            {profile?.club_name}
          </ProfileSearchPreviewNameSecondary>
        )}
      </ProfileSearchPreviewContent>
    </ProfileSearchPreviewWrapper>
  )
}

const SearchCategoryResult = ({title, queryInfo, route, onClose}) => {
  if (queryInfo?.data?.length > 0)
    return (
      <>
        <CategoryTitle>{title}</CategoryTitle>
        {queryInfo?.data?.map(profile => (
          <ProfileSearchPreview
            key={profile.id}
            profile={profile}
            route={route}
            onClick={onClose}
          />
        ))}
      </>
    )
  return null
}

const SearchResultsBottom = ({
  opened,
  onClose,
  playersInfo,
  // clubsInfo,
  teamsInfo,
  pressesInfo,
  ...rest
}) => {
  const {t} = useContext(I18nContext)
  const isLoading = playersInfo?.isLoading || teamsInfo?.isLoading || pressesInfo?.isLoading

  return (
    <CSSTransition in={opened} classNames={animName} timeout={animDuration} unmountOnExit>
      <Wrapper {...rest}>
        <MainTitle>
          {t('topSearch.suggestions.title')}
          {isLoading && <LoadingCircle dia={20} thickness={6} style={{marginLeft: 8}} />}
        </MainTitle>
        <SearchCategoryResult
          title={t('topSearch.suggestions.players')}
          queryInfo={playersInfo}
          route={routes.profile.player.path}
          onClose={onClose}
        />
        {/* <SearchCategoryResult
          title={t('topSearch.suggestions.clubs')}
          queryInfo={clubsInfo}
          route={routes.profile.club.path}
          onClose={onClose}
        /> */}
        <SearchCategoryResult
          title={t('topSearch.suggestions.teams')}
          queryInfo={teamsInfo}
          route={routes.profile.team.path}
          onClose={onClose}
        />
        <SearchCategoryResult
          title={t('topSearch.suggestions.presses')}
          queryInfo={pressesInfo}
          route={routes.profile.press.path}
          onClose={onClose}
        />
      </Wrapper>
    </CSSTransition>
  )
}

SearchResultsBottom.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  playersInfo: PropTypes.object.isRequired,
  // clubsInfo: PropTypes.object.isRequired,
  teamsInfo: PropTypes.object.isRequired,
  pressesInfo: PropTypes.object.isRequired,
}

export default SearchResultsBottom
