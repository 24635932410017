import routes from 'routes'

const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )
  return JSON.parse(jsonPayload)
}

const shouldRefreshTheToken = token => {
  const parsedToken = parseJwt(token)
  const expiresAt = parsedToken.exp * 1000
  const issuedAt = parsedToken.iat * 1000
  const now = +new Date()
  const lifeSpan = expiresAt - issuedAt
  return now - issuedAt > lifeSpan * 0.7
}

const refreshToken = (api, token, setToken, history) => {
  if (shouldRefreshTheToken(token)) {
    api.refreshToken().then(
      res => setToken(res.headers.authorization),
      () => history.push(routes.auth.login.path)
    )
  }
}

export default refreshToken
