import {useCallback, useMemo} from 'react'
import config from 'config'
import Api from 'api'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'
import {useQueryCache} from 'react-query'
import {translatedUrl} from 'utils/i18n'
import routes from 'routes/config'
import useAuthToken from 'hooks/auth/useAuthToken'

/**
 * @returns {ApiMethods} Returns Api instance with 'Authorization' header if user is logged in
 */
export default function useApi() {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const queryCache = useQueryCache()
  const [token, setToken] = useAuthToken()

  const onUnauthorized = useCallback(
    error => {
      setToken(null)
      queryCache.clear()

      const message = t('auth.errors.logoutMessage', {reason: error.toString()})
      history.replace({
        pathname: translatedUrl(routes.auth.login.path),
        state: {from: location, message},
      })
    },
    [history, location, queryCache, setToken, t]
  )

  return useMemo(
    () =>
      new Api({
        baseUrl: config.apiUrl,
        commonHeaders: token ? {Authorization: `${token}`} : {},
        onUnauthorized,
      }),
    [token, onUnauthorized]
  )
}
