import routes from 'routes'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import React, {useContext} from 'react'
import I18nContext from 'contexts/I18nContext'

const ButtonEdit = ({props, pathTab, text}) => {
  const {t} = useContext(I18nContext)
  return (
    <Button
      color='grey[400]'
      textColor='grey[0]'
      minWidth={100}
      outlined
      text={t(`userProfileCard.${text}`)}
      to={`${routes.edit.path}?tab=${pathTab}`}
      {...props}
    />
  )
}

ButtonEdit.propTypes = {
  pathTab: PropTypes.string,
  text: PropTypes.string,
}

export default ButtonEdit
