import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import I18nContext from 'contexts/I18nContext'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {Capacitor} from '@capacitor/core'
import {usePopupState, bindTrigger, bindMenu} from 'material-ui-popup-state/hooks'
import {CircleFlag} from 'react-circle-flags'
import useApi from 'hooks/api/useApi'
import useUser from 'hooks/api/useUser'
import {languageMap} from './LanguagesDropdown.config'
import {Text, ButtonTrigger, SelectIcon} from './LanguagesDropdown.styles'

const LanguagesDropdown = ({buttonProps, menuProps}) => {
  const {language, languages, changeLanguage} = useContext(I18nContext)
  const popupState = usePopupState({variant: 'popover', popupId: 'demoMenu'})
  const ios = Capacitor.getPlatform() === 'ios'
  const api = useApi()
  const {userInfo} = useUser()
  const changeLanguageForUserAccount = lang => {
    api.changeLanguage(userInfo.id, lang)
  }
  return (
    <>
      <ButtonTrigger
        size='lg'
        color='grey[600]'
        textColor='grey[400]'
        {...bindTrigger(popupState)}
        {...buttonProps}
      >
        <CircleFlag countryCode={languageMap[language].iconId} width={20} height={20} />
        <Text>{languageMap[language].textShort}</Text>
        <SelectIcon />
      </ButtonTrigger>
      <Menu
        {...bindMenu(popupState)}
        {...menuProps}
        style={{marginTop: ios && language === 'en' && '39px'}}
      >
        {languages.map(l => (
          <MenuItem
            key={l}
            onClick={() => {
              popupState.close()
              changeLanguage(l)
              changeLanguageForUserAccount(l)
            }}
            selected={l === language}
          >
            <CircleFlag countryCode={languageMap[l].iconId} width={20} height={20} />
            <Text>{languageMap[l].text}</Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

LanguagesDropdown.propTypes = {
  buttonProps: PropTypes.object,
  menuProps: PropTypes.object,
}

export default LanguagesDropdown
