import React from 'react'
import * as PropTypes from 'prop-types'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {useSwipeable} from 'react-swipeable'
import {
  ModalContainer,
  ModalStyled,
  ModalHead,
  ModalTitle,
  CloseIcon,
  ModalBody,
  CloseButton,
} from './Modal.styles'

const Modal = ({children, title, open, onClose, maxWidth, className, style, ...rest}) => {
  const handlers = useSwipeable({
    onSwipedRight: () => onClose(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  })

  return (
    <ModalStyled
      aria-labelledby='modal-title'
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
      {...rest}
      {...handlers}
    >
      <Fade timeout={250} in={open}>
        <ModalContainer maxWidth={maxWidth} className={className} style={style}>
          <ModalHead>
            <ModalTitle id={'modal-title'}>{title}</ModalTitle>
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </ModalHead>
          <ModalBody>{children}</ModalBody>
        </ModalContainer>
      </Fade>
    </ModalStyled>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Modal
