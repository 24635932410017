export const setNotifications = payload => ({
  type: 'Notifications',
  payload,
})

export const setCount = payload => ({
  type: 'NotificationCount',
  payload,
})

export const setDeviceToken = payload => ({
  type: 'DeviceToken',
  payload,
})
