import routes from 'routes'

export const stickyOffset = 15

export const routesWithRightCol = [
  routes.profile.personal.path,
  routes.profile.player.path,
  routes.profile.club.path,
  routes.profile.team.path,
  routes.profile.press.path,
  routes.feed.path,
  routes.search.path,
  routes.teammates.path,
  routes.profileFollowers.path,
  routes.contactLight.path,
  routes.team.path,
  routes.teams.path,
  routes.bookmarks.path,
  routes.vacancies.path,
  routes.vacancies.singleVacancy.path,
  routes.vacancies.vacanciesOtherProfile.path,
  routes.faq.path,
  routes.getPremium.path,
  routes.notifications.path,
  routes.posts.path,
]
