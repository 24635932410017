import React from 'react'
import HeaderTopBar from 'components/headers/common/HeaderTopBar'
import LanguagesDropdown from 'components/LanguagesDropdown'
import HeaderSideBar from 'components/headers/common/HeaderSideBar'
import HeaderWrapper from 'components/headers/common/HeaderWrapper'
import ButtonFaq from 'components/headers/common/ButtonFaq'

const HeaderDefault = ({...rest}) => (
  <HeaderWrapper collapseWidth='collapseLeftRow' {...rest}>
    {({opened, setOpened, isCollapsed}) => (
      <>
        <HeaderTopBar
          opened={opened}
          setOpened={setOpened}
          isCollapsed={isCollapsed}
          centerNode={
            <>
              <ButtonFaq />
            </>
          }
          rightNode={!isCollapsed && <LanguagesDropdown />}
        />
        <HeaderSideBar opened={opened} setOpened={setOpened} />
      </>
    )}
  </HeaderWrapper>
)

export default HeaderDefault
