import {shape, string, number, arrayOf} from 'prop-types'
import {profileType} from './accounts'

export const mediaFileType = shape({
  id: string.isRequired,
  attachment_type: string.isRequired, // can be any string
  attachment_url: string.isRequired,
  created_at: string.isRequired,
  updated_at: string.isRequired,
})

export const commentType = shape({
  id: string.isRequired,
  text: string,
  created_at: string.isRequired,
  updated_at: string.isRequired,
})

export const postType = shape({
  id: string,
  text: string,
  total_comments: number,
  total_likes: number,
  user_profile: profileType,
  media_files: arrayOf(mediaFileType),
  created_at: string,
  updated_at: string,
})
