import {createGlobalStyle} from 'styled-components'
import fontFace from './utils/fontFace'
import RobotoRegular from './fontFiles/Roboto/Roboto-Regular.ttf'
import RobotoMedium from './fontFiles/Roboto/Roboto-Medium.ttf'
import PoppinsRegular from './fontFiles/Poppins/Poppins-Regular.ttf'
import PoppinsMedium from './fontFiles/Poppins/Poppins-Medium.ttf'
import PoppinsSemiBold from './fontFiles/Poppins/Poppins-SemiBold.ttf'

export default createGlobalStyle`
  ${fontFace('Poppins', PoppinsRegular, 400)}
  ${fontFace('Poppins', PoppinsMedium, 500)}
  ${fontFace('Poppins', PoppinsSemiBold, 600)}
  ${fontFace('Roboto', RobotoRegular, 400)}
  ${fontFace('Roboto', RobotoMedium, 500)}
  
  button {
     font: inherit;
  }
  
  html {
    height: -webkit-fill-available;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
  }
  
  #root {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }
`
