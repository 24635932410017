import styled from 'styled-components'
import React from 'react'
import color from 'color'
import ButtonBase from '@material-ui/core/ButtonBase'

export const Wrapper = styled(props => <ButtonBase {...props} />)(({theme}) => ({
  width: '100%',
  color: theme.palette.common.white,
  fill: theme.palette.grey[400],
  stroke: theme.palette.grey[400],
  fontWeight: 500,
  borderRadius: theme.radii.default,
  padding: '0.4em 1em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  transitionDuration: `${theme.transitions.duration.shortest}ms`,
  lineHeight: 1.15,
  textDecoration: 'none',
  fontFamily: theme.typography.fontFamilyTitle,
  margin: theme.spacing(1, 0),
  minHeight: 40,
  '&:hover': {
    background: color(theme.palette.primary.main).fade(0.95),
  },
  '&.active': {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
    background: color(theme.palette.primary.main).fade(0.9),
  },

  '& > svg, & > img': {
    width: 20,
    height: 20,
    flex: '0 0 20px',
    objectFit: 'contain',
    objectPosition: 'center',
    marginRight: 12,
  },
}))

export const Text = styled.span(() => ({}))
