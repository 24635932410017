import React, {useContext} from 'react'
import {ConfirmProvider} from 'material-ui-confirm'
import I18nContext from 'contexts/I18nContext'

const MuiConfirmProvider = ({children}) => {
  const {t} = useContext(I18nContext)

  return (
    <ConfirmProvider
      defaultOptions={{
        title: t('confirmDialog.title'),
        confirmationText: t('confirmDialog.confirmationText'),
        cancellationText: t('confirmDialog.cancellationText'),
        confirmationButtonProps: {autoFocus: true},
        dialogProps: {
          fullWidth: false,
          maxWidth: 'md',
        },
      }}
    >
      {children}
    </ConfirmProvider>
  )
}

export default MuiConfirmProvider
