import React, {createContext, useState, useContext, useEffect, useRef, createRef} from 'react'
import Modal from 'components/Modal'
import DialogContentText from '@material-ui/core/DialogContentText'
import I18nContext from 'contexts/I18nContext'
import useApi from 'hooks/api/useApi'
import Confetti from 'react-confetti'
import useUser from 'hooks/api/useUser'
import {useQuery} from 'react-query'

const initialState = null

export const UserProfileContext = createContext(initialState)

const UserProfileProvider = ({children}) => {
  const [state, setstate] = useState(initialState)
  const [open, setOpen] = useState(true)
  const {t} = useContext(I18nContext)
  const api = useApi()
  const {userInfo} = useUser()

  const [onboardingStatus, setOnboardingStatus] = useState(null)
  const close = () => {
    setOpen(false)
    api.onboardingProcessCompleted(state.id).then(data => setstate(data.user_profile))
  }

  const enabled = /player$|team$/.test(userInfo?.role) && !!state

  const id = () => {
    if (userInfo?.role === 'player') {
      return state?.player_profile.id
    }
    if (userInfo?.role === 'team') {
      return state?.team_profile.id
    }
    return null
  }

  const userType = () => {
    if (userInfo?.role === 'player') {
      return 'player'
    }
    if (userInfo?.role === 'team') {
      return 'team'
    }
    return null
  }

  useEffect(() => {
    if (!userInfo?.id) {
      setOnboardingStatus(null)
      setstate(null)
    }
  }, [userInfo?.id])

  useQuery(
    `onboardingStatus-${userInfo?.id}`,
    () =>
      api
        .onboardingStatus(id(), userType())
        .then(res => setOnboardingStatus(res.onboarding_status)),
    {enabled}
  )
  return (
    <>
      <UserProfileContext.Provider value={{state, setstate, onboardingStatus, setOnboardingStatus}}>
        {!state?.completed_onboarding &&
          onboardingStatus &&
          Object.values(onboardingStatus).every(e => e) &&
          /player$|team$/.test(userInfo?.role) && (
            <>
              <Modal open={open} onClose={close} title={t('onboarding.successTitle')}>
                <DialogContentText id='alert-dialog-description'>
                  {userInfo?.role === 'player'
                    ? t('onboarding.successText')
                    : t('onboarding.team.successText')}
                </DialogContentText>
                <Confetti width={window.innerWidth} height={window.innerHeight} />
              </Modal>
            </>
          )}
        {children}
      </UserProfileContext.Provider>
    </>
  )
}
export default UserProfileProvider
