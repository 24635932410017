import styled from 'styled-components'
import Input from 'components/inputs/Input'
import {ReactComponent as IconSvg} from 'images/icons/search-icon.svg'

export const Wrapper = styled.div({
  flex: 1,
  position: 'relative',
  zIndex: 1,
})

export const SearchInput = styled(Input)(({theme}) => ({
  marginBottom: 0,
  height: '100%',
}))

export const SearchIcon = styled(IconSvg)(({theme}) => ({
  opacity: 0.6,
  fill: 'currentColor',
  width: '1.25em',
  height: '1.25em',
  marginRight: '1em',
  pointerEvents: 'none',
}))
