import React from 'react'
import PropTypes from 'prop-types'
import bgImg from 'images/bg/bg-auth.jpg'
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import {lightTheme} from 'styles/theme'
import {ThemeProvider} from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import SEO from 'components/SEO'
import {Container, BgImg, ContainerChildren} from './RegisterLayout.styles'
import TopBar from './TopBar'

const RegisterLayout = ({children}) => (
  <MuiThemeProvider theme={lightTheme}>
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <SEO />
      <Container>
        <TopBar />
        <BgImg src={bgImg} />
        <ContainerChildren>{children}</ContainerChildren>
      </Container>
    </ThemeProvider>
  </MuiThemeProvider>
)

RegisterLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RegisterLayout
