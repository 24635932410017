import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import {StylesProvider, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {QueryParamProvider} from 'use-query-params'
import 'i18n'
import 'utils/yupAddMethods'
import GlobalStyles from 'styles/GlobalStyles'
import App from 'containers/App'
import LoadingCircle from 'components/LoadingCircle'
import {I18nContextProvider} from 'contexts/I18nContext'
import {darkTheme} from 'styles/theme'
import ReactQueryProvider from 'containers/ReactQueryProvider'
import SnackbarProvider from 'containers/SnackbarProvider'
import MuiConfirmProvider from 'containers/MuiConfirmProvider'
import NotificationProvider from 'containers/NotificationProvider/NotificationProvider'
import UserProfileProvider from 'containers/UserProfileProvider/UserProfileProvider'
import PrivacyPolicy from 'components/PrivacyPolicy'

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={darkTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <SnackbarProvider>
            <ReactQueryProvider>
              <Suspense fallback={<LoadingCircle isAbsolute />}>
                <GlobalStyles />
                <Router>
                  <I18nContextProvider>
                    <MuiConfirmProvider>
                      <QueryParamProvider ReactRouterRoute={Route}>
                        <NotificationProvider>
                          <UserProfileProvider>
                            <PrivacyPolicy>
                              <App />
                            </PrivacyPolicy>
                          </UserProfileProvider>
                        </NotificationProvider>
                      </QueryParamProvider>
                    </MuiConfirmProvider>
                  </I18nContextProvider>
                </Router>
              </Suspense>
            </ReactQueryProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
)
// ReactDOM.render(<React.StrictMode>Helffoff</React.StrictMode>, document.getElementById('root'))
