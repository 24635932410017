import React, {useState, useContext} from 'react'
import I18nContext from 'contexts/I18nContext'
import FeedbackModal from 'components/FeedbackModal'
import {ReactComponent as Player} from 'images/icons/feedback.svg'
import NavigationNoLink from 'components/navigations/common/NavigationNoLink'

const FeedbackView = () => {
  const {t} = useContext(I18nContext)
  const [banModalOpened, setBanModalOpened] = useState(false)
  return (
    <>
      <NavigationNoLink
        onClick={() => setBanModalOpened(true)}
        text={t('feedback.title')}
        iconSvg={Player}
      />
      <FeedbackModal open={banModalOpened} onClose={() => setBanModalOpened(false)}></FeedbackModal>
    </>
  )
}

export default FeedbackView
