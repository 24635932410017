const palette = {
  common: {
    black: '#000',
    white: '#fff',
    red: '#ff0000',
  },
  primary: {
    main: '#f4b61c',
    dark: '#E1A101',
    contrastText: '#fff',
  },
  secondary: {
    main: '#008466',
    dark: '#0C5B49',
  },
  info: {
    main: '#E84E1B',
  },
  error: {
    main: '#FF0000',
    dark: '#c20000',
  },
  fb: {
    main: '#4167A3',
  },
  link: {
    main: '#4683E4',
  },
  blueDeep: {
    main: '#1F1BE8',
  },
  grey: {
    1000: '#000000',
    950: '#1B1C1D',
    900: '#18191a', // default bg
    850: '#1C1D1E',
    800: '#1e2023',
    700: '#242526',
    600: '#3A3B3C', // border, input bg
    550: '#44444F',
    500: '#626262',
    400: '#92929D',
    300: '#B5B5BE',
    200: '#d7d7d7',
    100: '#F1F1F5',
    50: '#FAFAFB',
    0: '#FFF',
  },
}
export default palette
